import React, { useState, useEffect } from "react";
import { systems } from "./systems";
import { XIcon } from "@heroicons/react/solid";

export default function SourceSystemsInput({ onSelectOption, userResponse }) {
  const initialSourceSystems = systems;
  const [availableSystems] = useState(initialSourceSystems);
  const [selectedSystems, setSelectedSystems] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (userResponse && userResponse !== "None") {
      const initialSelectedSystems = userResponse.split(',').map(system => system.trim());
      setSelectedSystems(initialSelectedSystems);
    }
  }, [userResponse]);

  useEffect(() => {
    const sourceSystemsString = selectedSystems.length === 0 ? "None" : selectedSystems.join(", ");
    onSelectOption(sourceSystemsString);
  }, [selectedSystems, onSelectOption]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      const newSystem = inputValue.trim();
      if (newSystem && !selectedSystems.includes(newSystem)) {
        setSelectedSystems([...selectedSystems, newSystem]);
        setInputValue("");
      }
    }
  };

  const handleRemoveSystem = (systemToRemove) => {
    setSelectedSystems(
      selectedSystems.filter((system) => system !== systemToRemove)
    );
  };

  return (
    <div className="w-full">
      <input
        type="text"
        className="border border-gray-300 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        list="available-systems"
      />
      <datalist className="w-full" id="available-systems">
        {availableSystems.map((system) => (
          <option className="w-full" key={system} value={system} />
        ))}
      </datalist>
      <div className="mb-2 flex flex-wrap gap-1 py-2 text-sm">
        {selectedSystems.length === 0 && <div className='italic text-gray-500 text-xs'>Currently Selected Source Systems: None</div>}
        {selectedSystems.map((system) => (
          <span
            key={system}
            className="inline-flex items-center bg-grayBlue-800 text-white text-sm rounded-md pl-3 pr-2 py-1"
          >
            {system}
            <button
              className="ml-2 h-4 w-4 rounded-sm flex items-center  bg-grayBlue-500  justify-center text-white hover:bg-error-500"
              onClick={() => handleRemoveSystem(system)}
            >
              <XIcon className="h-4 w-4" />
            </button>
          </span>
        ))}
      </div>
    </div>
  );
}
