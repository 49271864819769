import { ArrowLeftIcon } from "@heroicons/react/outline";
import React from "react";
import { useDispatch } from "react-redux";
import { clearSelectedCustomer } from "../../../../redux/customers/customersSlice";

export default function Header() {
  const dispatch = useDispatch();


  return (
    <div className="flex justify-between items-center p-3 py-6">
      <div className="flex items-center justify-center">
        <div
          onClick={() => dispatch(clearSelectedCustomer())}
          className="cursor-pointer hover:bg-gray-100 border rounded-full bg-white border-gray-400 p-1 mr-3"
        >
          <ArrowLeftIcon className="h-4 w-4 text-gray-900" />
        </div>
        <div className="text-2xl font-semibold">Customer Details</div>
      </div>
    </div>
  );
}
