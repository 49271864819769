import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ShortlistCard from "./ShortlistCard";

const ShortlistCardListView = () => {
  const completedQuestionnaires = useSelector(
    (state) => state.customerAuth?.customer?.completedQuestionnaires
  );

  return (
    <div className="md:p-4">
      <div className="flex w-full min-h-full items-center justify-start flex-col font-sans text-sm shadow-sm text-left">
        <div className="overflow-x-auto min-h-full min-h-full min-w-full ">
          {completedQuestionnaires?.map((questionnaire) => (
            <ShortlistCard questionnaire={questionnaire} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShortlistCardListView;
