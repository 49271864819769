import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CategoryScores from "./CategoryScores";
import { fetchVendors } from "../../redux/vendors/vendorsThunk";
import { setMatchReportSelectedImages } from "../../redux/questionnaire/questionnaireThunk";

// NOTE: This Component is the MatchReport visible in the Customer Scorecard view. There is another one which is the
// Admin-facing MatchReport, which is at
// shortlist-advice\shortlist-advice\src\pages\QuestionnaireResults\components\LiveDataScorecard\CategoryScores.js

const ShortlistMatchCard = ({ scores }) => {
  const dispatch = useDispatch();
  const gptScores = scores;
  // const selectedVendors = scores.selectedVendors;

  const [selectedVendors, setSelectedVendors] = useState(
    scores.selectedVendors
  );

  // Helper function to extract and prepare scores for the selected vendors
  const getPreparedScores = () => {
    const { overallScores = {} } = gptScores;
    const scores = Object.entries(overallScores)
      .filter(([vendorName]) => selectedVendors.includes(vendorName))
      .map(([vendorName, score]) => ({ vendorName, score: Number(score) }));

    const highestScore = Math.max(...scores.map(({ score }) => score));
    return scores.map((scoreInfo) => ({
      ...scoreInfo,
      isHighestScore: scoreInfo.score === highestScore,
    }));
  };

  const preparedScores = getPreparedScores();

  const selectedVendorImages = useSelector(
    (state) => state.questionnaire.gptScores.matchReportSelectedImages || []
  );

  const vendorData = useSelector((state) => state.vendors.vendors);
  const vendorDataStatus = useSelector((state) => state.vendors.status);

  useEffect(() => {
    // If necessary, load vendors so that we have access to their images.
    if (vendorDataStatus == "init") {
      dispatch(fetchVendors());
    }

    if (vendorDataStatus == "success" && selectedVendors.length > 0) {
      dispatch(setMatchReportSelectedImages(selectedVendors));
    }
  }, [selectedVendors, vendorDataStatus]);

  return (
    <div className="">
      <div className="shadow-md mb-4">
        <div className="bg-grayBlue-50 rounded-lg overflow-hidden">
          <h1 className="text-2xl w-full text-center font-semibold pl-6 pt-6 pb-3 md:pb-0">
            Your Match Report
          </h1>
          <div className="grid grid-cols-3 ">
            {preparedScores.map(
              ({ vendorName, score, isHighestScore }, index) => {
                const highlightStyle = isHighestScore
                  ? "bg-success-500 text-white"
                  : score == 1
                  ? "bg-success-500 text-white"
                  : score > 0.9
                  ? "bg-success-300"
                  : score > 0.5
                  ? "bg-warning-200"
                  : "bg-error-300";
                const isLastRow =
                  index >=
                  preparedScores.length - (preparedScores.length % 3 || 3);
                const roundedClasses = isLastRow
                  ? `${index % 3 === 0 ? "rounded-bl-lg" : ""}${
                      index % 3 === 2 ? " rounded-br-lg" : ""
                    }`
                  : "";

                return (
                  <div
                    key={index}
                    className={`flex flex-col justify-between text-center h-full ${roundedClasses}`}
                  >
                    <div className="flex items-center justify-center relative lg:p-6 p-2">
                      <div className="rounded-md border shadow-sm aspect-square bg-white flex items-center justify-center max-h-[200px]">
                        <img
                          src={selectedVendorImages[index]}
                          className="object-contain h-full w-full p-3"
                          alt={vendorName}
                        />
                      </div>
                    </div>
                    <div
                      className={`font-semibold text-2xl p-6 ${highlightStyle} flex-1`}
                    >
                      {Math.round(score.toFixed(2) * 100)}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
      <CategoryScores scores={scores} />
    </div>
  );
};

export default ShortlistMatchCard;
