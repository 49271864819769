import React from "react";
import Header from "../Header";
import SoftwareLogos from "../../../../assets/SoftwareLogos.jpg";
import "./StartQuestionnaire.css";
import { ClockIcon } from "@heroicons/react/outline";
import PrivacyPolicy from "../../../../assets/ShortlistMatchPrivacyPolicy3-26-24.pdf";
import TermsOfUse from "../../../../assets/ShortlistMatchTermsOfUse3-26-24.pdf";

export default function StartQuestionnaire({ setHasStarted }) {
  const startQuestionnaire = () => {
    setHasStarted(true);
  };
  return (
    <div className="flex flex-col h-screen items-center justify-between bg-grayBlue-100 ">
      <Header />
      <div className="w-full flex flex-col items-center justify-center text-center p-6 grow max-w-2xl">
        <div className="text-3xl font-semibold">
          Let's find the best software for you
        </div>
        <div className="p-2 text-sm text-gray-600">
          Please take a moment to complete this short questionnaire, and once
          you submit it, we'll send you personalized advice and recommendations.
          Let’s begin!
        </div>
        <button
          className="mt-6 bg-blue-600 hover:bg-blue-700 w-full max-w-md p-2 rounded-md text-white text-lg"
          onClick={() => startQuestionnaire()}
        >
          Start Questionnaire
        </button>
        <div className="text-md p-2 pt-4 flex items-center justify-center space-x-1 text-gray-600">
          {" "}
          <ClockIcon className="h-5 w-5" />
          <span>Takes 5 minutes</span>
        </div>
        <p className="text-xs m-4">
          By continuing, you agree to our
          <a
            className="font-semibold hover:text-blue-500"
            href={PrivacyPolicy}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Privacy Policy{" "}
          </a>
          and
          <a
            className="font-semibold hover:text-blue-500"
            href={TermsOfUse}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Terms and Conditions{" "}
          </a>
          .
        </p>
      </div>

      {/* <div className="relative max-h-96 hidden sm:block w-full overflow-hidden">
        <img className="object-none xl:object-cover w-full" src={SoftwareLogos} alt="softwareLogos" />
        <div className="absolute top-0 left-0 right-0 bottom-0 custom-horizontal-gradient" />
      </div> */}
    </div>
  );
}
