import React from 'react';

export default function StatusBadge({ status }) {
  const isActive = status === 'Active';

  return (
    <div
      className={`px-3 py-1 rounded-full text-sm font-semibold 
                  ${isActive ? 'bg-success-100 text-success-600' : 'bg-gray-100 text-gray-400'}`}
    >
      {status}
    </div>
  );
}
