import React from 'react';
import Checkbox from '../../../../components/Checkbox/Checkbox';

const Checklist = ({ question, currentlySelected, onUpdateUserResponse }) => {
  const handleCheckboxChange = (answer) => {
    let updatedSelected;
    if (currentlySelected.includes(answer)) {
      updatedSelected = currentlySelected.filter(item => item !== answer);
    } else {
      updatedSelected = [...currentlySelected, answer];
    }
    onUpdateUserResponse(updatedSelected);
  };

  const gridClass = question.answers.length > 6 ? 'grid grid-cols-2 gap-4' : '';

  return (
    <div className={gridClass}>
      {question.answers.map((answer, index) => (
        <div key={index} className="flex items-center mb-2 cursor-pointer" onClick={() => handleCheckboxChange(answer.text)}>
          <Checkbox value={currentlySelected.includes(answer.text)} />
          <label
            htmlFor={answer.id}
            className="ml-2 text-sm text-grayBlue-700 cursor-pointer"
          >
            {answer.text}
          </label>
        </div>
      ))}
    </div>
  );
};

export default Checklist;
