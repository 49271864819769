import React, { useState, useEffect } from "react";
import Dropdown from "../../../../components/Dropdown";
import { setMatchReportContactInfo, setMatchReportVendors } from "../../../../redux/questionnaire/questionnaireSlice";
import { useDispatch } from "react-redux";

export default function VendorSelection({ vendors, title }) {
  const [vendorName, setVendorName] = useState("");
  const [vendorEmail, setVendorEmail] = useState("");
  const [vendorPhone, setVendorPhone] = useState("");
  const [vendorScore, setVendorScore] = useState("");
  const dispatch = useDispatch();

  const handleVendorSelection = (item) => {
    if (title === "Shortlist Top Pick") {
      dispatch(setMatchReportVendors({ index: 0, value: item }));
    }
    if (title === "Shortlist Second Pick") {
      dispatch(setMatchReportVendors({ index: 1, value: item }));
    }
    if (title === "Shortlist Third Pick") {
      dispatch(setMatchReportVendors({ index: 2, value: item }));
    }
  };

  useEffect(() => {
    if (title === "Shortlist Top Pick") {
      dispatch(setMatchReportContactInfo({ index: 0, value: {
        vendorName,
        vendorEmail,
        vendorPhone,
        vendorScore
      }}));
    }
    if (title === "Shortlist Second Pick") {
      dispatch(setMatchReportContactInfo({ index: 1, value: {
        vendorName,
        vendorEmail,
        vendorPhone,
        vendorScore
      } }));
    }
    if (title === "Shortlist Third Pick") {
      dispatch(setMatchReportContactInfo({ index: 2, value: {
        vendorName,
        vendorEmail,
        vendorPhone,
        vendorScore
      } }));
    }
  }, [vendorName,vendorEmail,vendorPhone,vendorScore])
  

  return (
    <div className="">
      <div className="mb-4 font-semibold">{title}</div>
      <div className="mb-4">
        <Dropdown options={vendors} onSelectOption={handleVendorSelection} />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-grayBlue-700 mb-2">
          Contact Name
        </label>
        <input
          onChange={(e) => setVendorName(e.target.value)}
          type="text"
          value={vendorName}
          className="border border-gray-300 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600 w-full"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-grayBlue-700 mb-2">
          Contact Email
        </label>
        <input
          onChange={(e) => setVendorEmail(e.target.value)}
          type="text"
          value={vendorEmail}
          className="border border-gray-300 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600 w-full"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-grayBlue-700 mb-2">
          Contact Phone Number
        </label>
        <input
          onChange={(e) => setVendorPhone(e.target.value)}
          type="text"
          value={vendorPhone}
          className="border border-gray-300 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600 w-full"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-grayBlue-700 mb-2">
          Match Report Overall Score (Optional)
        </label>
        <input
          onChange={(e) => setVendorScore(e.target.value)}
          type="text"
          value={vendorScore}
          className="border border-gray-300 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600 w-full"
        />
      </div>
    </div>
  );
}
