// EmailInput.js
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customerLogin } from "../../../redux/customerAuth/customerAuthThunk";
import Spinner from "../../../components/Spinner";
import { useNavigate } from "react-router-dom";

const EmailInput = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // State to manage the email input value
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("")
  const [error, setFormError] = useState("")
  const status = useSelector((state) => state.customerAuth.status);
  const token = useSelector((state) => state.customerAuth.token);

  // Event handler for handling changes in the input
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // Event handler for handling form submission (you can customize this according to your needs)
  
  const handleSubmit = (event) => {
    setFormError('')
    event.preventDefault();
    console.log(`Email submitted: ${email}`);
  
    // If all checks pass, dispatch the registration action
    dispatch(customerLogin({ email, password }));
  };

  
  if ( status === "loading") {
    return (
      <div className="flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  

  if (token) {
    return (
      navigate('/my-shortlists')
    );
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-6"
      action="#"
      method="POST"
    >
      <label
        htmlFor="email"
        className="block text-sm font-medium leading-5 text-gray-900"
      >
        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Enter your email"
          required
          className="
          border
          border-gray-300
          block
          w-full
          rounded-md
          py-2
          pl-3
          text-gray-900
          shadow-sm
          placeholder:text-gray-400
          focus:outline-blue-600
          focus:ring-blue-600
          sm:text-sm
          sm:leading-6"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter your password"
          required
          className="
          border
          border-gray-300
          block
          w-full
          rounded-md
          py-2
          pl-3
          mt-2
          text-gray-900
          shadow-sm
          placeholder:text-gray-400
          focus:outline-blue-600
          focus:ring-blue-600
          sm:text-sm
          sm:leading-6"
        />
        <button
          type="submit"
          className="
          hover:bg=blue-700
          focus-visible:outline-transparent
          mt-3
          flex
          w-full
          cursor-pointer
          justify-center
          rounded-md
          bg-blue-600
          p-2.5
          text-sm
          font-semibold
          text-white
          shadow-sm
          focus-visible:outline
          focus-visible:outline-0
          focus-visible:outline-offset-0"
        >
          Continue with email
        </button>
      </label>
    </form>
  );
};

export default EmailInput;
