import React, { useState, useEffect } from "react";
import SubmitLoading from "./SubmitLoading";
import SubmitSuccess from "./SubmitSuccess";

export default function SubmittedForm() {
  const [loading, setLoading] = useState(true); // Initialize with loading true

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []); 

  return (
    <div className="absolute inset-0 flex flex-col items-center justify-center">
      {loading ? <SubmitLoading /> : <SubmitSuccess />}
    </div>
  );
}
