import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CategoryScores from "./CategoryScores";
import { setMatchReportScores } from "../../../../redux/questionnaire/questionnaireSlice";
import { fetchVendors } from "../../../../redux/vendors/vendorsThunk";
import { setMatchReportSelectedImages } from "../../../../redux/questionnaire/questionnaireThunk";

// NOTE: This Component is what is visible in the "Admin" menu. There is another one which is the
// Customer-facing MatchRepor, which is at
// shortlist-advice\shortlist-advice\src\components\ShortlistMatchCard\ShortlistMatchCard.js

function applyNormalizationRatios(overallScores, categories, normalizedRatios) {
  // Create a new object for updated overall scores
  const updatedOverallScores = {};
  for (const vendor in overallScores) {
    const ratio =
      normalizedRatios.find((item) => item.vendor === vendor)?.ratio || 1;
    // Cap the score between 0 and 1
    updatedOverallScores[vendor] = Math.min(
      1,
      Math.max(0, overallScores[vendor] * ratio)
    );
  }

  // Create a new array for updated categories
  const updatedCategories = categories.map((category) => {
    // Copy the category object
    const newCategory = { ...category };

    // Update category scores
    newCategory.categoryScores = {};
    for (const vendor in category.categoryScores) {
      const ratio =
        normalizedRatios.find((item) => item.vendor === vendor)?.ratio || 1;
      // Cap the score between 0 and 1
      newCategory.categoryScores[vendor] = Math.min(
        1,
        Math.max(0, category.categoryScores[vendor] * ratio)
      );
    }

    // Update question scores
    newCategory.questionScores = category.questionScores.map((question) => {
      const newQuestion = {
        question: question.question,
        category: question.category,
        requirement: question.requirement,
        scoreMethod: question.scoreMethod,
        targetProperty: question.targetProperty,
        userResponse: question.userResponse,
      };
      for (const vendor in question) {
        if (
          vendor !== "question" &&
          vendor !== "category" &&
          vendor !== "requirement" &&
          vendor !== "scoreMethod" &&
          vendor !== "targetProperty" &&
          vendor !== "userResponse"
        ) {
          const ratio =
            normalizedRatios.find((item) => item.vendor === vendor)?.ratio || 1;
          // Cap the score between 0 and 1
          newQuestion[vendor] = Math.min(
            1,
            Math.max(0, question[vendor] * ratio)
          );
        }
      }
      return newQuestion;
    });

    return newCategory;
  });

  return {
    updatedOverallScores,
    updatedCategories,
  };
}

function applyVendorSort(updatedOverallScores, updatedCategories) {
  // Extract and sort the vendors by their scores in descending order
  const sortedVendors = Object.keys(updatedOverallScores).sort(
    (a, b) => updatedOverallScores[b] - updatedOverallScores[a]
  );

  // Function to sort keys according to sortedVendors, keeping non-vendor keys first
  function sortKeysAccordingToVendors(obj) {
    const vendorKeys = sortedVendors.filter((vendor) => vendor in obj);
    const nonVendorKeys = Object.keys(obj).filter(
      (key) => !sortedVendors.includes(key)
    );
    const sortedObject = {};

    // Add non-vendor keys first
    nonVendorKeys.forEach((key) => {
      sortedObject[key] = obj[key];
    });

    // Add vendor keys in sorted order
    vendorKeys.forEach((vendor) => {
      sortedObject[vendor] = obj[vendor];
    });

    return sortedObject;
  }

  // Update categories with sorted vendors
  const sortedCategories = updatedCategories.map((category) => {
    const sortedCategoryScores = sortKeysAccordingToVendors(
      category.categoryScores
    );
    const sortedQuestionScores = category.questionScores.map((question) =>
      sortKeysAccordingToVendors(question)
    );

    return {
      ...category,
      categoryScores: sortedCategoryScores,
      questionScores: sortedQuestionScores,
    };
  });

  return {
    sortedOverallScores: sortKeysAccordingToVendors(updatedOverallScores), // in case there are any additional keys
    sortedCategories: sortedCategories,
  };
}

function sortVendors(overallScores, selectedVendors) {
  return selectedVendors.sort((a, b) => overallScores[b] - overallScores[a]);
}

const MatchReport = () => {
  const dispatch = useDispatch();
  const gptScores = useSelector((state) => state.questionnaire.gptScores);
  const matchReportScores = useSelector(
    (state) => state.questionnaire.matchReportScores
  );
  const selectedVendors = useSelector(
    (state) => state.questionnaire.gptScores.matchReportSelectedVendors || []
  );
  const selectedVendorImages = useSelector(
    (state) => state.questionnaire.gptScores.matchReportSelectedImages || []
  );

  const vendorData = useSelector((state) => state.vendors.vendors);
  const vendorDataStatus = useSelector((state) => state.vendors.status);

  useEffect(() => {
    // If necessary, load vendors so that we have access to their images.
    if (vendorDataStatus == "init") {
      dispatch(fetchVendors());
    }

    if (vendorDataStatus == "success" && selectedVendors.length > 0) {
      // TODO: Dispatch setSelectedImages
      dispatch(setMatchReportSelectedImages(selectedVendors));
    }
  }, [selectedVendors, vendorDataStatus]);

  useEffect(() => {
    let finalizedContactInfo = [];
    let normalizedRatios = [];
    for (let i = 0; i < selectedVendors.length; i++) {
      finalizedContactInfo.push({
        vendorName: selectedVendors[i],
        contactName: gptScores.matchReportContactInfo[i].vendorName,
        contactEmail: gptScores.matchReportContactInfo[i].vendorEmail,
        contactPhone: gptScores.matchReportContactInfo[i].vendorPhone,
        adjustedScore: gptScores.matchReportContactInfo[i].vendorScore,
      });
      normalizedRatios.push({
        vendor: selectedVendors[i],
        ratio:
          gptScores.matchReportContactInfo[i].vendorScore !== "" &&
          parseInt(gptScores.matchReportContactInfo[i].vendorScore) !== "NaN"
            ? gptScores.matchReportContactInfo[i].vendorScore /
              100 /
              gptScores.overallScores[selectedVendors[i]]
            : 1,
      });
    }

    // clean normalized ratios in case of NaN inputs:
    normalizedRatios = normalizedRatios.map((item) => ({
      ...item,
      ratio: isNaN(item.ratio) ? 1 : item.ratio,
    }));

    const { updatedOverallScores, updatedCategories } =
      applyNormalizationRatios(
        gptScores.overallScores,
        gptScores.categories,
        normalizedRatios
      );

    const { sortedOverallScores, sortedCategories } = applyVendorSort(
      updatedOverallScores,
      updatedCategories
    );

    // Now, sort these selected vendors based on the sorted overall scores
    const sortedSelectedVendors = sortVendors(sortedOverallScores, [
      ...selectedVendors,
    ]);

    dispatch(
      setMatchReportScores({
        questionnaireId: gptScores.questionnaireId,
        selectedVendors: sortedSelectedVendors,
        contactInfo: finalizedContactInfo,
        overallScores: sortedOverallScores,
        categories: sortedCategories,
      })
    );
  }, [gptScores]);

  // Helper function to extract and prepare scores for the selected vendors
  const getPreparedScores = () => {
    const { overallScores = {} } = matchReportScores;
    const scores = Object.entries(overallScores)
      .filter(([vendorName]) => selectedVendors.includes(vendorName))
      .map(([vendorName, score]) => ({ vendorName, score: Number(score) }));

    const highestScore = Math.max(...scores.map(({ score }) => score));
    return scores.map((scoreInfo) => ({
      ...scoreInfo,
      isHighestScore: scoreInfo.score === highestScore,
    }));
  };

  const preparedScores = getPreparedScores();

  return (
    <div className="mx-auto py-8 px-1 sm:px-4 bg-white rounded-lg shadow-lg">
      <h1 className="text-2xl font-semibold mb-6">Your Match Report</h1>
      <div className="mb-4">
        <div className="grid grid-cols-3 bg-grayBlue-50 rounded-lg overflow-hidden">
          {preparedScores.map(
            ({ vendorName, score, isHighestScore }, index) => {
              const highlightStyle = isHighestScore
                ? "bg-success-500 text-white"
                : score == 1
                ? "bg-success-500 text-white"
                : score > 0.9
                ? "bg-success-300"
                : score > 0.5
                ? "bg-warning-200"
                : "bg-error-300";
              const isLastRow =
                index >=
                preparedScores.length - (preparedScores.length % 3 || 3);
              const roundedClasses = isLastRow
                ? `${index % 3 === 0 ? "rounded-bl-lg" : ""}${
                    index % 3 === 2 ? " rounded-br-lg" : ""
                  }`
                : "";

              return (
                <div
                  key={index}
                  className={`flex flex-col justify-between text-center h-full ${roundedClasses}`}
                >
                  <div className="flex items-center justify-center relative lg:p-6 p-2">
                    <div className="rounded-md border shadow-sm aspect-square bg-white flex items-center justify-center max-h-[200px]">
                      <img
                        src={selectedVendorImages[index]}
                        className="object-contain h-full w-full p-3"
                        alt={vendorName}
                      />
                    </div>
                  </div>
                  <div
                    className={`font-semibold text-2xl p-6 ${highlightStyle} flex-1`}
                  >
                    {Math.round(score.toFixed(2) * 100)}
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
      <CategoryScores />
    </div>
  );
};

export default MatchReport;
