import { createAsyncThunk } from '@reduxjs/toolkit';
import ShortListAdviceAPI from '../ShortListAdviceAPI'; // Replace with your actual API utility

export const fetchCustomers = createAsyncThunk(
  'customers/fetchCustomers',
  async (_, { getState, rejectWithValue }) => {
    try {
      // Access the current state to get the page and limit values
      const { currentPage, itemsPerPage } = getState().customers.pagination;

      console.log(currentPage)
      console.log(itemsPerPage)

      // Construct the query string with the retrieved page and limit values
      const queryString = `?currentPage=${currentPage}&itemsPerPage=${itemsPerPage}`;
      const response = await ShortListAdviceAPI.get('/api/customers' + queryString);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);


export const getCustomerDetailsById = createAsyncThunk(
    'customers/getCustomerDetailsById',
    async ({ id }, { getState, rejectWithValue }) => {
      try {
        const response = await ShortListAdviceAPI.get(`/api/customers/${id}`);
  
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
      }
    }
  );