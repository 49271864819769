import React, { useState, useEffect } from "react";
import SlideOutDetail from "./components/SlideOutDetail"; // The slide-out detail view
import ItemList from "./components/ItemList";
import ActionButton from "../../components/PageHeader/components/ActionButton";
import SingleLineTextInput from "../../components/SingleLineTextInput";
import { useDispatch, useSelector } from "react-redux";
import {
  getSimplifiedRequirementsList,
  populateRequirementDetails,
} from "../../redux/scoreVendors/scoreVendorsThunk";
import CustomerRequirementsCreator from "./components/CustomerRequirementsCreator/CustomerRequirementsCreator";
import { useLocation, useNavigate } from "react-router-dom";
import CustomerBackToShortlists from "../../components/CustomerBackToShortlists";
import {
  getScorecardByQuestionnaireId,
  saveScorecardDataByQuestionnaireId,
} from "../../redux/scorecards/scorecardsThunk";
import { unwrapResult } from "@reduxjs/toolkit";
import { updateCustomerStatus } from "../../redux/customerAuth/customerAuthSlice";
import { setSelectedScorecardRequirements } from "../../redux/scoreVendors/scoreVendorsSlice";
import CustomRequirementModal from "../../components/CustomRequirementModal/CustomRequirementModal";
import Spinner from "../../components/Spinner";

const CustomerCreateDemoScorecard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const availableRequirements = useSelector(
    (state) => state.scoreVendors.availableRequirements
  );
  const availableRequirementsStatus = useSelector(
    (state) => state.scoreVendors.availableRequirementsStatus
  );
  const selectedScorecardRequirements = useSelector(
    (state) => state.scoreVendors.selectedScorecardRequirements
  );

  const requirementDetailView = useSelector(
    (state) => state.scoreVendors.requirementDetailView
  );

  const selectedScorecard = useSelector(
    (state) => state.scorecards.selectedScorecard
  );

  const matchDataByQuestionnaire = useSelector(
    (state) => state.scorecards.matchDataByQuestionnaire
  );


  // setSelectedScorecardRequirements
  // UseEffect to load scorecard based on URL ID
  useEffect(() => {
    if (selectedScorecard?.scoreCardData) {
      dispatch(
        setSelectedScorecardRequirements(
          JSON.parse(selectedScorecard.scoreCardData)
        )
      );
    }
  }, [selectedScorecard]);

  useEffect(() => {
    // UseEffect to load scorecard based on URL ID
    const urlParams = new URLSearchParams(window.location.search);
    const qid = urlParams.get("qid");
    dispatch(getScorecardByQuestionnaireId({ id: qid }));

    // If we are on our initial load, get all of the available requirements
    if (availableRequirementsStatus == "init") {
      dispatch(getSimplifiedRequirementsList());
    }
  }, []);

  const currentVendors = useSelector(
    (state) => state.questionnaire.matchReportScores.selectedVendors
  );

  const [selectedItem, setSelectedItem] = useState(requirementDetailView);
  const [isSlideOutOpen, setIsSlideOutOpen] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const showEditor = useSelector((state) => state.requirements.showEditor);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsSlideOutOpen(true);
    dispatch(populateRequirementDetails({ id: item._id }));
  };

  const closeSlideOut = () => setIsSlideOutOpen(false);

  // Filter availableRequirements based on searchVal
  const filteredAvailableRequirements = searchVal
    ? availableRequirements.filter((req) =>
        req.name.toLowerCase().includes(searchVal.toLowerCase())
      )
    : availableRequirements;

  const handleSaveScorecard = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const qid = urlParams.get("qid");
    const saveScorecardData = {
      qid: qid,
      body: {
        scoreCardData: selectedScorecardRequirements,
      },
    };

    try {
      // Dispatch the action and unwrap the result
      const result = await dispatch(
        saveScorecardDataByQuestionnaireId(saveScorecardData)
      ).then(unwrapResult);

      // If the above line didn't throw, the action was successful
      dispatch(updateCustomerStatus("scorecardCreated"));
      navigate("/my-shortlists");
    } catch (error) {
      // Do not navigate away from page.
      console.error("Failed to save scorecard:", error);
    }
  };

  const [isCustomRequirementOpen, setIsCustomRequirementOpen] = useState(false);
  const handleConfirmCustomRequirement = () => {
    // Handle the confirm action
    setIsCustomRequirementOpen(false);
    console.log("Confirmed!");
  };

  const handleCloseCustomRequirement = () => {
    // Handle the close action
    setIsCustomRequirementOpen(false);
  };

  
  // Handle Loading States
  const [loading, setLoading] = useState(true)

  const scoreVendorsStatus = useSelector(
    (state) => state.scoreVendors.status
  );
  const scorecardsStatus = useSelector(
    (state) => state.scorecards.status
  );
  
  useEffect(() => {
    if ((scoreVendorsStatus === "loading" || scorecardsStatus === "loading")) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [scoreVendorsStatus, scorecardsStatus]);

  if (loading) {
    return (
      <div className='w-full h-full flex items-center justify-center p-6'>
        <Spinner />
      </div>
    )
  }

  return showEditor ? (
    <div className="overflow-hidden">
      <CustomerRequirementsCreator />
    </div>
  ) : (
    <div className="flex flex-col p-6">
      <div className="flex w-full justify-between">
        <CustomerBackToShortlists />
        <ActionButton
          text="Save Scorecard"
          func={() => handleSaveScorecard()}
        />
      </div>
      <div className="flex flex-col gap-2 mb-4">
        <CustomRequirementModal
          isOpen={isCustomRequirementOpen}
          onClose={handleCloseCustomRequirement}
          onConfirm={handleConfirmCustomRequirement}
          title="Create Custom Requirement"
          confirmText="OK"
          cancelText="Cancel"
        />
      </div>

      <div className="flex flex-col pb-8">
        <h1 className="text-2xl font-bold">Create Scorecard</h1>
        <p className="text-gray-700 text-sm">
          You will be evaluating vendors based on your own custom criteria.
          Select which criteria to include, or create custom criteria to
          evaluate vendors with.
        </p>
      </div>
      <div className="flex space-x-3 w-full">
        <div className="w-1/2 lg:w-1/3 p-4 flex flex-col bg-white rounded-lg border border-gray-200 shadow-lg">
          <h1 className="text-2xl font-semibold mb-1">
            Available Requirements
          </h1>
          <p className="text-sm text-gray-700">
            Choose which requirements you'd like to evaluate vendors for.
          </p>
          <div className="py-3 space-y-6">
            <ActionButton
              text="Create Custom Requirement"
              func={() => setIsCustomRequirementOpen(true)}
            />
            <SingleLineTextInput
              label="Filter Requirements"
              inputVal={searchVal}
              inputUpdateFunc={(value) => setSearchVal(value)}
            />
          </div>
          <ItemList
            isScorecard={false}
            items={filteredAvailableRequirements}
            onItemSelected={handleItemClick}
          />
        </div>
        <div className="w-1/2 lg:w-2/3 p-4 flex flex-col bg-white rounded-lg border border-gray-200 shadow-lg">
          <div className="mb-6 space-y-2">
            <h1 className="text-2xl font-semibold ">Current Scorecard</h1>
            <div className="font-semibold">Vendors:</div>

            <div className="flex flex-wrap gap-2">
              {currentVendors.map((item) => (
                <div className="border border-gray-300 p-1 px-2 rounded-md">
                  {item}
                </div>
              ))}
            </div>
          </div>
          <ItemList
            isScorecard={true}
            items={selectedScorecardRequirements}
            onItemSelected={(item) => handleItemClick(item)}
          />
        </div>
      </div>
      <SlideOutDetail
        item={selectedItem}
        onClose={closeSlideOut}
        isOpen={isSlideOutOpen}
      />
    </div>
  );
};

export default CustomerCreateDemoScorecard;
