import React from 'react';

const QuestionDisplay = ({ question }) => {
  // Switch statement to render based on question type
  switch (question.type) {
    case 'Dropdown':
      return (
        <div className="mb-4">
          <label className="block text-sm font-medium text-grayBlue-700 mb-2">{question.text}</label>
          <select className="block w-full border border-grayBlue-300 rounded-md shadow-sm p-2 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50">
            {question.answers.map((answer, index) => (
              <option key={index} value={answer.text}>{answer.text}</option>
            ))}
          </select>
        </div>
      );
    case 'Checklist':
      return (
        <div className="mb-4">
          <label className="block text-sm font-medium text-grayBlue-700 mb-2">{question.text}</label>
          {question.answers.map((answer, index) => (
            <div key={index} className="flex items-center mb-2">
              <input type="checkbox" id={answer.id} name={answer.text} className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-grayBlue-300 rounded" />
              <label htmlFor={answer.id} className="ml-2 text-sm text-grayBlue-700">{answer.text}</label>
            </div>
          ))}
        </div>
      );
    case 'Radio Button':
      return (
        <div className="mb-4">
          <label className="block text-sm font-medium text-grayBlue-700 mb-2">{question.text}</label>
          {question.answers.map((answer, index) => (
            <div key={index} className="flex items-center mb-2">
              <input type="radio" id={answer.id} name={question.id} className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-grayBlue-300" />
              <label htmlFor={answer.id} className="ml-2 text-sm text-grayBlue-700">{answer.text}</label>
            </div>
          ))}
        </div>
      );
    case 'One Line Text Entry':
      return (
        <div className="mb-4">
          <label className="block text-sm font-medium text-grayBlue-700 mb-2">{question.text}</label>
          <input type="text" className="block w-full border border-grayBlue-300 rounded-md shadow-sm p-2 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50" />
        </div>
      );
    case 'Large Text Field':
      return (
        <div className="mb-4">
          <label className="block text-sm font-medium text-grayBlue-700 mb-2">{question.text}</label>
          <textarea className="block w-full border border-grayBlue-300 rounded-md shadow-sm p-2 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50" rows="4"></textarea>
        </div>
      );
    default:
      return null;
  }
};

export default QuestionDisplay;
