import React from "react";
import Button from "../../../components/Button";
import { DocumentTextIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";

export default function MiniDashboardScorecard({ scorecard, questionnaireId }) {
  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate(`/create-demo-scorecard?qid=${questionnaireId}`);
  };

  const handleScoreVendors = () => {
    navigate(`/score-vendors?qid=${questionnaireId}`);
  };

  const handleViewResults = () => {
    navigate(`/view-team-results?qid=${questionnaireId}`);
  };

  return (
    <div className="p-6 shadow-md rounded-lg space-y-4 flex-1 bg-grayBlue-50 flex flex-col">
      <div className="text-2xl font-bold text-gray-800">
        Select Winning Vendor:
      </div>

      {!scorecard ? (
        <div className="text-md text-gray-600">
          <p>Create a scorecard to help select the best vendor.</p>
          <Button onClick={handleEditClick} variant="primary" className="mt-4">
            Create Scorecard
          </Button>
        </div>
      ) : (
        <div className="flex flex-col h-full justify-between rounded-md">
          <div className="text-lg text-gray-700 bg-white rounded-md shadow-sm ">
            <div className="flex items-center justify-between border border-gray-200 rounded-md p-4">
              <div className="flex items-center space-x-2">
                <DocumentTextIcon className="h-8 w-8" />
                <div className="flex flex-col">
                  <span className="text-sm font-semibold">Team Scorecard</span>
                </div>
              </div>
              <Button onClick={handleEditClick} variant="secondary" size="small">
                Edit
              </Button>
            </div>
          </div>

          <div className="flex space-x-2 mt-4">
            <Button
              onClick={handleScoreVendors}
              variant="primary"
              size="medium"
              className="flex-1"
            >
              Score Vendors
            </Button>
            <Button
              onClick={handleViewResults}
              variant="secondary"
              size="medium"
              className="flex-1"
            >
              View Team Scores
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
