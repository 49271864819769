import React from "react";
import ActionButton from "./components/ActionButton";

export default function PageHeader({ pageName, actions }) {
  return (
    <div className="p-4 bg-gray text-lg font-bold bg-grayBlue-50/80 border-grayBlue-200 border border-1 flex items-center justify-between">
      <div>{pageName}</div>
      <div className='space-x-2'>
      {actions?.map((item) => (
        <ActionButton text={item.text} func={item.func} />
      ))}
      </div>
    </div>
  );
}
