import React from 'react'
import { XIcon } from '@heroicons/react/outline'
import Logo from '../../../assets/logo.png'
import {useNavigate} from 'react-router-dom'
import { useSelector } from 'react-redux'

const Header = () => {
  const navigate = useNavigate();
  const isAdmin = useSelector(state => state.auth?.user?.isAdmin)
  return (
    <div className='py-6 px-10 sm:px-16 flex justify-between fixed left-0 top-0 right-0 h-16 z-10 items-center bg-[#F8F9FC] text-lg border-grayBlue-200 border border-1'>
      <div>
              <img className="h-8" src={Logo} alt="ShortlistAdvice Logo" />
      </div>
      {isAdmin && <div className='cursor-pointer hover:bg-gray-200 p-2 rounded-full' onClick={()=>navigate("/questionnaire-editor")}>
        <XIcon className='h-5 w-5 '/>
      </div>}
    </div>
  )
}

export default Header
