import { createSlice } from '@reduxjs/toolkit'
import { login, logout, register, refreshUserData } from './authThunk'
import toast from "react-hot-toast";

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    passwordProtect: true,
    status: 'init',
    error: ''
  },
  reducers: {
    setAuthentication: (state, { payload }) => {
      state.isLogged = payload
    },
    setUserData: (state, { payload }) => {
      state.user = payload
    },
    clearAuthErrors: (state,action) => {
      state.error = ''
    }
  },
  extraReducers: {
    // --------
    // LOGIN
    // --------
    [login.pending]: (state, action) => {
      state.status = 'loading'
      state.user = null
      state.error = ''
    },
    [login.fulfilled]: (state, { payload }) => {
      state.status = 'success'
      state.user = payload
      state.passwordProtect = false;
    },
    [login.rejected]: (state, { payload }) => {
      state.status = 'failed'
      state.user = null;
      state.error = payload
      toast.error(payload)
      },
    // --------
    // REGISTER
    // --------
    [register.pending]: (state, action) => {
      state.status = 'loading'
      state.user = null
      state.error = ''
    },
    [register.fulfilled]: (state, { payload }) => {
      state.status = 'success'
      state.user = payload
    },
    [register.rejected]: (state, { payload }) => {
      state.status = 'failed'
      state.user = null
      state.error = payload
      toast.error(payload)
    },
    // --------
    // REFRESH
    // --------
    [refreshUserData.pending]: (state, action) => {
      state.status = 'loading'
      state.user = null
      state.error = ''
    },
    [refreshUserData.fulfilled]: (state, { payload }) => {
      state.status = 'success'
      state.user = payload
    },
    [refreshUserData.rejected]: (state, { payload }) => {
      state.status = 'failed'
      state.user = null
      state.error = payload
    },
    // --------
    // LOGOUT
    // --------
    [logout.pending]: (state, action) => {
      state.status = 'loading'
      state.user = null
    },
    [logout.fulfilled]: (state, { payload }) => {
      state.status = 'success'
      state.passwordProtect = true
      state.user = null
    },
    [logout.rejected]: (state, action) => {
      state.status = 'failed'
      state.user = null
    },
  }
})

export const { setAuthentication, setUserData, clearAuthErrors } = authSlice.actions
export const selectAuth = (state) => state.auth
export default authSlice.reducer
