import React, { useState, useMemo } from "react";
import { PlusCircleIcon, MinusCircleIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { useDispatch } from "react-redux";
import { addRequirementToScorecard, removeRequirementFromScorecard } from "../../../redux/scoreVendors/scoreVendorsSlice";

const ItemList = ({ isScorecard, items, onItemSelected }) => {
  const dispatch = useDispatch();
  const [expandedCategories, setExpandedCategories] = useState({});

  const toggleItemSelection = (id) => {
    if (isScorecard) {
      dispatch(removeRequirementFromScorecard(id))
    } else {
      dispatch(addRequirementToScorecard(id))
    }
  };

  const handleRowClick = (item, event) => {
    event.stopPropagation(); // Prevent row click action when selecting/deselecting
    onItemSelected(item);
  };

  const toggleCategory = (functionalArea) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [functionalArea]: !prev[functionalArea],
    }));
  };

  // Sort and group items by functionalArea
  const sortedAndGroupedItems = useMemo(() => {
    const sortedItems = [...items].sort((a, b) => a.functionalArea.localeCompare(b.functionalArea));
    return sortedItems.reduce((acc, item) => {
      (acc[item.functionalArea] = acc[item.functionalArea] || []).push(item);
      return acc;
    }, {});
  }, [items]);

  return (
    <div className="flex flex-col">
      {Object.keys(sortedAndGroupedItems).map((functionalArea) => (
        <div key={functionalArea}>
          <div 
            className="flex items-center justify-between text-lg font-semibold w-full bg-gray-200 p-2 my-1 cursor-pointer"
            onClick={() => toggleCategory(functionalArea)}
          >
            <span>{functionalArea}</span>
            <span>
              {expandedCategories[functionalArea] ? (
                <ChevronUpIcon className="h-6 w-6" />
              ) : (
                <ChevronDownIcon className="h-6 w-6" />
              )}
            </span>
          </div>
          {expandedCategories[functionalArea] && (
            <div>
              {sortedAndGroupedItems[functionalArea].map((item) => (
                <div key={item._id} className="flex items-center text-sm">
                  <div onClick={() => toggleItemSelection(item._id)}>
                    <button className="p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 focus:outline-none">
                      {isScorecard ? (
                        <MinusCircleIcon className="h-6 w-6 text-error-500" />
                      ) : (
                        <PlusCircleIcon className="h-6 w-6" />
                      )}
                    </button>
                  </div>
                  <span
                    onClick={(event) => handleRowClick(item, event)}
                    className="flex-1 hover:bg-gray-100 cursor-pointer p-2"
                  >
                    {item.name}
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ItemList;
