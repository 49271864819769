import { createSlice } from "@reduxjs/toolkit";
import {
  customerLogin,
  customerLogout,
  customerRegistration,
  getCustomerForLogin,
  getMyCustomerDetails,
  joinTeam,
} from "./customerAuthThunk";
import toast from "react-hot-toast";

const customerAuthSlice = createSlice({
  name: "customerAuth",
  initialState: {
    user: null,
    firstName: null,
    email: null,
    token: null,
    passwordProtect: true,
    status: "init",
    error: "",
    customer: null,
    scorecards: [],
  },
  reducers: {
    setAuthentication: (state, { payload }) => {
      state.isLogged = payload;
    },
    setUserData: (state, { payload }) => {
      state.user = payload;
    },
    clearAuthErrors: (state, action) => {
      state.error = "";
    },
    updateCustomerStatus: (state, { payload }) => {
      state.customer.status = payload;
    },
  },
  extraReducers: {
    // --------
    // LOGIN
    // --------
    [customerLogin.pending]: (state, action) => {
      state.status = "loading";
      state.user = null;
      state.token = null;
      state.error = "";
    },
    [customerLogin.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.user = payload.user;
      state.token = payload.token;
      state.passwordProtect = false;
    },
    [customerLogin.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.user = null;
      state.error = payload;
      toast.error(payload);
    },
    // --------
    // JOIN TEAM
    // --------
    [joinTeam.pending]: (state, action) => {
      state.status = "loading";
      state.user = null;
      state.token = null;
      state.error = "";
    },
    [joinTeam.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.user = payload.user;
      state.token = payload.token;
      state.passwordProtect = false;
    },
    [joinTeam.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.user = null;
      state.error = payload;
      toast.error(payload);
    },
    // --------
    // getCustomerForLogin
    // --------
    [getCustomerForLogin.pending]: (state, action) => {
      state.status = "loading";
      state.user = null;
      state.firstName = null;
      state.email = null;
      state.error = "";
    },
    [getCustomerForLogin.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.firstName = payload.customer.firstName;
      state.email = payload.customer.email;
    },
    [getCustomerForLogin.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
      toast.error(payload);
    },
    // --------
    // customerRegistration
    // --------
    [customerRegistration.pending]: (state, action) => {
      state.status = "loading";
      state.user = null;
      state.error = "";
    },
    [customerRegistration.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.user = payload.user;
      state.token = payload.token;
    },
    [customerRegistration.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.user = null;
      state.error = payload;
      toast.error(payload);
    },
    // --------
    // getMyCustomerDetails
    // --------
    [getMyCustomerDetails.pending]: (state, action) => {
      state.status = "loading";
      state.error = "";
    },
    [getMyCustomerDetails.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.customer = payload.customer;
      state.scorecards = payload.scorecards;
    },
    [getMyCustomerDetails.rejected]: (state, { payload }) => {
      state.status = "failed";
      toast.error(payload);
    },
    // --------
    // LOGOUT
    // --------
    [customerLogout.pending]: (state, action) => {
      state.status = "loading";
      state.user = null;
      state.token = null;
    },
    [customerLogout.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.passwordProtect = true;
      state.user = null;
    },
    [customerLogout.rejected]: (state, action) => {
      state.status = "failed";
      state.user = null;
    },
  },
});

export const {
  setAuthentication,
  setUserData,
  clearAuthErrors,
  updateCustomerStatus,
} = customerAuthSlice.actions;
export const selectAuth = (state) => state.auth;
export default customerAuthSlice.reducer;
