import React from "react";
import { TrashIcon } from "@heroicons/react/outline";
import { useDispatch } from "react-redux";
import { updateAnswerText } from "../../../../redux/questions/questionsSlice"; // Adjust import path

const SubcategoryQuestionAnswerItem = ({
  question,
  answer,
  onDelete,
  handleMoveAnswer,
  index,
  answers,
}) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    onDelete(answer.id);
  };

  const handleTextChange = (e) => {
    dispatch(
      updateAnswerText({
        questionId: question.id,
        answerId: answer.id,
        text: e.target.value,
        answers,
      })
    );
  };

  return (
    <div className="flex items-center w-full space-x-2 pr-0">
      <input
        type="text"
        value={answer.text}
        onChange={handleTextChange}
        placeholder="Type Answer Here"
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600"
      />
      <button
        className={
          index === 0
            ? "mb-1 px-1 rounded-md text-gray-300"
            : "mb-1 px-1 rounded-md hover:bg-gray-200"
        }
        onClick={() => handleMoveAnswer(index, index - 1)}
        disabled={index === 0}
      >
        ↑
      </button>

      <button
        className={
          index === answers.length - 1
            ? "mb-1 px-1 rounded-md text-gray-300"
            : "mb-1 px-1 rounded-md hover:bg-gray-200"
        }
        onClick={() => handleMoveAnswer(index, index + 1)}
        disabled={index === answers.length - 1}
      >
        ↓
      </button>

      <button
        onClick={handleDelete}
        className="flex items-center justify-center p-2 bg-transparent rounded-md text-slate-400 hover:text-error hover:bg-red-500 focus:outline-none focus:ring-0 focus:ring-transparent"
        
        aria-label="Delete"
      >
        <TrashIcon className="h-5 w-5" />
      </button>
    </div>
  );
};

export default SubcategoryQuestionAnswerItem;
