import {
    SpeakerphoneIcon
  } from "@heroicons/react/outline";

const Rating = ({ score, comment }) => {
    const maxRating = 5;
    const colorClasses = [
      'bg-error-500',    // Score of 1
      'bg-warning-500',     // Score of 2
      'bg-warning-300',     // Score of 3
      'bg-success-400',  // Score of 4
      'bg-success-500',  // Score of 5
    ];
  
    // Generate the array for the full rating, defaulting to the gray color.
    const ratingCircles = Array.from({ length: maxRating }, (_, index) => {
      return index < score ? colorClasses[score - 1] : 'bg-gray-200';
    });

    const speakerPhoneStyle = comment?.length > 0 ? 'h-4 w-4 text-gray-500 hover:text-gray-700 cursor-pointer z-10' : 'h-4 w-4 opacity-0 z-10'
  
    return (
      <div className="flex items-center space-x-1">
        {ratingCircles.map((color, index) => (
          <div key={index} className={`h-2 w-2 rounded-full ${color}`}></div>
        ))}
        <div className="group relative ">
          <SpeakerphoneIcon className={speakerPhoneStyle} />
          {comment?.length > 0 && <div className="z-20 absolute hidden group-hover:block -left-36 w-36 top-0 bg-gray-900 text-white text-xs rounded-lg px-2 py-1">
            {comment}
          </div>}
        </div>
      </div>
    );
  };
  
  export default Rating;
  