import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customerRegistration, joinTeam } from "../../redux/customerAuth/customerAuthThunk";
import Spinner from "../../components/Spinner";
import Logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";

export default function JoinTeam() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const status = useSelector(state => state.customerAuth.status);
  const firstName = useSelector(state => state.customerAuth.firstName);
  const errorMessage = useSelector(state => state.customerAuth.error); // Assuming there's an error message field
  const token = useSelector(state => state.customerAuth.token);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formError, setFormError] = useState("");
  const [scorecardId, setScorecardId] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const emailFromURL = searchParams.get("e");
    const scorecardIdFromURL = searchParams.get("sid");
    setEmail(emailFromURL.replace(' ', '+')); // Emails shouldnt have spaces, but '+' is interpreted as one.
    setScorecardId(scorecardIdFromURL);
  }, []);

  const handleSubmit = event => {
    event.preventDefault();
    setFormError('');

    // Password match check
    if (password !== confirmPassword) {
      setFormError("Passwords do not match");
      return;
    }

    // Password criteria check
    const passwordCriteria = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
    if (!passwordCriteria.test(password)) {
      setFormError("Password must include one uppercase letter, one lowercase letter, be at least 8 characters long, and include one of the following symbols: !@#$%^&*");
      return;
    }

    // Dispatch the registration action
    dispatch(joinTeam({ email, password, scorecardId }));
  };

  if (token) {
    navigate('/my-shortlists');
  }

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center bg-gray-50 text-center overflow-x-hidden">
      <div className="bg-white border border-gray-200 rounded-xl p-6 shadow-lg">
        <div className="flex flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md ">
            <img className="mx-auto h-12 w-auto" src={Logo} alt="ShortlistMatch Logo" />
            <h2 className="py-6 text-center text-2xl font-bold text-gray-900">
              Welcome to the team!
            </h2>
            <p className="text-sm">Please create an account to view your Shortlist Match Scorecards, and to prepare for vendor evaluations.</p>
          </div>

          <form className="mt-8 space-y-6 sm:mx-auto sm:w-full sm:max-w-md text-start" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div
                className="mt-1 block w-full border border-gray-300 bg-gray-50 text-gray-400 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-0 sm:text-sm"
              >
                {email}
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
                <input
                  type="password"
                  name="password"
                  id="password"
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </label>
              <div className='pl-1 pt-1 text-xs'>
                Password must include one uppercase letter, one lowercase letter, be at least 8 characters long, and include one of the following symbols: !@#$%^&*
              </div>
            </div>

            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                Confirm Password
                <input
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="Confirm your password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </label>
            </div>

            {formError && (
              <p className="mt-2 text-center text-sm text-error-600">
                {formError}
              </p>
            )}
            {errorMessage && (
              <p className="mt-2 text-center text-sm text-error-600">
                {errorMessage}
              </p>
            )}
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Create account
            </button>
          </form>
          <div className="text-xs mt-3">Already have an account? Please <a href="/customer-login" className="text-blue-700 hover:underline">click here</a> to log in.</div>
        </div>
      </div>
    </div>
  );
}
