import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getScorecardByQuestionnaireId } from "../../../../redux/scorecards/scorecardsThunk";
import MiniMemberRow from "./MiniMemberRow";
import Button from "../../../../components/Button";

export default function DashboardMembersList({ questionnaireId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.customerAuth.user);

  const selectedScorecard = useSelector(
    (state) => state.scorecards.selectedScorecard
  );

  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    if (!selectedScorecard) {
      dispatch(getScorecardByQuestionnaireId({ id: questionnaireId }));
    } else {
      const currentUserEmail = currentUser.email;
      const filteredTeamMembers = selectedScorecard.teamMembers
        .filter(member => member.email !== currentUserEmail)
        .map(member => {
          const memberScore = selectedScorecard.memberScores.find(
            score => score.email === member.email
          );
          return {
            ...member,
            completedScorecard: memberScore ? true : false,
          };
        });
  
      setTeamMembers(filteredTeamMembers);
    }
  }, [selectedScorecard, currentUser, dispatch, questionnaireId]);
  

  const handleEditTeam = () => {
    navigate(`/create-team?qid=${questionnaireId}`);
  };

  return (
    <div className="p-3 py-6 sm:p-6 shadow-md rounded-lg h-fit bg-grayBlue-50 flex-1">
      <div className="flex space-between items-center w-full items-center mb-3">
        <h3 className="text-xl font-semibold text-gray-900 flex-1">
          Team Members
        </h3>
        <Button className="py-1" variant={"secondary"} onClick={handleEditTeam}>
          Edit
        </Button>
      </div>
      <div className="flex flex-col flex-1 space-y-2">
        {teamMembers.length == 0 && "No Team Members."}
        {teamMembers.map((member, index) => (
          <MiniMemberRow
            key={index}
            index={index}
            firstName={member.firstName}
            lastName={member.lastName}
            status={member.status}
            completedScorecard={member.completedScorecard}
            email={member.email}
          />
        ))}
      </div>
    </div>
  );
}
