import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { setUserData } from '../redux/auth/authSlice'
import Sidebar from './Sidebar'

export const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const userData = JSON.parse(localStorage.getItem('user_data'))

  useEffect(() => {
    if (userData && !user) {
      dispatch(setUserData(userData))
    }
  }, [dispatch, user, userData])

  const isAuthenticated = user != null

  const [availableHeight, setAvailableHeight] = useState(window.innerHeight - 48)

  // Update the available height on window resize
  useEffect(() => {
    const handleResize = () => {
      setAvailableHeight(window.innerHeight - 48)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isAuthenticated ? (
    <div className="flex bg-white dark:bg-grayBlue-800 inset-0 dark:text-white">
      <Sidebar />
      <div className='flex w-full md:pl-64 pt-12 overflow-hidden'>
        <div style={{ height: availableHeight }} className='py-4 w-full bg-gradient-to-r from-white/40 to-grayBlue-100/20 bg-grayBlue-50 overflow-auto'>
          {children}
        </div>
      </div>
    </div>
  ) : <Navigate to='/admin-login' replace />
}
