import { ArrowLeftIcon } from "@heroicons/react/solid";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function CustomerBackToShortlists() {
  const navigate = useNavigate();
  
  const handleGoBack = () => {
    // Display confirmation dialog
    const confirm = window.confirm("Are you sure you'd like to go back? Any changes will not be saved.");
    
    // Check if user confirmed
    if (confirm) {
      navigate("/my-shortlists");  // Navigate to '/my-shortlists' if user confirms
    }
  };
  return (
    <div
      onClick={handleGoBack}
      className="pb-6 flex space-x-2 items-center hover:underline cursor-pointer text-sm w-48"
    >
      <ArrowLeftIcon className="h-3 w-3" />
      <span>Back to Shortlists</span>
    </div>
  );
}
