import React from "react";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import Dropdown from "../../../../components/Dropdown";
import { useDispatch } from "react-redux";
import {
  updateQuestionScoreMethod,
  updateQuestionTargetProperty,
  updateQuestionShowImportance,
} from "../../../../redux/questions/questionsSlice";
import ScoreMethodDropdown from "./ScoreMethodDropdown";
import { targetPropertyOptions } from "./targetPropertyOptions.js";

export default function QuestionSearchInfoRow({
  questionId,
  questionProperty,
  questionScoreMethod,
  questionShowImportance,
}) {
  const dispatch = useDispatch();

  console.log("QUESTION ROW");
  console.log(questionId);
  console.log(questionProperty);
  console.log(questionScoreMethod);
  console.log(questionShowImportance);

  const handleTargetPropUpdate = (option) => {
    dispatch(
      updateQuestionTargetProperty({
        id: questionId,
        targetProperty: option,
      })
    );
  };

  const handleScoreMethodUpdate = (option) => {
    dispatch(
      updateQuestionScoreMethod({
        id: questionId,
        scoreMethod: option,
      })
    );
  };

  const handleShowImportanceUpdate = () => {
    dispatch(
      updateQuestionShowImportance({
        id: questionId,
        showImportance: !questionShowImportance,
      })
    );
  };

  return (
    <div className="flex flex-col space-y-3 w-full">
      <div className="text-xs flex flex-col">
        <span className="font-bold">Target Property</span>
        <Dropdown
          onSelectOption={handleTargetPropUpdate}
          selectedValue={questionProperty || "None (No Score)"}
          options={targetPropertyOptions}
          maxHeight = 'max-h-24'
        />
      </div>
      <div className="text-xs flex flex-col">
        <span className="font-bold">Scoring Method</span>
        <ScoreMethodDropdown
          onSelectOption={handleScoreMethodUpdate}
          selectedValue={questionScoreMethod}
        />
      </div>
      <div
        onClick={() => handleShowImportanceUpdate()}
        className="cursor-pointer text-xs flex space-x-2"
      >
        <Checkbox value={questionShowImportance} />
        <span className="font-bold">Ask Customer for Importance Score?</span>
      </div>
    </div>
  );
}
