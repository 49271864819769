import { DotsVerticalIcon } from "@heroicons/react/outline";
import React from "react";
import SubcategoryQuestionCount from "./SubcategoryQuestionCount";
import SubcategoryItemDropdown from "./SubcategoryItemDropdown";

export default function SubCategoriesCreator({
  subcategories,
  addNewSubcategory,
  handleSubcategoryTitleChange,
  handleEditSubcategory,
}) {
  return (
    <div className="w-full bg-gray-50 px-4 py-6">
      <div className="font-bold text-xl">Add Subcategories</div>
      <p className="text-md text-gray-700 pt-2">
        Subcategories allow you to organize your data in a better way. This
        information will be linked with the category listed above.
      </p>
      <div className="my-4">
        
        {subcategories.map((subcategory, index) => (
          <div
            key={index}
            className="flex w-full items-end mb-4 space-x-2"
          >
            <div className="flex flex-col flex-grow">
              <label className='pb-2 text-sm text-gray-700 font-semibold'>Subcategory Title</label>
              <input
                type="text"
                name="title"
                value={subcategory.title}
                onChange={(e) =>
                  handleSubcategoryTitleChange(index, e.target.value)
                }
                className="flex-1 border border-gray-300 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600"
                placeholder="Subcategory title"
              />
            </div>
            <div
              className="cursor-pointer flex items-center text-sm space-x-2 bg-blue-50 hover:bg-blue-100 p-3 rounded-md py-2 text-blue-600 font-bold "
              onClick={() => handleEditSubcategory(subcategory)}
            >
              <span className>Add Questions</span>
              <SubcategoryQuestionCount number={subcategory.questions.length}/>
            </div>
            {/* Placeholder for vertical dots dropdown */}
            <SubcategoryItemDropdown subcategoryId={subcategory.id} />
          </div>
        ))}
      </div>
      <div
        className="shadow-sm cursor-pointer flex items-center justify-center w-24 px-4 py-1.5 text-sm border border-gray-300 bg-white font-bold text-gray-700 hover:bg-gray-50 rounded-lg"
        onClick={addNewSubcategory}
      >
        Add New
      </div>
    </div>
  );
}
