export const systems = [
"A3 Solutions",
"Abacum",
"Abas ERP",
"Accelo",
"Act!",
"Acterys",
"Acumatica",
"ADP Vantage HCM",
"ADP Workforce Now",
"Agile CRM",
"Aimplan",
"Aleph",
"Anaplan",
"Apptivo CRM",
"APS Payroll",
"Ascender Payroll",
"Ascentis",
"Axiom Software",
"BambooHR",
"Basis",
"Bitrix24 CRM",
"Bizview",
"BlackLine",
"Blue Marble Global Payroll",
"Board",
"BPC PowerForms",
"Brightpearl",
"Budgyt",
"Bullhorn",
"Bullhorn CRM",
"CakeHR",
"Calumo",
"Capsule CRM",
"Casetak",
"Castaway",
"Causal",
"CCH Tagetik",
"Centage Maestro Suite",
"Ceridian Dayforce",
"Cin7",
"Clockwork",
"Close",
"CloudPay",
"CMiC Construction Platform",
"Cofi",
"Copper (formerly ProsperWorks)",
"Cornerstone OnDemand",
"CorPeuM",
"Creatio (formerly bpm'online)",
"Cube Software",
"Data Rails",
"Daylite for Mac",
"DEACOM ERP",
"Deel",
"Deltek",
"Deltek Costpoint",
"DriveTrain",
"Dynamics NAV",
"E2 Shop System",
"Epicor ERP",
"Exact Globe",
"Farseer",
"FinancialForce Accounting",
"Finmark",
"Fishbowl Inventory",
"FMP Global",
"Forecast5",
"Forecastr",
"FreeAgent",
"FreshBooks",
"Freshsales",
"Genius Sheets",
"Gini",
"Globalization Partners",
"GoldMine",
"Greenhouse",
"GreenRope",
"Gusto",
"Gusto HR",
"Halogen TalentSpace",
"Heartland Payroll",
"Highrise CRM",
"HoneyBook",
"HubSpot CRM",
"IBM Kenexa",
"IBM Planning Analytics (formerly Cognos TM1)",
"iCIMS",
"IDU",
"IFS Applications",
"IFS World",
"Infor",
"Infor M3 CloudSuite",
"Infor d/EPM",
"Infusionsoft by Keap",
"Insight Software",
"Insightly",
"iSolved",
"JazzHR",
"Jedox",
"Jeeves ERP",
"Jirav",
"Justworks",
"KashFlow",
"Kashoo",
"Kepion",
"KPI.com",
"Kronos",
"Kustomer",
"Lawson M3",
"Less Annoying CRM",
"Lever",
"Limelight",
"Liveflow",
"Logica",
"Longview",
"LucaNet",
"Lumesse ETWeb",
"Martus",
"Maximizer CRM",
"Mercur",
"Meta4",
"Microsoft Dynamics 365 ERP",
"Microsoft Dynamics 365 CRM",
"Microsoft Dynamics AX",
"Microsoft Dynamics GP",
"Mondial",
"Mosaic.tech",
"MYOB",
"MyPayrollHR",
"Namely",
"Neeyamo",
"NetSuite Planning and Budgeting",
"NetSuite CRM",
"NetSuite ERP",
"Neubrain",
"NGA Human Resources",
"Nimble",
"Odoo",
"Odoo CRM",
"OneStream XF",
"OneUp",
"OnPay",
"OnPlan",
"OpenAir (NetSuite)",
"OpenGov",
"Oracle E-Business Suite",
"Oracle CRM",
"Oracle ERP Cloud",
"Oracle Fusion Cloud Enterprise Performance Management",
"Oracle HCM Cloud",
"Oracle Hyperion",
"Oracle JD Edwards",
"Oracle NetSuite Accounting Software",
"Oracle PeopleSoft",
"OrangeHRM",
"Papaya Global",
"Patriot Accounting",
"Patriot Payroll",
"Paychex Flex",
"Paycom",
"Paycor",
"Paylocity",
"PeopleSoft",
"Performance Canvas (Epicor)",
"Phocas Software",
"Pipedrive",
"PivotXL",
"Planful (formerly Host Analytics)",
"Planguru",
"Plex Systems",
"Plika",
"Power ON",
"Priority Software",
"ProfitFrog",
"Prophix",
"Pry",
"QAD ERP",
"Quantrix",
"Questica",
"Intuit QuickBooks Desktop",
"Intuit QuickBooks Payroll",
"Intuit Quickbooks Enterprise",
"Intuit Quickbooks Online (QBO)",
"Ramco ERP",
"Ramco HR & Payroll",
"Reckon One",
"Redtail CRM",
"Remote",
"Rippling",
"Rootstock",
"Saba",
"Sage 100 ERP",
"Sage 300 ERP",
"Sage 500 ERP",
"Sage 50cloud",
"Sage Accounting",
"Sage CRM",
"Sage HRMS",
"Sage Intacct",
"Sage Intacct Budgeting and Planning",
"Sage Payroll",
"Sage X3",
"Salesflare",
"Salesforce",
"SAP Business One",
"SAP Business Planning and Consolidation",
"SAP CRM",
"SAP ERP",
"SAP SuccessFactors",
"Scoro",
"SD Worx",
"SlickPie",
"SmartRecruiters",
"Solver",
"Spotlight Reporting",
"Square Payroll",
"StrategyBlocks",
"Stratify",
"Streak CRM",
"SugarCRM",
"SuiteCRM",
"SumTotal Systems",
"SurePayroll",
"Syft",
"Synder",
"SYSPRO ERP",
"Talentsoft",
"Tally.ERP 9",
"Telemetry",
"Tipalti",
"TriNet",
"UKG",
"Ultimate Software UltiPro",
"Unit4 ERP",
"Unit4 FP&A",
"Vareto",
"Vena Solutions",
"Viewpoint Vista",
"Vtiger CRM",
"Vyapar",
"Wagepoint",
"Wave Accounting",
"Wave Payroll",
"whatiff.io",
"Workable",
"Workbooks",
"Workday Financials",
"Workday Adaptive Planning",
"Workday HR",
"Workday Payroll",
"Xero",
"Xero Payroll",
"Xlerant BudgetPak",
"XLReporting",
"Yendo",
"Zendesk Sell (formerly Base CRM)",
"Zenefits",
"ZipBooks",
"Zoho Books",
"Zoho CRM",
]