import { HomeIcon, LinkIcon, LogoutIcon } from "@heroicons/react/outline";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MenuIcon } from "@heroicons/react/solid";
import { useRef, useState } from "react";
import { useOnClickOutside } from "../../utils/customHooks";
import Logo from "../../assets/logo.png";
import { customerLogout } from "../../redux/customerAuth/customerAuthThunk";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CustomerPortalSidebar() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const ref = useRef(null);
  const location = useLocation();
  useOnClickOutside(ref, () => setSidebarOpen(false));
  const dispatch = useDispatch();
  const navigation = [
    {
      name: "My Shortlists",
      href: "/my-shortlists",
      icon: HomeIcon,
    },
  ];

  // Hide menu option if we are on the "Score vendors" page
  let showMenuOption = true
  if (location.pathname === "/score-vendors") {
    showMenuOption= false
  }

  return (
    <div ref={ref}>
      <div className="flex items-center p-5 fixed top-0 w-full z-20 bg-white border-b border-gray-200">
        {showMenuOption && <MenuIcon
          className="w-6 h-6 cursor-pointer text-black"
          onClick={() => setSidebarOpen(!sidebarOpen)}
        />}
        <img className="h-8 mx-auto" src={Logo} alt="Logo" />
      </div>
      <div
        className={`fixed top-0 left-0 h-screen bg-white w-56 border-r border-gray-200 z-10 transition-all duration-300 ease-in-out transform ${
          sidebarOpen ? "translate-x-0" : "-translate-x-full"
        } pt-20`}
      >
        <nav className="flex-1 px-2 space-y-2">
          {navigation.map((item) => (
            <NavLink
              key={item.name}
              onClick={() => setSidebarOpen(false)}
              to={item.href}
              className={({ isActive }) =>
                classNames(
                  isActive
                    ? "bg-grayBlue-500 text-white"
                    : "text-black hover:bg-gray-200",
                  "group flex items-center px-2 py-2 w-full text-sm font-medium rounded-md transition-all duration-100"
                )
              }
            >
              <item.icon className="mr-3 h-6 w-6" aria-hidden="true" />
              {item.name}
            </NavLink>
          ))}
          <NavLink
            to="/customer-login"
            onClick={() => {
              dispatch(customerLogout());
              setSidebarOpen(false);
            }}
            className="text-black hover:bg-gray-200 group flex items-center px-2 py-2 w-full text-sm font-medium rounded-md"
          >
            <LogoutIcon className="mr-3 h-6 w-6" aria-hidden="true" />
            Logout
          </NavLink>
        </nav>
      </div>
    </div>
  );
}
