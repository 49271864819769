import React from "react";
import CheckIcon from './CheckIcon.svg'
import { useNavigate } from "react-router-dom";

export default function SubmitSuccess() {
  const navigate = useNavigate();
  return (
    <div className="absolute inset-0 flex flex-col items-center justify-center bg-[#0B4A6F] p-4 text-center">
      <div className="w-20 h-20">
        <img src={CheckIcon} />
      </div>
      <div className="text-[#B9E6FE] text-3xl font-semibold p-4">Fantastic, you're all set!</div>
      <div className="text-sm text-white text-center px-4 max-w-xl">
      A confirmation email has been sent to you. A representative will contact you within <b>24 hours</b> with your results and will give you personalized advice.
      </div>
      <div
      onClick={()=>navigate("/customer-login")}
      className='mt-2 hover:bg-white hover:text-[#0B4A6F] transition-all duration-150 cursor-pointer border border-white text-white p-2 mt-8 rounded-md w-full text-center max-w-sm'>
        Done
      </div>
    </div>
  );
}
