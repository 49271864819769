import {configureStore} from '@reduxjs/toolkit'
import authReducer from './auth/authSlice'
import questionsReducer from './questions/questionsSlice'
import vendorsReducer from './vendors/vendorsSlice'
import questionnaireSlice from './questionnaire/questionnaireSlice'
import requirementsSlice from './requirements/requirementsSlice'
import scoreVendorsSlice from './scoreVendors/scoreVendorsSlice'
import customersSlice from './customers/customersSlice'
import customerAuthSlice from './customerAuth/customerAuthSlice'
import scorecardsSlice from './scorecards/scorecardsSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    customerAuth: customerAuthSlice,
    questions: questionsReducer,
    vendors: vendorsReducer,
    questionnaire: questionnaireSlice,
    requirements: requirementsSlice,
    scoreVendors: scoreVendorsSlice,
    customers: customersSlice,
    scorecards: scorecardsSlice,
  }
})
