import React, { useState } from "react";
import MemberStatusBadge from "../../../components/MemberStatusBadge";
import Button from "../../../components/Button";
import Spinner from "../../../components/Spinner";
import { removeMemberFromTeam, reSendTeamMemberInvite } from "../../../redux/scorecards/scorecardsThunk";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

const MemberRow = ({
  email,
  firstName,
  lastName,
  status,
  completedScorecard,
  currentUserName,
  currentScorecardId,
}) => {
  const dispatch = useDispatch();

  const [loading, setIsLoading] = useState(false);

  const handleSendEmail = async () => {
    const isConfirmed = window.confirm(
      "Did your user not get their invitation? If so, please use this to trigger a manual re-send. Be sure they check their spam folder."
    );
    if (isConfirmed) {
      setIsLoading(true);
      try {
        const body = {
          invitingUserName: currentUserName,
          email: email,
          scorecardId: currentScorecardId,
        };
        const resultAction = dispatch(reSendTeamMemberInvite({ body }));
        await unwrapResult(resultAction);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to send email:", error);
        setIsLoading(false); // Ensure loading state is reset on failure
      }
    }
  };

  const handleRemoveMember = async () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to remove this member? This will remove all of their data. This cannot be undone."
    );
    if (isConfirmed) {
      setIsLoading(true);
      try {
        const body = {
          email,
          scorecardId: currentScorecardId,
        }
        const resultAction = dispatch(removeMemberFromTeam({ body }));
        
        await unwrapResult(resultAction);

        // Reset loading state or any other state updates
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to remove member:", error);
        setIsLoading(false); // Ensure loading state is reset on failure
      }
    }
  };

  if (loading) {
    return (
      <div className="p-6">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col lg:flex-row items-stretch lg:items-center justify-between bg-white rounded shadow-sm space-y-3 border p-2 sm:p-3 sm:px-6 border-gray-200">
      <div className="flex items-start space-x-3">
        <img
          src={`https://www.gravatar.com/avatar/00000000000000000?d=mp`}
          alt={`${firstName} ${lastName}`}
          className="w-10 h-10 rounded-full"
        />
        <div className="flex-1 flex flex-col overflow-hidden">
          <p className="font-medium">
            {firstName} {lastName}
          </p>
          <p className="text-sm text-gray-500 truncate">{email}</p>
          <div className="flex flex-wrap gap-1 py-1 pt-2">
            <MemberStatusBadge status={status} size="small" />
            {(status === "joined" || status === "owner") &&  (
              <MemberStatusBadge
                status={completedScorecard ? "scorecardComplete" : "scorecardIncomplete"}
                size="small"
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-1">
        {/* <Button variant="primaryTableAction" size="small">
          Copy invite Link
        </Button> */}
        <Button onClick={handleSendEmail} variant="tableAction" size="small">
          Re-Send Invite
        </Button>
        <Button onClick={handleRemoveMember} variant="danger" size="small">
          Remove
        </Button>
      </div>
    </div>
  );
};

export default MemberRow;
