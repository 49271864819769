import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CategorySelect from "../defaultForms/CategorySelect";
import QuestionDisplay from "./QuestionDisplay";

export default function QuestionStep({ stepId, animation }) {
  const [category, setCategory] = useState("");
  const [subcatTitle, setSubcatTitle] = useState("");
  const [headline, setHeadline] = useState("");
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);

  // Selecting state from Redux store
  const { currentCategoryIndex, currentSubcategoryIndex, categories } =
    useSelector((state) => ({
      currentCategoryIndex: state.questionnaire.currentCategoryIndex,
      currentSubcategoryIndex: state.questionnaire.currentSubcategoryIndex,
      categories: state.questionnaire.categories,
    }));

  // Always scroll to top whenever the questionId Changes
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentCategoryIndex, currentSubcategoryIndex]);

  useEffect(() => {
    if (categories?.length > 0 && currentCategoryIndex < categories?.length) {
      const currentCategory = categories[currentCategoryIndex];
      if (currentSubcategoryIndex < currentCategory.subcategories.length) {
        const currentSubcategory =
          currentCategory.subcategories[currentSubcategoryIndex];
        setCategory(currentCategory.title);
        setSubcatTitle(currentSubcategory.title);
        setHeadline(currentSubcategory.headline);
        setQuestions(currentSubcategory.questions);
        // You might also want to set answers here if needed
      }
    }
  }, [currentCategoryIndex, currentSubcategoryIndex, categories]);

  return (
    <div
      className={`bg-white px-6 lg:px-8 mb-36 ${
        animation === "slideInRight"
          ? "animate-slideInRight"
          : animation === "slideInLeft"
          ? "animate-slideInLeft"
          : animation === "slideOutLeft"
          ? "animate-slideOutLeft"
          : "animate-slideOutRight"
      }`}
    >
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="fontSize-xl font-bold tracking-tight text-gray-900 text-md pb-2">
          {category}
        </h2>
        <h2 className="fontSize-xl font-bold tracking-tight text-gray-900 text-3xl">
          {subcatTitle}
        </h2>
        <p className="mt-2 text-lg leading-8 text-gray-600">{headline}</p>
      </div>
      <div className="mx-auto bg-grayBlue-25 p-5 ring-1 ring-inset ring-grayBlue-300 rounded-lg mt-7 max-w-xl sm:mt-7">
        {questions.length === 0 && "No Questions for Subcategory"}
        {/* Render your questions here */}
        {questions.map((question, quesIndex) => (
          <div key={question.id} className="mb-4">
            <QuestionDisplay question={question} />
          </div>
        ))}
      </div>
      {/* Content */}
    </div>
  );
}
