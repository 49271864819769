import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom'
import MatchReport from "../QuestionnaireResults/components/LiveDataScorecard/MatchReport";

export default function CustomerScorecardView() {
  const firstName = useSelector((state) => state.customerAuth.firstName);
  const navigate = useNavigate();
  return (
    <div className="p-6 flex flex-col">
      <div className="flex flex-col p-6 space-y-2 text-sm bg-white mb-12 border border-gray-200 rounded-lg shadow-lg">
        <div>Hello {firstName},</div>
        <p>
          Our team has reviewed your responses with the help of our AI
          Assistant, and come up with the following match scores for you, based
          on your criteria.
        </p>
        <p>
          After reviewing the match scores below, your next step will be to
          create a scorecard, to help you and your team make a final decision
          about which Vendors to use, based on their presentations.
        </p>
        <div className='py-3 w-full flex items-center justify-center'>
        <button onClick={() => navigate('/create-demo-scorecard')} className='bg-blue-700 hover:bg-blue-900 p-2 px-4 text-white font-semibold rounded-md max-w-lg'>Click Here to Create Your Scorecard</button>
        </div>
      </div>
      <MatchReport />
    </div>
  );
}
