import { createAsyncThunk } from '@reduxjs/toolkit'
import ShortListAdviceAPI from '../ShortListAdviceAPI.js'

export const login = createAsyncThunk('auth/login', async (data, { rejectWithValue }) => {
  try {
    const response = await ShortListAdviceAPI.post('api/auth/adminLogin', data)
    localStorage.setItem('user_data', JSON.stringify(response.data))
    localStorage.setItem('auth_token', response.data.token)
    return response.data
  } catch (error) {
    throw rejectWithValue(error.response.data.message)
  }
})

export const register = createAsyncThunk('auth/register', async (data, { rejectWithValue }) => {
  try {
    const response = await ShortListAdviceAPI.post('api/auth/', data)
    localStorage.setItem('user_data', JSON.stringify(response.data))
    localStorage.setItem('auth_token', response.data.token)
    return response.data
  } catch (error) {
    throw rejectWithValue(error.response.data.message)
  }
})

export const refreshUserData = createAsyncThunk('auth/refreshData', async ( _, { rejectWithValue }) => {
  try {
    const response = await ShortListAdviceAPI.get('api/auth/profile')
    localStorage.setItem('user_data', JSON.stringify(response.data))
    localStorage.setItem('auth_token', response.data.token)
    return response.data
  } catch (error) {
    throw rejectWithValue(error.response.data.message)
  }
})

export const logout = createAsyncThunk('auth/logout', async () => {
  localStorage.removeItem('user_data')
  localStorage.removeItem('auth_token')

  
  // Trigger a full page reload to reset the entire Redux state
  window.location.reload();
  
  const response = {
    data: null
  }
  return response.data
})

export const resetPasswordReq = createAsyncThunk('auth/resetPasswordReq', async ({email}, { rejectWithValue }) => {
  try {
    const lowercaseEmail = email.toLowerCase();
    const response = await ShortListAdviceAPI.post('/resetPasswordRequest', {
      email: lowercaseEmail,
    })
    return response.data
  } catch (error) {
    throw rejectWithValue(error.response.data.message)
  }
})

export const resetPassword = createAsyncThunk('auth/resetPassword', async (data, { rejectWithValue }) => {
  try {
    const response = await ShortListAdviceAPI.put('/resetPassword', data)
    return response.data
  } catch (error) {
    throw rejectWithValue(error.response.data.message)
  }
})