import React from "react";
import MemberStatusBadge from "../../../../components/MemberStatusBadge";

const MiniMemberRow = ({
  email,
  firstName,
  lastName,
  status,
  completedScorecard,
}) => {
  return (
    <div className="flex flex-col items-stretch justify-between bg-white rounded shadow-sm space-y-3 border p-3 border-gray-200">
      <div className="flex items-start space-x-3">
        <img
          src={`https://www.gravatar.com/avatar/00000000000000000?d=mp`}
          alt={`${firstName} ${lastName}`}
          className="w-10 h-10 rounded-full"
        />
        <div className="flex-1 flex flex-col overflow-hidden">
          <p className="font-medium">
            {firstName} {lastName}
          </p>
          <p className="text-sm text-gray-500 truncate">{email}</p>
          <div className="flex flex-wrap gap-1 py-1 pt-2">
            <MemberStatusBadge status={status} size="small" />
            {(status === "joined" || status === "owner") &&  (
              <MemberStatusBadge
                status={completedScorecard ? "scorecardComplete" : "scorecardIncomplete"}
                size="small"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniMemberRow;
