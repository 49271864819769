import { createAsyncThunk } from "@reduxjs/toolkit";
import CustomerAPI from "../CustomerAPI"; // Replace with your actual API utility
import ShorlistAdviceAPI from "../ShortListAdviceAPI";

export const saveTeam = createAsyncThunk(
  "scorecards/saveTeam",
  async ({ body }, { rejectWithValue }) => {
    try {
      // Construct the query string with the retrieved page and limit values
      const response = await CustomerAPI.post("/api/scorecards/saveTeam", body);

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const populateRequirementDetails = createAsyncThunk(
  "scorecards/populateRequirementDetails",
  async ({ id }, { rejectWithValue }) => {
    try {
      // Construct the query string with the retrieved page and limit values
      const response = await CustomerAPI.get("/api/scorecards/" + id);

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getScorecardByQuestionnaireId = createAsyncThunk(
  "scorecards/getScorecardByQuestionnaireId",
  async ({ id }, { rejectWithValue }) => {
    try {
      // Construct the query string with the retrieved page and limit values
      const response = await CustomerAPI.get(
        "/api/scorecards/getScorecardByQuestionnaireId/" + id
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getScorecardByQuestionnaireIdAdmin = createAsyncThunk(
  "scorecards/getScorecardByQuestionnaireId",
  async ({ id }, { rejectWithValue }) => {
    try {
      // Construct the query string with the retrieved page and limit values
      const response = await ShorlistAdviceAPI.get(
        "/api/scorecards/getScorecardByQuestionnaireId/" + id
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const saveScorecardDataByQuestionnaireId = createAsyncThunk(
  "scorecards/saveScorecardDataByQuestionnaireId",
  async ({ qid, body }, { rejectWithValue }) => {
    try {
      // Construct the query string with the retrieved page and limit values
      const response = await CustomerAPI.post(
        "/api/scorecards/saveScorecardDataByQuestionnaireId/" + qid,
        body
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const reSendTeamMemberInvite = createAsyncThunk(
  "scorecards/reSendTeamMemberInvite",
  async ({ body }, { rejectWithValue }) => {
    try {
      // Construct the query string with the retrieved page and limit values
      const response = await CustomerAPI.post(
        "/api/email/reSendTeamMemberInvite",
        body
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const removeMemberFromTeam = createAsyncThunk(
  "scorecards/removeMemberFromTeam",
  async ({ body }, { rejectWithValue }) => {
    try {
      // Construct the query string with the retrieved page and limit values
      const response = await CustomerAPI.post(
        `/api/scorecards/removeMemberFromTeam`,
        body
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
