import React from 'react'
import PasswordProtect from './components/PasswordProtect'

const SignIn = () => (
  <div className='h-screen bg-gradient-to-r from-blue-800 to-blue-900  text-textWhite overflow-y-auto'>
    <PasswordProtect />
  </div>
)

export default SignIn
