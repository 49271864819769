import React from 'react'
import TabController from './components/TabController'
import ScorecardForm from './components/ScorecardForm'

export default function ScoreVendors() {
  return (
    <div className='relative'>
        <TabController />
        <div className='bg-gray-50 z-0'>
        <ScorecardForm />
        </div>
        
    </div>
  )
}
