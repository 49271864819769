import React from "react";
import { CheckIcon, XIcon } from "@heroicons/react/solid";

// Define the styles for each status
const statusStyles = {
  notYetInvited: `text-grayBlue-700 bg-grayBlue-100 border border-grayBlue-300`,
  pendingInvite: `text-warning-500 bg-warning-100 border border-warning-300`,
  joined: `text-success-600 bg-success-100 border border-success-500`,
  owner:  `text-success-600 bg-success-100 border border-success-500`,
  scorecardComplete: `text-success-600 bg-success-100 border border-success-500`,
  scorecardIncomplete: `text-error-600 bg-error-100 border border-error-500`,
};

// Define the styles for the "light" circle
const circleStyles = {
  notYetInvited: "bg-grayBlue-700",
  pendingInvite: "bg-warning-500",
  joined: "bg-success-600",
  owner: "bg-success-600",
  scorecardComplete: "bg-success-600",
  scorecardIncomplete: "bg-error-600",
};

// Define the sizes for the badges (if needed)
const badgeSizes = {
  small: "text-xs px-2 py-0.5",
  medium: "text-sm px-3 py-1.5",
  large: "text-lg px-4 py-2",
};

// The MemberStatusBadge component
const MemberStatusBadge = ({
  status = "notYetInvited",
  size = "medium",
  className = "",
  ...props
}) => {
  // Combine both style and size
  const style = `${statusStyles[status]} ${badgeSizes[size]} ${className}`;

  return (
    <span
      className={`inline-flex items-center rounded-md transition-all ${style}`}
      {...props}
    >
      {status !== "scorecardComplete" && status !== "scorecardIncomplete" && (
        <span
          className={`inline-block w-1.5 h-1.5 rounded-full mr-1 ${circleStyles[status]}`}
        ></span>
      )}
      {status === "notYetInvited" && "Not Yet Invited"}
      {status === "pendingInvite" && "Invite Sent"}
      {status === "joined" && "Joined"}
      {status === "owner" && "Owner"}
      {status === "scorecardComplete" && (
        <>
          <CheckIcon className="w-4 h-4 mr-1" /> Scorecard Complete
        </>
      )}
      {status === "scorecardIncomplete" && (
        <>
          <XIcon className="w-4 h-4 mr-1" /> Scorecard Incomplete
        </>
      )}
    </span>
  );
};

export default MemberStatusBadge;
