import React from "react";
import Timeline from "./ShortlistCard/Timeline";

export default function CardHeader({ createdAt, categories }) {
  return (
    <div className="p-4 w-full md:w-1/3 bg-grayBlue-50 rounded-lg shadow-md">
      <div className="text-2xl font-bold text-gray-800">Shortlist 1</div>
      <div className="text-sm font-semibold text-gray-600">
        Created on:{" "}
        <span className="font-medium">
          {createdAt.toLocaleDateString()} at {createdAt.toLocaleTimeString()}
        </span>
      </div>
      <div className="text-xs italic text-gray-500 mt-2">
        <div>Categories:</div>

        {categories.length === 0 ? (
          <p>None</p>
        ) : (
          <div>Categories: {categories.join(", ")}</div>
        )}
      </div>
      <Timeline />
    </div>
  );
}
