import React, { useState, useEffect } from 'react';
import { dummyQuestions, dummyResults } from './dummyData';
import VendorScorecard from './VendorScorecard'; // Import the VendorScorecard component
import { queryVendorProperties } from '../../../../redux/vendors/vendorsThunk';
import {useDispatch, useSelector} from 'react-redux';
import Spinner from '../../../../components/Spinner';


export default function SearchSample() {
  const [questionnaire, setQuestionnaire] = useState(dummyQuestions);
  // const [results, setResults] = useState(dummyResults);
  const results = useSelector(state => state.vendors.vendorResults);
  const status = useSelector(state => state.vendors.status);
  const [answers, setAnswers] = useState(dummyQuestions.Questionnaire.map(q => ({
    ...q,
    Importance: 5 // Default importance
  })));
  const dispatch = useDispatch()


  const [scorecards, setScorecards] = useState([]); // State to store the scorecards

  
  const handleAnswerChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index].Answer = value;
    setAnswers(newAnswers);
  };

  const handleImportanceChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index].Importance = value;
    setAnswers(newAnswers);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(answers)
    dispatch(queryVendorProperties(answers)); // Dispatch your Redux action
  };

  useEffect(() => {
    function createVendorScorecards(data) {
        const vendorScores = {};
    
        // Iterate over each question
        data.forEach(question => {
            const targetField = question.targetField;
            const answer = question.answer;
            const importance = question.importance;
    
            // Iterate over each vendor in similarDocuments
            question.similarDocuments.forEach(doc => {
                // Initialize the vendor in the map if not present
                if (!vendorScores[doc._id]) {
                    vendorScores[doc._id] = {
                        _id: doc._id,
                        VendorName: doc.VendorName,
                        // Initialize each field with an object to store score, answer and property value
                        RevenueRanges: { score: 0, answer: '', propertyValue: '' },
                        MinimumAnnualCost: { score: 0, answer: '', propertyValue: '' },
                        FrontEndPlatform: { score: 0, answer: '', propertyValue: '' }
                    };
                }
    
                // Aggregate the score for the specific field and store answer and property value
                let fieldData = vendorScores[doc._id][targetField];
                fieldData.score += doc.score;
                fieldData.answer = answer;
                fieldData.importance = importance;
                fieldData.propertyValue = doc[targetField];
            });
        });
    
        // Convert the map to an array and normalize scores if needed
        return Object.values(vendorScores);
    }
    
    
    // Sample usage with your data structure
    const scorecards = createVendorScorecards(results);
    console.log(scorecards);
    setScorecards(scorecards);
  }, [results])
  

  return (
    <div className="flex h-screen">
    <div className="flex-1 p-5 overflow-y-auto">
        <form onSubmit={handleSubmit} className="space-y-4">
            {answers.map((item, index) => (
                <div key={index} className="flex flex-col p-2 border border-grayBlue-300 rounded-lg">
                    <label className="text-grayBlue-700 font-semibold">Question {`${index + 1}`}</label>
                    <div className="text-grayBlue-600">{item.Question}</div>
                    <input
                        type="text"
                        value={item.Answer}
                        onChange={(e) => handleAnswerChange(index, e.target.value)}
                        className="mt-2 p-2 border border-grayBlue-300 rounded-md"
                    />
                    <div className='flex items-center mt-2'>
                        <div className="text-grayBlue-700">Importance:</div>
                        <select
                            value={item.Importance}
                            onChange={(e) => handleImportanceChange(index, e.target.value)}
                            className="ml-2 p-2 border border-grayBlue-300 rounded-md"
                        >
                            {[1, 2, 3, 4, 5].map(num => (
                                <option key={num} value={num}>{num}</option>
                            ))}
                        </select>
                    </div>
                </div>
            ))}
            <button type="submit" className="px-4 py-2 bg-grayBlue-500 text-white font-bold rounded-lg hover:bg-grayBlue-600">Submit</button>
        </form>
    </div>
    
    <div className='w-[50%]'>
    {status == 'loading' ? <Spinner /> : <VendorScorecard vendors={scorecards} />}
    </div>
</div>

  );
}

// <div className="flex-1 p-5 overflow-y-auto">
//        {results.map((result, index) => (
//             <div key={index} className="mb-5 p-3 border border-grayBlue-300 rounded-lg">
//                 <h3 className="text-grayBlue-700 font-semibold">{result.question}</h3>
//                 <p className="text-grayBlue-600">Answer: {result.answer}</p>
//                 <p className="text-grayBlue-600">Target Field: {result.targetField}</p>
//                 <div className="mt-3 space-y-2">
//                     {result.similarDocuments.map((doc) => (
//                         <div key={doc._id} className="border-t border-grayBlue-300 pt-2">
//                             <p className="text-grayBlue-600">Vendor Name: {doc.VendorName}</p>
//                             <p className="text-grayBlue-600">Score: {doc.score}</p>
//                             {/* Display other fields as needed */}
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         ))}
//    </div>