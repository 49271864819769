import React, { useState, useEffect, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ScoreMethodDropdown({
  onSelectOption = () => {},
  selectedValue = null,
}) {

    const optionValues = [
          'Vector Search',
          'Closer Numbers first',
          'Higher Numbers first',
          'Lower Numbers first',
          '"Includes" Search',
          '"Excludes" Search',
      ];
    
  const options = [
    {
      title: 'Vector Search',
      desc: 'Results are scored based on text-similarity (concept-similarity) to the customer answer',
    },
    {
      title: 'Closer Numbers first',
      desc: 'Results score higher the closer to the customer answer they are, regardless of direction',
    },
    {
      title: 'Higher Numbers first',
      desc: 'Numbers greater than the customer answer are ranked better than lower numbers.',
    },
    {
      title: 'Lower Numbers first',
      desc: 'Numbers less than the customer answer are ranked better than higher numbers.',
    },
    {
      title: '"Includes" Search',
      desc: 'Results score high only if they include exactly the customer answer',
    },
    {
      title: '"Excludes" Search',
      desc: 'Results score lower if they include exactly the customer answer',
    },
  ];

  const [selected, setSelected] = useState(selectedValue || '');

  useEffect(() => {
    if (optionValues.includes(selectedValue)) {
      setSelected(selectedValue);
    } else {
      setSelected("");
    }
  }, [selectedValue]);

  const handleChange = (selection) => {
    setSelected(selection);
  };

  useEffect(() => {
    onSelectOption(selected);
  }, [selected]);

  

  return (
    <Menu
      as="div"
      className="relative inline-block text-left w-full text-xs font-semibold"
    >
      <div>
        <Menu.Button className="inline-flex w-full justify-between items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-xs text-left font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          {selected || "Choose an Option"}
          <ChevronDownIcon
            className="h-4 w-4 text-gray-900"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute mt-1 left-0 z-10 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-300 ring-opacity-5 focus:outline-none">
          <div className="py-1 max-h-32 overflow-y-auto">
            {options.map((option) => (
              <Menu.Item key={option.title}>
                {({ active }) => (
                  <button
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900 " : "text-gray-700",
                      "w-full px-4 py-2 text-left text-xs flex flex-col"
                    )}
                    onClick={() => handleChange(option.title)}
                  >
                    <span>{option.title}</span>
                    <span className='font-normal'>{option.desc}</span>
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
