import React, { useState } from "react";
import TeamScorecard from "./components/TeamScorecard";

import CallbackCheckbox from '../../components/Checkbox/CallbackCheckbox'
import MatchReport from "../QuestionnaireResults/components/LiveDataScorecard/MatchReport";

const tabs = ["Overall Scores", "Team Breakdown", "Select Winner"];

export default function Scorecards() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [showWeighted, setShowWeighted] = useState(false)
  return (
    <div className="overflow-y-auto h-screen">
      <div className="p-6 text-2xl font-bold">Scorecards</div>
      <div className="pb-6 pl-6 flex items-center justify-start space-x-2">
        {tabs.map((tab, idx) => {
          return (
            <div
              onClick={() => setSelectedTab(idx)}
              className={
                idx == selectedTab
                  ? "p-1.5 px-3 rounded-md text-sm bg-blue-50 text-blue-700 font-semibold shadow-sm border border-blue-700 cursor-pointer"
                  : "p-1.5 px-3 rounded-md text-sm bg-white border border-gray-200 shadow-sm cursor-pointer"
              }
            >
              {tab}
            </div>
          );
        })}
      </div>
      <div className="pb-6 pl-6 flex space-x-2 items,center text-sm cursor-pointer" onClick={()=>setShowWeighted(!showWeighted)}>
        <CallbackCheckbox cbFunc={()=>setShowWeighted(!showWeighted)} value={showWeighted} />
        <span>Show scores weighted by Importance</span>
      </div>
      {selectedTab == 0 && <MatchReport />}
      {selectedTab == 1 && <TeamScorecard />}
    </div>
  );
}
