import React from "react";

export default function SingleLineTextInput({
  label,
  inputVal,
  inputUpdateFunc,
  placeholder = "",
}) {
  const updateVal = (value) => {
    inputUpdateFunc(value);
  };

  return (
    <div className="">
      <label className="block text-sm font-medium text-grayBlue-700 mb-2">
        {label}
      </label>
      <input
        onChange={(e) => updateVal(e.target.value)}
        placeholder={placeholder}
        type="text"
        value={inputVal}
        className="border border-gray-300 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600 w-full"
      />
    </div>
  );
}
