import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getGptScoresForQuestionnaire } from "../../../redux/questionnaire/questionnaireThunk.js";
import {
  setIsInitialLoad,
  setSelectedVendors,
} from "../../../redux/questionnaire/questionnaireSlice.js";
import Spinner from "../../../components/Spinner.js";

const GptScores = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.questionnaire.gptScores);
  const currentScoresId = useSelector(
    (state) => state.questionnaire.gptScores.questionnaireId
  );
  const selectedVendors = useSelector(
    (state) => state.questionnaire.gptScores.selectedVendors
  );
  const isInitialLoad = useSelector(
    (state) => state.questionnaire.gptScores.isInitialLoad
  );
  const status = useSelector((state) => state.questionnaire.status);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const questionnaireId = searchParams.get("qid");

    if (currentScoresId !== questionnaireId && status !== "loading") {
      dispatch(setIsInitialLoad({ isInitialLoad: true }));
      dispatch(getGptScoresForQuestionnaire({ questionnaireId }));
    }
  }, [dispatch, currentScoresId]);

  useEffect(() => {
    if (data.overallScores && status == "success") {
      const vendors = Object.keys(data.overallScores);
      const initialSelectedVendors = vendors.reduce((acc, vendor, index) => {
        // Set the first 5 vendors as selected, rest as not selected
        acc[vendor] = index < 5;
        return acc;
      }, {});
      dispatch(setSelectedVendors({ selectedVendors: initialSelectedVendors }));
      dispatch(setIsInitialLoad({ isInitialLoad: false }));
    }
  }, [data.overallScores]);

  const toggleVendorSelection = (vendor) => {
    dispatch((dispatch, getState) => {
      const updatedSelectedVendors = {
        ...selectedVendors,
        [vendor]: !selectedVendors[vendor],
      };
      dispatch(setSelectedVendors({ selectedVendors: updatedSelectedVendors }));
    });
  };

  const getScoreColor = (score) => {
    score = parseFloat(score);
    if (score > 0.9) return "bg-green-100 text-center";
    if (score >= 0.3 && score <= 0.7) return "bg-yellow-100 text-center";
    return "bg-red-100 text-center";
  };

  // Filter vendorHeaders to include only those that are selected
  const vendorHeaders = Object.keys(selectedVendors).filter(
    (vendor) => selectedVendors[vendor]
  );

  return (
    <>
      {status == "loading" ? (
        <div className="w-full flex items-center justify-center p-6">
          <Spinner />
        </div>
      ) : (
        <div className="mb-12 px-6">
          <div className="my-6">
            <h2 className="text-2xl font-bold mb-4">
              Select Vendors for Side-By-Side
            </h2>
            <div className="grid grid-cols-3 gap-3">
              {Object.keys(selectedVendors).map((vendor) => (
                <div
                  key={vendor}
                  className="text-center flex items-center justify-start text-xs space-x-2 cursor-pointer hover:underline"
                  onClick={() => toggleVendorSelection(vendor)}
                >
                  <input
                    type="checkbox"
                    checked={selectedVendors[vendor]}
                    // onChange={() => toggleVendorSelection(vendor)}
                  />
                  <div>{vendor}</div>
                  <div className="font-bold">
                    {data.overallScores[vendor]?.toFixed(2)}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {data.categories?.map(
            (category, idx) =>
              category.title !== "Company Details" &&
              category.title !== "Contact" &&
              category.questionScores.length > 0 && (
                <div key={`category-${category.title}`}>
                  <div className="p-3 text-2xl font-semibold text-gray-700">
                    {category.title}
                  </div>
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead>
                      <tr>
                        <th scope="col" className="py-3 px-6">
                          Question
                        </th>
                        <th scope="col" className="py-3 px-6">
                          User Response
                        </th>
                        {vendorHeaders.map((vendor) => (
                          <th key={vendor} scope="col" className="py-3 px-6">
                            {vendor}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {category.questionScores.map((questionScore, qIndex) => (
                        <tr
                          key={`${idx}-${qIndex}`}
                          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                        >
                          <td className="py-2 px-3">
                            {questionScore.question}
                          </td>
                          <td className="py-2 px-3">
                            {typeof questionScore.userResponse == "object"
                              ? questionScore.userResponse.join(", ")
                              : questionScore.userResponse}
                          </td>
                          {vendorHeaders.map((vendor) => (
                            <td
                              key={`${vendor}-${qIndex}`}
                              className={`py-2 px-3 ${getScoreColor(
                                questionScore[vendor]
                              )}`}
                            >
                              {parseFloat(questionScore[vendor]).toFixed(2)}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )
          )}
        </div>
      )}
    </>
  );
};

export default GptScores;
