// Button.js
import React from "react";

// When to Use Each Button Style:
// Primary Button:

// Use for the main call-to-action on a page or modal.
// Should only appear once in the majority of cases.
// Examples: "Save", "Submit", "Send".
// Secondary Button:

// Use for actions that are important but not the main call-to-action.
// Useful when you need to provide an alternative to the primary action.
// Examples: "Edit", "View More".
// Tertiary Button:

// Use for less important or less frequently used actions.
// Also useful when you want to provide an action without drawing too much attention away from primary content.
// Examples: "Learn More", "Cancel".
// Remember to maintain a consistent hierarchy of actions. On any given page, you should have a clear visual distinction between levels of importance for the user's actions. The most visually distinct button, often the primary button, should be the action you most want users to take. Secondary and tertiary buttons should be styled to reflect their importance relative to the primary action.

const buttonStyles = {
  primary: `text-white font-medium bg-blue-700 hover:bg-blue-800`,
  secondary: "bg-gray-500 text-white hover:bg-gray-600",
  tertiary: "bg-gray-100 text-gray-500 hover:bg-blue-100 hover:text-blue-500",
  neutral: "bg-transparent hover:bg-gray-100 text-gray-700",
  danger: "bg-error-300 hover:bg-error-600 text-white border border-error-200",
  stripe: "bg-gray-900 hover:bg-gray-600 text-white rounded-full",
  tableAction: "bg-gray-500 hover:bg-gray-700 text-white rounded-md border border-gray-500",
  primaryTableAction: "text-gray-800 bg-gray-100 hover:bg-gray-700 hover:text-white rounded-md border border-gray-300",
  // ... you can add styles for disabled, loading, etc.
};

const buttonSizes = {
  small: "text-xs px-2 py-1",
  medium: "text-sm px-4 py-2",
  large: "text-lg px-6 py-3",
  // You can define other sizes as needed
};

const focusRingStyles = {
  primary: "focus:ring-blue-300 ",
  secondary: "focus:ring-gray-300",
  tertiary: "focus:ring-blue-200",
  danger: "focus:ring-error-200",
  stripe: "focus:ring-gray-200",
  tableAction: "focus:ring-gray-200",
  primaryTableAction: "focus:ring-gray-200",
};

const Button = ({
  children,
  variant = "primary",
  size = "medium",
  className = "",
  ...props
}) => {
  // Combine both style and size
  const style = `${buttonStyles[variant]} ${buttonSizes[size]} ${focusRingStyles[variant]} ${className}`;

  return (
    <button
      className={`rounded transition-all focus:outline-none focus:ring-2 focus:ring-offset-2 ${style}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
