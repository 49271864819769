import React, { useState } from "react";
import { useSelector } from "react-redux";

// NOTE: This Component is what is visible in the Customer Scorecard view. There is another one which is the
// Admin-facing CategoryScores, which is at
// shortlist-advice\shortlist-advice\src\pages\QuestionnaireResults\components\LiveDataScorecard\CategoryScores.js

const CategoryScores = ({ scores }) => {
  const matchReportScores = scores;
  const selectedVendors = scores.selectedVendors;

  const selectedVendorImages = useSelector(
    (state) => state.questionnaire.gptScores.matchReportSelectedImages || []
  );

  const [visibleCategoryIndexes, setVisibleCategoryIndexes] = useState({});

  const toggleVisibility = (index) => {
    setVisibleCategoryIndexes((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <div>
      {matchReportScores.categories &&
        matchReportScores.categories.map(
          (category, index) =>
            category.questionScores &&
            category.questionScores.length > 0 &&
            category.title !== "Company Details" &&
            category.title !== "Contact" && (
              <div
                key={index}
                className="shadow-md p-0 md:p-3 rounded-lg mb-4 bg-grayBlue-50"
              >
                <div
                  className=" flex items-end md:items-center cursor-pointer flex-col md:flex-row w-full"
                  onClick={() => toggleVisibility(index)}
                >
                  <h3 className="text-[1.25rem] font-semibold w-full md:w-1/2 flex items-center p-4 md:pl-4">
                    <svg
                      className="fill-indigo-500 shrink-0 mr-4"
                      width="16"
                      height="16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        y="7"
                        width="16"
                        height="2"
                        rx="1"
                        className={`transform origin-center transition duration-200 ease-out ${
                          visibleCategoryIndexes[index] && "!rotate-180"
                        }`}
                      />
                      <rect
                        y="7"
                        width="16"
                        height="2"
                        rx="1"
                        className={`transform origin-center rotate-90 transition duration-200 ease-out ${
                          visibleCategoryIndexes[index] && "!rotate-180"
                        }`}
                      />
                    </svg>
                    {category.title}
                  </h3>
                  <div className="w-full md:w-1/2 grid grid-cols-3 border-solid border-0 border-t border-r border-gray-100">
                    {(() => {
                      // Determine the highest score within categoryScores for the current category
                      const scores = Object.entries(category.categoryScores)
                        .filter(([vendorName]) =>
                          selectedVendors.includes(vendorName)
                        )
                        .map(([_, scoreValue]) => Number(scoreValue));
                      const highestScore = Math.max(...scores);

                      return Object.entries(category.categoryScores)
                        .filter(([vendorName]) =>
                          selectedVendors.includes(vendorName)
                        )
                        .map(([vendorName, scoreValue], idx) => {
                          const isHighestScore =
                            Number(scoreValue) === highestScore;

                          const highlightStyle = isHighestScore
                            ? "bg-success-500 text-white"
                            : scoreValue == 1
                            ? "bg-success-500 text-white"
                            : scoreValue > 0.9
                            ? "bg-success-300"
                            : scoreValue > 0.5
                            ? "bg-warning-200"
                            : "bg-error-300";

                          return (
                            <div
                              key={idx}
                              className="font-medium text-center border-solid border-0 border-l border-gray-100"
                            >
                              <div className="bg-grayBlue-50 flex justify-center p-4 whitespace-nowrap font-semibold text-[1rem] md:text-[1.125rem]">
                                <div className="max-h-[80px] p-1 rounded-md border shadow-sm aspect-square bg-white flex items-center justify-center">
                                  <img
                                    src={selectedVendorImages[idx]}
                                    className="object-contain h-full w-full"
                                    alt={vendorName}
                                  />
                                </div>
                              </div>
                              <div className={`${highlightStyle} p-4`}>
                                {Math.round(
                                  Number(scoreValue).toFixed(2) * 100
                                )}
                              </div>
                            </div>
                          );
                        });
                    })()}
                  </div>
                </div>
                {category.questionScores.map((question, qIndex) => (
                  <div
                    key={qIndex}
                    className={`grid overflow-hidden transition-all duration-300 ease-in-out ${
                      visibleCategoryIndexes[index]
                        ? "grid-rows-[1fr] opacity-100"
                        : "grid-rows-[0fr] opacity-0"
                    }`}
                  >
                    <div className="flex justify-between overflow-hidden border-solid border-0 border-t border-black/10">
                      <div className="w-1/2">
                        <div
                          className={`px-2 py-2 ${
                            qIndex % 2 === 0 ? "bg-grayBlue-50" : "bg-white"
                          }`}
                        >
                          <p className="md:truncate">
                            <div className="text-xs italic text-gray-400">
                              {question.question}
                            </div>
                          </p>
                          <p>
                            <div className="text-xs md:text-sm">
                              {typeof question.userResponse == "object"
                                ? question.userResponse.join(", ")
                                : question.userResponse}
                            </div>
                          </p>
                        </div>
                      </div>
                      <div className="grid grid-cols-3 w-1/2">
                        {selectedVendors.map((vendor, vendorIndex) => {
                          const score = question[vendor] || 0;
                          const highestScore = Math.max(
                            ...selectedVendors.map((v) => question[v] || 0)
                          );

                          const highlightStyle =
                            score == 1
                              ? "bg-success-500 text-white"
                              : score > 0.9
                              ? "bg-success-300"
                              : score > 0.5
                              ? "bg-warning-200"
                              : "bg-error-300";

                          // Applying highlightStyle directly if it's the highest score, otherwise apply alternating background color
                          const backgroundColor = "";

                          return (
                            <div
                              key={vendorIndex}
                              className="text-center border-solid border-0 border-l border-black/10"
                            >
                              <div
                                className={`${highlightStyle} ${backgroundColor} h-full flex items-center jusify-center w-full text-center`}
                              >
                                <div className="text-center w-full text-sm">
                                  {Math.round(score.toFixed(2) * 100)}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )
        )}
    </div>
  );
};

export default CategoryScores;
