import axios from 'axios'
import {v4 as uuidv4} from 'uuid'

const createOrGetDeviceId = () => {
    let deviceId
    try {
        deviceId = JSON.parse(localStorage.getItem('deviceId'))
        if (deviceId == null) {
            throw new Error()
        }
    } catch {
        const newId = uuidv4()
        localStorage.setItem('deviceId', newId)
        deviceId = newId
    }
    return deviceId
}

const DEVICE_ID = createOrGetDeviceId()

const baseURL = process.env.REACT_APP_API_URL || 'http://192.168.1.27:4001'

const ShorlistAdviceAPI = axios.create({
    baseURL: baseURL,
    // timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        webDeviceId: DEVICE_ID
    }
})

window.http = ShorlistAdviceAPI

ShorlistAdviceAPI.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('auth_token')
        config.headers['Authorization'] = `Bearer ${token}`
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

const url = window.location.href;
const baseUrl = window.location.origin;
const path = url.substring(baseUrl.length);
ShorlistAdviceAPI.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if(error.response.status === 401 && path !== '/') {
            // localStorage.removeItem('auth_token');
            // window.location.href = '/admin-login'
        }
        return Promise.reject(error)
    }
)

export default ShorlistAdviceAPI
