import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddOptionDropdown from "./AddOptionDropdown";
import { updateSelectedRequirement } from "../../../../redux/requirements/requirementsSlice";

export default function Form() {
  const dispatch = useDispatch();
  const selectedRequirement = useSelector(
    (state) => state.requirements.selectedRequirement
  );

  const [name, setName] = useState("");
  const [tags, setTags] = useState([]);
  const [category, setCategory] = useState("");
  const [functionalArea, setFunctionalArea] = useState("");
  const [industry, setIndustry] = useState("");
  const [companySize, setCompanySize] = useState("");

  useEffect(() => {
    if (selectedRequirement) {
      console.log("SELECTED");
      console.log(selectedRequirement);
      setName(selectedRequirement.name || "");
      setTags(selectedRequirement.tags || []);
      setCategory(selectedRequirement.category || "");
      setFunctionalArea(selectedRequirement.functionalArea || "");
      setIndustry(selectedRequirement.industry || "");
      setCompanySize(selectedRequirement.companySize || "");
    }
  }, [selectedRequirement]);

  const handleUpdateField = (field, value) => {
    dispatch(
      updateSelectedRequirement({
        id: selectedRequirement._id,
        property: field,
        value: value,
      })
    );
  };

  return (
    <div className="flex flex-col w-full">
      <div className="w-full p-3">
        <label className="block text-sm font-medium text-grayBlue-700 mb-2">
          Vendor Name
        </label>
        <input
          onChange={(e) => {
            setName(e.target.value);
            handleUpdateField("name", e.target.value);
          }}
          type="text"
          value={name}
          className="border border-gray-300 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600 w-full"
        />
      </div>

      <div className="w-full p-3">
        <label className="block text-sm font-medium text-grayBlue-700 mb-2">
          Vendor Website URL
        </label>
        <input
          onChange={(e) => {
            setName(e.target.value);
            handleUpdateField("name", e.target.value);
          }}
          type="text"
          value={name}
          className="border border-gray-300 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600 w-full"
        />
      </div>
        <label className="block pt-3 text-sm font-medium text-grayBlue-700 pl-3 pb-1">
          Vendor Metadata
        </label>
      <div className="px-3 flex space-x-2">
        <AddOptionDropdown
          options={["Category 1", "Category 2", "Category 3", "Category 4"]}
          placeholderValue="Select Category"
          selectedValue={category}
          onSelectOption={(newCategory) => {
            setCategory(newCategory);
            handleUpdateField("category", newCategory);
          }}
        />
        <AddOptionDropdown
          options={[
            "Subcategory 1",
            "Subcategory 2",
            "Subcategory 3",
            "Subcategory 4",
          ]}
          placeholderValue="Select Subcategory"
          selectedValue={functionalArea}
          onSelectOption={(newFunctionalArea) => {
            setFunctionalArea(newFunctionalArea);
            handleUpdateField("functionalArea", newFunctionalArea);
          }}
        />
        <AddOptionDropdown
          options={["Industry 1", "Industry 2", "Industry 3", "Industry 4"]}
          placeholderValue="Select Industry"
          selectedValue={industry}
          onSelectOption={(newIndustry) => {
            setIndustry(newIndustry);
            handleUpdateField("industry", newIndustry);
          }}
        />
      </div>
    </div>
  );
}
