import { createAsyncThunk } from '@reduxjs/toolkit';
import ShortListAdviceAPI from '../ShortListAdviceAPI.js';

export const saveQuestionsToDb = createAsyncThunk(
  'questions/saveQuestionsToDb',
  async (data, { getState, rejectWithValue }) => {
    try {
      // Access and serialize the specific part of Redux state
      const categories = getState().questions.categories;
      const serializedCategories = JSON.stringify(categories);

      // Include the serialized categories in the data sent to the server
      const payload = { categories: serializedCategories };

      // Post the payload to the server
      const response = await ShortListAdviceAPI.post('api/questionnaire/saveQuestionsToDb', payload);

      // Continue with your existing logic
      localStorage.setItem('user_data', JSON.stringify(response.data));
      localStorage.setItem('questions_token', response.data.token);
      return response.data;
    } catch (error) {
      throw rejectWithValue(error.response.data.message);
    }
  }
);


export const getAdminQuestionnaire = createAsyncThunk(
  'questions/getAdminQuestionnaire',
  async (_, { rejectWithValue }) => {
    try {
      // Make a GET request to the server
      const response = await ShortListAdviceAPI.get('api/questionnaire/admin');

      // Convert 'categories' from a string to a JSON object
      const categories = JSON.parse(response.data.categories);

      // Return the parsed categories
      return categories;
    } catch (error) {
      // Ensure error handling accounts for both server and parsing errors
      const errorMessage = error.response?.data?.message || error.message;
      throw rejectWithValue(errorMessage);
    }
  }
);

