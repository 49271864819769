import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getScorecardByQuestionnaireId } from "../../../redux/scorecards/scorecardsThunk";
import AdminTeamScores from "./AdminTeamScores/AdminTeamScores.js";

export default function ScorecardView({ memberScores }) {

  return <div className="flex-1 p-3">
    <AdminTeamScores />
  </div>;
}
