import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
// import SubcategoryQuestionList from './components/SubcategoryQuestionList';
import RequirementsTable from "./components/RequirementsTable/RequirementsTable";
import { useNavigate } from "react-router-dom";
import RequirementsCreator from "./components/RequirementsCreator/RequirementsCreator";
import { initNewRequirement, toggleEditor } from "../../redux/requirements/requirementsSlice";
import { fetchRequirements } from "../../redux/requirements/requirementsThunk";

// NOTE: This was scrapped, and is unused. Can probably be deleted, once we are released and have confirmed
// that we do not in fact want it!

export default function CustomerRequirementsDB() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const requirementsStatus = useSelector(
    (state) => state.requirements.status
  );

  const showEditor = useSelector(state => state.requirements.showEditor)

  useEffect(() => {
    if (requirementsStatus === "init") {
      dispatch(fetchRequirements())
    }
  }, [])
  

  return showEditor ? (
    <div className="overflow-hidden">
      <RequirementsCreator />
    </div>
  ) : (
    <div className="flex flex-col">
      <PageHeader pageName="Requirements Database" />
      <RequirementsTable />
    </div>
  );
}
