const GoogleIcon = () => (
  <svg
    width="19"
    height="15"
    viewBox="0 0 19 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.51677 8.09434C1.40328 7.91464 1.34654 7.82479 1.31477 7.6862C1.29091 7.5821 1.29091 7.41794 1.31477 7.31384C1.34654 7.17525 1.40328 7.0854 1.51677 6.90571C2.45461 5.42072 5.24617 1.66669 9.50034 1.66669C13.7545 1.66669 16.5461 5.42072 17.4839 6.9057C17.5974 7.0854 17.6541 7.17525 17.6859 7.31384C17.7098 7.41794 17.7098 7.5821 17.6859 7.6862C17.6541 7.82479 17.5974 7.91464 17.4839 8.09434C16.5461 9.57932 13.7545 13.3334 9.50034 13.3334C5.24617 13.3334 2.45461 9.57932 1.51677 8.09434Z"
      stroke="#175CD3"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.50034 10C10.881 10 12.0003 8.88073 12.0003 7.50002C12.0003 6.11931 10.881 5.00002 9.50034 5.00002C8.11962 5.00002 7.00034 6.11931 7.00034 7.50002C7.00034 8.88073 8.11962 10 9.50034 10Z"
      stroke="#175CD3"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default GoogleIcon;
