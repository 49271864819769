import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import ShortlistMatchCard from "../../components/ShortlistMatchCard/ShortlistMatchCard";

export default function ViewMatchReport() {
  const navigate = useNavigate();
  const scores = useSelector((state) => state.customers.loadedMatchReport);

  return (
    <div className="overflow-y-auto bg-white pb-12">
      <div className="p-6 text-2xl font-bold flex items-center space-x-3">
        <div
          onClick={() => navigate("/customers")}
          className="cursor-pointer hover:bg-gray-100 border rounded-full bg-white border-gray-400 p-1"
        >
          <ArrowLeftIcon className="h-4 w-4 text-gray-900" />
        </div>
        <div className="w-full">Customer Match Report</div>
      </div>
      <div className="px-6 xl:px-12">
        
        {scores.overallScores ? <ShortlistMatchCard scores={scores} /> : "No Match Report has been Sent"}
      </div>
    </div>
  );
}
