import React, { useState, useEffect, Fragment } from 'react';
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from '@heroicons/react/outline';
import {v4} from 'uuid';


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AddOptionDropdown({
  options = [],
  onSelectOption = () => {},
  onAddOption= () => {},
  selectedValue = null,
  placeholderValue = "Choose an Option",
}) {

    const [selected, setSelected] = useState(selectedValue || '');
  
    useEffect(() => {
      if (options.includes(selectedValue)) {
        setSelected(selectedValue);
      } else {
        setSelected('');
      }
    }, [selectedValue]);
  
    const handleChange = (newType) => {
      setSelected(newType);
      onSelectOption(newType); // This will notify the parent component of the change
    };

    useEffect(() => {
      console.log('Selected changed')
      console.log(selected)
      // onSelectOption(selected)
    }, [selected])

    const handleCreateNew = () => {
        console.log('creating...')
    }
    

    return (
        <Menu as="div" className="relative inline-block text-left w-full text-xs font-semibold">
          <div>
            <Menu.Button className="inline-flex w-full justify-between items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-xs text-left font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              {selected || placeholderValue}
              <ChevronDownIcon className="h-4 w-4 text-gray-900" aria-hidden="true" />
            </Menu.Button>
          </div>
    
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute mt-1 left-0 z-10 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-300 ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {options.map((option) => (
                  <Menu.Item key={option}>
                    {({ active }) => (
                      <button
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block w-full px-4 py-2 text-left text-xs'
                        )}
                        onClick={() => handleChange(option)}
                      >
                        {option}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
              {/* <Menu.Item key={v4()}>
                    {({ active }) => (
                      <button
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900 border bt-2' : 'border bt-2 text-gray-700',
                          'block w-full px-4 py-2 text-left text-xs'
                        )}
                        onClick={() => handleCreateNew()}
                      >
                        + Create New
                      </button>
                    )}
                  </Menu.Item> */}
            </Menu.Items>
          </Transition>
        </Menu>
      );
}

