import React, { useState } from 'react';
import { SpeakerphoneIcon } from "@heroicons/react/outline";

const Rating = ({ score, handleUserResponse, comment, questionId, questionText, questionCategory }) => {
    const maxRating = 5;
    const [hoveredScore, setHoveredScore] = useState(0);

    const colorClasses = [
      'bg-error-500',    // Score of 1
      'bg-warning-500',  // Score of 2
      'bg-warning-300',  // Score of 3
      'bg-success-400',  // Score of 4
      'bg-success-500',  // Score of 5
    ];

    const handleMouseEnter = (index) => {
        setHoveredScore(index);
    };

    const handleMouseLeave = () => {
        setHoveredScore(0);
    };

    const handleClick = (index) => {
        handleUserResponse(questionId, index, questionText, questionCategory);
    };

    const speakerPhoneStyle = comment?.length > 0 ? 'h-4 w-4 text-gray-500 hover:text-gray-700 cursor-pointer z-10' : 'h-4 w-4 opacity-0 z-10'

    return (
      <div className="flex items-center space-x-1">
        {Array.from({ length: maxRating }).map((_, index) => {
          const isActive = index < score;
          const isHovered = index < hoveredScore;
          const color = isActive ? colorClasses[score - 1] : isHovered ? `${colorClasses[hoveredScore - 1]} opacity-50` : 'bg-gray-200';

          return (
            <div 
              key={index} 
              className={`h-4 w-4 rounded-full cursor-pointer ${color}`} 
              onMouseEnter={() => handleMouseEnter(index + 1)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleClick(index + 1)}
            ></div>
          );
        })}
        <div className="group relative">
          <SpeakerphoneIcon className={speakerPhoneStyle} />
          {comment?.length > 0 && <div className="z-20 absolute hidden group-hover:block -left-36 w-36 top-0 bg-gray-900 text-white text-xs text-center rounded-lg px-2 py-1">
            {comment}
          </div>}
        </div>
      </div>
    );
};

export default Rating;
