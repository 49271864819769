import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/solid";

const Timeline = ({ questionnaireId }) => {
  const customerStep = useSelector(
    (state) => state.customerAuth.customer?.status
  );
  const navigate = useNavigate();
  const iconStyles = "h-6 w-6"; // Icon size

  const scorecards = useSelector((state) => state.scorecards.scorecards);
  const [currentScorecard, setCurrentScorecard] = useState(null);

  useEffect(() => {
    // Find the scorecard where the populated questionnaireId object's _id matches the passed questionnaireId
    let foundScorecard = scorecards.find(
      (scorecard) => scorecard.questionnaireId._id === questionnaireId
    );

    // Silly bug handler because sometimes we have the questionnaire populated, and sometimes not.
    if (!foundScorecard) {
      foundScorecard = scorecards.find(
        (scorecard) => scorecard.questionnaireId === questionnaireId
      );
    }

    if (foundScorecard) {
      setCurrentScorecard(foundScorecard);
    } else {
    }
  }, [scorecards, questionnaireId]); // Add questionnaireId to the dependency array

  // Initial steps setup
  const initialSteps = [
    { name: "Take Questionnaire", state: "disabled", url: null },
    {
      name: "Create Team",
      state: "disabled",
      url: `/create-team?qid=${questionnaireId}`,
    },
    {
      name: "Create Vendor Scorecard",
      state: "disabled",
      url: `/create-demo-scorecard?qid=${questionnaireId}`,
    },
    {
      name: "Evaluate Vendors",
      state: "disabled",
      url: `/score-vendors?qid=${questionnaireId}`,
    },
    {
      name: "Team Review + Select Winner",
      state: "disabled",
      url: `/view-team-results?qid=${questionnaireId}`,
    },
  ];

  // State to manage steps
  const [steps, setSteps] = useState(initialSteps);

  // Function to get color based on state
  const getColor = (state) => {
    switch (state) {
      case "completed":
        return "text-success-500";
      case "current":
        return "text-blue-600 hover:underline cursor-pointer";
      case "disabled":
        return "text-gray-400";
      default:
        return "text-gray-400";
    }
  };

  // Handler for item click
  const handleItemClick = (url) => {
    if (url) {
      navigate(url);
    }
  };

  const [customerStepData, setCustomerStepData] = useState();

  useEffect(() => {
    const statusToIndexMapping = {
      resultsSent: 0,
      teamCreated: 1,
      scorecardCreated: 2,
      evaluationsCollected: 3,
      winnerSelected: 4,
    };

    const currentIndex = currentScorecard?.selectedWinner
      ? 4
      : statusToIndexMapping[customerStep] ?? -1;
    setCustomerStepData(initialSteps[currentIndex + 1]);

    let updatedSteps = initialSteps.map((step, index) => {
      if (index <= currentIndex) {
        return { ...step, state: "completed" };
      } else if (index === currentIndex + 1) {
        return { ...step, state: "current" };
      } else {
        return { ...step, state: "disabled" };
      }
    });

    if (currentIndex === 2) {
      updatedSteps[4].state = "current";
    }

    setSteps(updatedSteps);
  }, [customerStep, questionnaireId, currentScorecard]);

  const handleTimelineBtnClick = () => {
    navigate(customerStepData.url);
  };

  return (
    <div className="flex flex-col justify-evenly py-3 space-y-1">
      {/* {customerStepData && customerStepData?.name !== "Team Review + Select Winner" && <button onClick={ ()=> handleTimelineBtnClick()} className="bg-blue-700 text-white hover:bg-blue-600 py-2 rounded-md px-3 mb-3">Click Here to {customerStepData?.name}</button>} */}
      {steps.map((step, index) => (
        <div
          key={index}
          onClick={() => handleItemClick(step.url)}
          className={`flex items-center space-x-2 ${getColor(step.state)}`}
        >
          <div>
            {step.state === "completed" && (
              <CheckCircleIcon
                className={`${iconStyles} ${getColor(step.state)}`}
              />
            )}
            {step.state === "current" && (
              <ExclamationCircleIcon
                className={`${iconStyles} ${getColor(step.state)}`}
              />
            )}
            {step.state === "disabled" && (
              <XCircleIcon
                className={`${iconStyles} ${getColor(step.state)}`}
              />
            )}
          </div>
          <div>{step.name}</div>
        </div>
      ))}
    </div>
  );
};

export default Timeline;
