import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeVendor } from "../../../../redux/scoreVendors/scoreVendorsSlice";

const TabController = () => {
  const dispatch = useDispatch();
  const vendors = useSelector((state) => state.scoreVendors.vendors);
  const currentVendor = useSelector(
    (state) => state.scoreVendors.currentVendor
  );

  const handleTabClick = (vendor) => {
    dispatch(changeVendor(vendor));
  };

  return (
    <div className="p-6 pb-0">
      <div>
        <div className="text-xs font-semibold pb-1">Select Vendor:</div>
        <div className="flex space-x-2">
          {vendors.map((vendor) => (
            <button
              key={vendor}
              className={`px-4 py-2 rounded ${
                vendor === currentVendor
                  ? "p-1.5 px-3 rounded-md text-sm bg-blue-50 border border-blue-700 shadow-sm cursor-pointer text-blue-700 font-semibold transition-all"
                  : "p-1.5 px-3 rounded-md text-sm bg-white border border-gray-200 shadow-sm cursor-pointer hover:bg-gray-100 transition-all"
              }`}
              onClick={() => handleTabClick(vendor)}
            >
              {vendor}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TabController;
