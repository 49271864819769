import React from "react";
import { useSelector, useDispatch } from "react-redux";
import SubcategoryQuestionForm from "./SubcategoryQuestionForm/SubcategoryQuestionForm";
import {
  addQuestion,
  updateCurrentQuestionsOrder,
} from "../../../redux/questions/questionsSlice";
import { v4 as uuidv4 } from "uuid";

const SubcategoryQuestionList = () => {
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.questions.currentQuestions);

  const handleAddQuestion = () => {
    const newQuestion = { id: uuidv4(), text: "", type: "", answers: [] };
    dispatch(addQuestion(newQuestion));
  };

  const moveQuestion = (fromIndex, toIndex) => {
    const newQuestions = [...questions];
    const [movedQuestion] = newQuestions.splice(fromIndex, 1);
    newQuestions.splice(toIndex, 0, movedQuestion);
    dispatch(updateCurrentQuestionsOrder(newQuestions));
  };

  return (
    <div>
      <div className="space-y-2 p-2">
        {questions.length == 0 ? (
          <div className='text-gray-900 text-sm'>Add a question to get started.</div>
        ) : (
          questions.map((question, index) => (
            <div key={question.id} className="flex items-start center p-2 ">
              <div className="flex flex-col justify-center ml-2">
                <button
                  className={
                    index === 0
                      ? "mb-1 px-1 rounded-md text-gray-300"
                      : "mb-1 px-1 rounded-md hover:bg-gray-200"
                  }
                  disabled={index === 0}
                  onClick={() => moveQuestion(index, index - 1)}
                >
                  ↑
                </button>
                <button
                  className={
                    index === questions.length - 1
                      ? "mb-1 px-1 rounded-md text-gray-300"
                      : "mb-1 px-1 rounded-md hover:bg-gray-200"
                  }
                  disabled={index === questions.length - 1}
                  onClick={() => moveQuestion(index, index + 1)}
                >
                  ↓
                </button>
              </div>
              <SubcategoryQuestionForm question={question} index={index} />
            </div>
          ))
        )}
      </div>

      <div
        className="shadow-sm cursor-pointer flex items-center justify-center w-24 px-4 py-1.5 text-sm border border-gray-300 bg-white font-bold text-gray-700 hover:bg-gray-50 rounded-lg mt-2"
        onClick={handleAddQuestion}
      >
        Add New
      </div>
    </div>
  );
};

export default SubcategoryQuestionList;
