import React, { useEffect } from 'react'
import PageHeader from '../../components/PageHeader/PageHeader'
import QuestionsPreview from './components/QuestionsPreview'
import SearchSample from './components/SearchSample/SearchSample'
import { getAdminQuestionnaire } from '../../redux/questions/questionsThunk'
import { useDispatch } from 'react-redux'

export default function Home() {
 
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdminQuestionnaire());
  }, [])
  

  return (
    <div>
        <PageHeader pageName="Home"/>
        {/* <QuestionsPreview /> */}
        <SearchSample />
    </div>
  )
}
