import React from "react";

const VendorScorecard = ({ vendors }) => {
  // Function to calculate the total score
  const calculateTotalScore = (vendor) => {
    const fields = ["RevenueRanges", "MinimumAnnualCost", "FrontEndPlatform"];
    let totalAdjustedScore = 0;
    // console.log(`Calculating score for vendor: ${vendor.VendorName}`);

    fields.forEach((field) => {
      if (vendor[field]) {
        const importance = parseInt(vendor[field].importance) || 1; // Assuming equal importance for all fields, change as needed
        const adjustedScore = (vendor[field].score * importance) / 5;
        totalAdjustedScore += adjustedScore;
        // console.log(`Field: ${field}, Score: ${vendor[field].score}, Importance: ${importance}, Adjusted Score: ${adjustedScore}`);
      }
    });

    const averageAdjustedScore = totalAdjustedScore / fields.length;
    // console.log(`Total Adjusted Score: ${totalAdjustedScore}, Average Adjusted Score: ${averageAdjustedScore}`);

    // Normalize the average score to a scale of 0-100
    const normalizedScore = averageAdjustedScore * 100;
    const roundedScore = Math.round(normalizedScore);
    // console.log(`Normalized Score: ${normalizedScore}, Rounded Score: ${roundedScore}`);

    return roundedScore;
  };

  const sortedVendors = vendors
    .map((vendor) => ({
      ...vendor,
      totalScore: calculateTotalScore(vendor), // Calculate and store the total score for each vendor
    }))
    .sort((a, b) => b.totalScore - a.totalScore); // Sort vendors based on total score

  return (
    <div>
      <div className="flex flex-col gap-4 p-4">
        {sortedVendors.map((vendor, index) => (
          <div
            key={index}
            className="border border-slate-300 rounded-lg p-4 w-full flex flex-col"
          >
            {" "}
            {/* Adjusted width */}
            <h3 className="text-lg font-semibold text-slate-700 mb-4">
              {vendor.VendorName}
            </h3>
            <div className='flex items-start justify-start'>
              <div className="pr-6 pl-2">
                <div className="flex flex-col items-start justify-center">
                  <div>Total Score:</div>
                  <div className="text-2xl">{calculateTotalScore(vendor)}</div>
                </div>
              </div>
              <div className="text-slate-900 ">
                {/* Displaying RevenueRanges details */}
                <div className="mb-1">
                  <strong>Revenue Ranges Score:</strong>{" "}
                  {vendor.RevenueRanges.score.toFixed(2)}
                  <div className="text-sm">
                    <span className="font-semibold">Answer:</span>{" "}
                    {vendor.RevenueRanges.answer}
                    <br />
                    <span className="font-semibold">Value:</span>{" "}
                    {vendor.RevenueRanges.propertyValue}
                  </div>
                </div>
                {/* Displaying MinimumAnnualCost details */}
                <div className="my-4">
                  <strong>Minimum Annual Cost Score:</strong>{" "}
                  {vendor.MinimumAnnualCost.score.toFixed(2)}
                  <div className="text-sm">
                    <span className="font-semibold">Answer:</span>{" "}
                    {vendor.MinimumAnnualCost.answer}
                    <br />
                    <span className="font-semibold">Value:</span>{" "}
                    {vendor.MinimumAnnualCost.propertyValue}
                  </div>
                </div>
                {/* Displaying FrontEndPlatform details */}
                <div>
                  <strong>Front End Platform Score:</strong>{" "}
                  {vendor.FrontEndPlatform.score.toFixed(2)}
                  <div className="text-sm">
                    <span className="font-semibold">Answer:</span>{" "}
                    {vendor.FrontEndPlatform.answer}
                    <br />
                    <span className="font-semibold">Value:</span>{" "}
                    {vendor.FrontEndPlatform.propertyValue}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VendorScorecard;
