import React from "react";
import RatingInputCell from "./RatingInputCell";

export default function RatingInput({ questionId, currentValue }) {
  const options = [1, 2, 3, 4, 5];

  return (
    <div className="space-y-1 my-3">
      <div className="font-medium text-xs">Question Importance Factor:</div>
      <div className="flex gap-2 justify-start">
        {options.map((number) => (
          <RatingInputCell
            questionId={questionId}
            key={number}
            text={number}
            isSelected={number === currentValue} // Pass a prop to indicate selection
          />
        ))}
      </div>
      <div className="flex justify-between font-medium text-xs">
        <div>Not Important</div>
        <div className="text-right">Very Important</div>
      </div>
    </div>
  );
}

