import { MailIcon, TrashIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";

export default function InviteTeamMemberRow({
  firstName,
  lastName,
  email,
  index,
  updateFunc,
}) {
  // Initialize local state with props
  const [memberData, setMemberData] = useState({ firstName, lastName, email });
  const [showSendEmailTooltip, setShowSendEmailTooltip] = useState(false);
  const [showRemoveMemberTooltip, setShowRemoveMemberTooltip] = useState(false);

  // Update local state when props change
  useEffect(() => {
    setMemberData({ firstName, lastName, email });
  }, [firstName, lastName, email]);

  const handleInputChange = (e, field) => {
    const newValue = e.target.value;
    // Update memberData state with new field value
    const updatedData = { ...memberData, [field]: newValue };
    setMemberData(updatedData);
    // Call parent update function
    updateFunc(index, updatedData);
  };

  const handleSendEmail = () => {
    const isConfirmed = window.confirm(
      "Did your user not get their invitation? If so, please use this to trigger a manual re-send."
    );
    if (isConfirmed) {
      console.log("Sending email invite...");
    }
  };

  const handleRemoveMember = () => {
    updateFunc(index, null);
  };

  return (
    <div className="flex space-x-0 sm:space-x-2 flex-col sm:flex-row sm:space-y-0 space-y-2">
      <div className="flex-1">
        
        <input
          onChange={(e) => handleInputChange(e, "firstName")}
          type="text"
          value={memberData.firstName}
          placeholder="First Name"
          className="border border-gray-300 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600 w-full"
        />
      </div>
      <div className="flex-1">
        
        <input
          onChange={(e) => handleInputChange(e, "lastName")}
          type="text"
          value={memberData.lastName}
          placeholder="Last Name"
          className="border border-gray-300 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600 w-full"
        />
      </div>
      <div className="flex-1">
        
        <input
          onChange={(e) => handleInputChange(e, "email")}
          type="text"
          value={memberData.email}
          placeholder="Email Address"
          className="border border-gray-300 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600 w-full"
        />
      </div>
      <div className="">
        
        <div className="flex space-x-2">
          <div className="relative flex flex-col items-center w-full">
            <button
              className="bg-gray-200 hover:bg-gray-300 p-2 rounded-md w-full flex items-center justify-center"
              onClick={handleRemoveMember}
              onMouseEnter={() => setShowRemoveMemberTooltip(true)}
              onMouseLeave={() => setShowRemoveMemberTooltip(false)}
            >
              <TrashIcon className="h-5 w-5" />
            </button>
            {showRemoveMemberTooltip && (
              <div className="w-16 flex items-center justify-center text-center absolute -top-14 mb-8 z-10 p-2 text-xs text-white bg-black rounded-md">
                Remove Member
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="border-t border-gray-300 my-4"></div>
    </div>
  );
}
