import { createAsyncThunk } from '@reduxjs/toolkit'
import CustomerAPI from '../CustomerAPI.js'


export const getCustomerForLogin = createAsyncThunk('customerAuth/getCustomerForLogin', async (data, { rejectWithValue }) => {
    try {
      const response = await CustomerAPI.post('api/customers/getCustomerForLogin', data)
      return response.data
    } catch (error) {
      throw rejectWithValue(error.response.data.message)
    }
  })

export const customerLogin = createAsyncThunk('customerAuth/customerLogin', async (data, { rejectWithValue }) => {
  try {
    const response = await CustomerAPI.post('api/auth/login', data)
    localStorage.setItem('customer_user_data', JSON.stringify(response.data))
    localStorage.setItem('customer_auth_token', response.data.token)
    return response.data
  } catch (error) {
    throw rejectWithValue(error.response.data.message)
  }
})

export const customerRegistration = createAsyncThunk('customerAuth/customerRegistration', async (data, { rejectWithValue }) => {
  try {
    const response = await CustomerAPI.post('api/auth/', data)
    localStorage.setItem('customer_user_data', JSON.stringify(response.data))
    localStorage.setItem('customer_auth_token', response.data.token)
    return response.data
  } catch (error) {
    throw rejectWithValue(error.response.data.message)
  }
})


export const joinTeam = createAsyncThunk('customerAuth/joinTeam', async (data, { rejectWithValue }) => {
  try {
    const response = await CustomerAPI.post('api/auth/joinTeam', data)
    localStorage.setItem('customer_user_data', JSON.stringify(response.data))
    localStorage.setItem('customer_auth_token', response.data.token)
    return response.data
  } catch (error) {
    throw rejectWithValue(error.response.data.message)
  }
})


export const customerLogout = createAsyncThunk('customerAuth/customerLogout', async () => {
  localStorage.removeItem('customer_user_data');
  localStorage.removeItem('customer_auth_token');

  // Trigger a full page reload to reset the entire Redux state
  window.location.reload();

  const response = {
    data: null
  };
  return response.data;
});

export const resetPasswordReq = createAsyncThunk('customerAuth/resetPasswordReq', async ({email}, { rejectWithValue }) => {
  try {
    const lowercaseEmail = email.toLowerCase();
    const response = await CustomerAPI.post('/resetPasswordRequest', {
      email: lowercaseEmail,
    })
    return response.data
  } catch (error) {
    throw rejectWithValue(error.response.data.message)
  }
})

export const resetPassword = createAsyncThunk('customerAuth/resetPassword', async (data, { rejectWithValue }) => {
  try {
    const response = await CustomerAPI.put('/resetPassword', data)
    return response.data
  } catch (error) {
    throw rejectWithValue(error.response.data.message)
  }
})

export const getMyCustomerDetails = createAsyncThunk('customerAuth/getMyCustomerDetails', async (data, { rejectWithValue }) => {
  try {
    const response = await CustomerAPI.get('/api/customers/getMyCustomerDetails')
    return response.data
  } catch (error) {
    throw rejectWithValue(error.response.data.message)
  }
})

