import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import EmptyTable from "./EmptyTable";
import { TrashIcon, PencilIcon } from "@heroicons/react/outline";
import {
  setSelectedRequirement,
  toggleEditor,
} from "../../../../redux/vendors/vendorsSlice";
import { deleteRequirement } from "../../../../redux/requirements/requirementsThunk";
import Pagination from "./Pagination";

const VendorTable = () => {
  const vendors = useSelector((state) => state.vendors.vendors);
  const dispatch = useDispatch();

  const handleAddCategoryClick = () => {
    // dispatch(setSelectedCategory(null)); // Reset selected requirement when adding new
    // dispatch(toggleSlideout());
  };

  const handleEditClick = (requirement) => {
    // dispatch(setSelectedRequirement(requirement));
    // dispatch(toggleEditor());
  };

  const handleDeleteClick = (requirementId, requirementTitle) => {
    const userInput = window.prompt(
      `Please type '${requirementTitle}' to confirm deletion:`
    );

    if (userInput === requirementTitle) {
      dispatch(deleteRequirement(requirementId));
    } else {
      // Do nothing
    }
  };

  return (
    <div className="p-4">
      <div className="flex w-full min-h-full items-center justify-start flex-col text-center font-sans text-sm shadow-sm text-left">
        <div className="overflow-x-auto min-h-full min-h-full min-w-full ">
          {vendors.length === 0 ? (
            <EmptyTable handleAddCategoryClick={handleAddCategoryClick} />
          ) : (
            <table className="min-w-full bg-white">
              <thead className="bg-gray-200 w-full border-separate text-gray-500">
                <tr>
                  <th className="text-left py-3 px-4 text-start rounded-tl-lg border-grayBlue-200 font-semibold">
                    Name
                  </th>
                  <th className="text-left py-3 px-4 text-start border-l border-grayBlue-200 font-semibold">
                    Subcategories
                  </th>
                  <th className="text-left py-3 px-4 text-start border-l border-grayBlue-200 font-semibold">
                    URL
                  </th>
                  <th className="text-left py-3 px-4 text-start rounded-tr-lg border-l border-grayBlue-200 font-semibold">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {vendors.map((vendor, index) => (
                  <tr
                    key={index}
                    className={
                      vendor.title !== "Company Details" &&
                      vendor.title !== "Contact"
                        ? "border-b"
                        : "border-b bg-gray-50 text-gray-400 italic text-sm"
                    }
                  >
                    <td className="py-3 px-4 text-left">
                      <div className="w-[120px] h-[120px] flex items-center justify-center">
                        {vendor.Logo ? (
                          <img
                            src={vendor.Logo}
                            alt={vendor.VendorName}
                            className="max-w-full max-h-full object-contain"
                          />
                        ) : (
                          <span className="text-center">
                            {vendor.VendorName}
                          </span>
                        )}
                      </div>
                    </td>

                    <td className="py-3 px-4 text-left">
                      {vendor.Subcategories}
                    </td>
                    <td className="py-3 px-4 text-left">
                      <a
                        className="hover:underline"
                        target="_blank"
                        href={vendor.URL}
                      >
                        {vendor.URL}
                      </a>
                    </td>
                    <td className="py-3 px-4 text-left flex gap-1">
                      <button
                        className="bg-gray-200 hover:bg-gray-300 py-1 px-2 rounded-md"
                        onClick={() => handleEditClick(vendor)}
                      >
                        <PencilIcon className="h-5 w-5" />
                      </button>
                      {vendor.title !== "Company Details" &&
                        vendor.title !== "Contact" && (
                          <button
                            className="bg-gray-200 hover:bg-gray-300 py-1 px-2 rounded-md"
                            onClick={() =>
                              handleDeleteClick(vendor._id, vendor.name)
                            }
                          >
                            <TrashIcon className="h-5 w-5" />
                          </button>
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <Pagination />
    </div>
  );
};

export default VendorTable;
