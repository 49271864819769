import React from 'react';
import { XIcon } from '@heroicons/react/outline';

export default function TagsInput({ tags, setTags }) {
  const handleInputChange = (event) => {
    if (event.key === 'Enter' && event.target.value) {
      if (!tags.includes(event.target.value)) {
        setTags([...tags, event.target.value]);
        event.target.value = '';
      }
    }
  };

  const handleDeleteTag = (tagToDelete) => {
    setTags(tags.filter(tag => tag !== tagToDelete));
  };

  return (
    <div className="flex flex-wrap gap-2 p-2 border border-gray-300 rounded bg-white text-sm">
      {tags.map((tag, index) => (
        <span key={index} className="flex items-center bg-gray-200 rounded-full px-2 text-sm font-semibold text-gray-700 p-1">
          {tag}
          <button type="button" onClick={() => handleDeleteTag(tag)} className="ml-1 flex items-center justify-center h-4 w-4 hover:bg-gray-400 rounded-full">
            <XIcon className='h-3 w-3'/>
          </button>
        </span>
      ))}
      <input className='w-full p-2 outline-blue-600' type="text" onKeyPress={handleInputChange} placeholder="Type to add a tag..." />
    </div>
  );
}
