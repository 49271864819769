import React, { useState } from "react";
import SendToTeam from "./components/SendToTeam/SendToTeam";
import GptScores from "./components/GptScores";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { useDispatch } from 'react-redux';
import { getGptScoresForQuestionnaire } from "../../redux/questionnaire/questionnaireThunk";

const tabs = ["GPT-4 Rankings", "Finalize + Send"];

export default function QuestionnaireResults() {
  const [selectedTab, setSelectedTab] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch()

  const handleReScore = () => {
    const queryParams = new URLSearchParams(location.search);
    const questionnaireId = queryParams.get('qid');
    const confirmOverwrite = window.confirm("Are you sure? This will over-write any existing scores for this customer.");
    if (confirmOverwrite) {
      // Dispatch your Redux action here
      // dispatch(/* yourAction() */);
      dispatch(getGptScoresForQuestionnaire({ questionnaireId, force: true }));
      console.log("Handled");
    }
  };
  return (
    <div className="overflow-y-auto">
      <div className="p-6 text-2xl font-bold flex items-center space-x-3">
        <div
          onClick={() => navigate("/customers")}
          className="cursor-pointer hover:bg-gray-100 border rounded-full bg-white border-gray-400 p-1"
        >
          <ArrowLeftIcon className="h-4 w-4 text-gray-900" />
        </div>
        <div className="w-full">Questionnaire Results</div>
        <div className="w-36">
          <button
            onClick={() => handleReScore()}
            className="bg-blue-700 text-white text-sm px-3 py-1 rounded-md hover:bg-blue-800"
          >
            Re-Score
          </button>
        </div>
      </div>
      <div className="pl-6 flex items-center justify-start space-x-2">
        {tabs.map((tab, idx) => {
          return (
            <div
              onClick={() => setSelectedTab(idx)}
              className={
                idx == selectedTab
                  ? "p-1.5 px-3 rounded-md text-sm bg-blue-50 text-blue-700 font-semibold shadow-sm border border-blue-700 cursor-pointer"
                  : "p-1.5 px-3 rounded-md text-sm bg-white border border-gray-200 shadow-sm cursor-pointer"
              }
            >
              {tab}
            </div>
          );
        })}
      </div>
      {selectedTab == 0 && <GptScores />}
      {selectedTab == 1 && <SendToTeam />}
    </div>
  );
}
