import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import SingleLineTextInput from "../SingleLineTextInput";
import Dropdown from "../Dropdown.js";
import { v4 as uuidv4 } from "uuid";
import { addCustomRequirementToScorecard } from "../../redux/scoreVendors/scoreVendorsSlice.js";
import { useDispatch } from "react-redux";
import { overrideMatchReportScore } from "../../redux/questionnaire/questionnaireSlice.js";

const functionalAreaOptions = [
  "Administration",
  "Analysis",
  "Analytics",
  "Collaboration",
  "Conslidation",
  "Cost",
  "Data Management",
  "General",
  "Implementation",
  "Integration",
  "Planning",
  "Reporting",
];

const AdminScoreOverrideModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  confirmText,
  cancelText,
  data,
}) => {
  const [scoreValue, setScoreValue] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const handleSubmitNewScore = () => {
    // Handle Validation
    setError("");
    const parsedScore = parseInt(scoreValue);

    if (scoreValue === "" || isNaN(parsedScore)) {
      setError("Please enter a valid score.");
      return;
    }

    const updateObj = {
      targetCategory: data.category,
      targetVendor: data.vendor,
      targetRequirement: data.requirement,
      newScore: parsedScore / 100,
    };

    dispatch(overrideMatchReportScore(updateObj));
    setScoreValue("");
    onConfirm();
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500/80 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                {title && (
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                )}
                <div className="py-4 space-y-3">
                  <div className="text-xs">
                    <div>
                      <span className="font-bold">Category:</span>{" "}
                      {data?.category}
                    </div>
                    <div>
                      <span className="font-bold">Requirement:</span>{" "}
                      {data?.requirement}
                    </div>
                    <div>
                      <span className="font-bold">Vendor:</span> {data?.vendor}
                    </div>
                    <div>
                      <span className="font-bold">Current Score:</span>{" "}
                      {data.score
                        ? parseFloat(data?.score).toFixed(2) * 100
                        : -1}
                    </div>
                  </div>
                  <SingleLineTextInput
                    label="New Score Value (0-100)"
                    inputVal={scoreValue}
                    inputUpdateFunc={setScoreValue}
                  />
                </div>
              </div>
              <div className="w-full text-center text-sm text-error-500">
                {error}
              </div>
              <div className="mt-5 sm:mt-6 flex items-end justify-end ">
                <button
                  type="button"
                  className="min-w-32 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                  onClick={handleSubmitNewScore}
                >
                  {confirmText || "Confirm"}
                </button>
                <button
                  type="button"
                  variant="neutral"
                  className="mt-3 min-w-32 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={onClose}
                >
                  {cancelText || "Cancel"}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AdminScoreOverrideModal;
