import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
// import { setUserData } from '../../redux/auth/authSlice'
import CustomerPortalSidebar from './CustomerPortalSidebar'
import { setUserData } from '../../redux/auth/authSlice'

export const CustomerPortalWrapper = ({ children }) => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.customerAuth)
  const userData = JSON.parse(localStorage.getItem('customer_user_data'))

  useEffect(() => {
    if (userData && !user) {
      dispatch(setUserData(userData))
    }
  }, [dispatch, user, userData])

  const isAuthenticated = user != null

  const [availableHeight, setAvailableHeight] = useState(window.innerHeight - 48)

  // Update the available height on window resize
  useEffect(() => {
    const handleResize = () => {
      setAvailableHeight(window.innerHeight - 48)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  
  
  // Always scroll to top whenever the page changes.
  const scrollableDivRef = useRef(null)
  const { pathname } = useLocation();
  useEffect(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTo({ top: 0, behavior: "auto" });
    }
  }, [pathname]);



  return isAuthenticated ? (
    <div className="flex bg-white dark:bg-grayBlue-800 inset-0 dark:text-white">
      <CustomerPortalSidebar />
      <div ref={scrollableDivRef} className='flex w-full pt-12 bg-gradient-to-r from-white/40 to-grayBlue-100/20 bg-grayBlue-50 fixed overflow-y-auto'>
        <div style={{ height: availableHeight }} className='py-4 w-full '>
          {children}
        </div>
      </div>
    </div>
  ) : <Navigate to='/customer-login' replace />
}
