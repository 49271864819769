import React, { useState } from 'react';
import 'tailwindcss/tailwind.css';
import { CheckIcon } from '@heroicons/react/outline';

const Checkbox = ({ value, cbFunc }) => {

  return (
    <label className="inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        className="hidden"
        checked={value}
        onChange={() => cbFunc()}
      />
      <div className={`w-4 h-4 rounded-md flex justify-center items-center border  ${value ? 'bg-[#EFF8FF] border-blue-700' : 'border-gray-400'}`}>
        {value && <CheckIcon className="w-4 h-4 text-blue-700" />}
      </div>
    </label>
  );
};

export default Checkbox;
