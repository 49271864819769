import React from "react";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";

const Footer = ({ handleNext, handleBack, handleSubmit }) => {
  const currentCategoryIndex = useSelector(state => state.questionnaire.currentCategoryIndex);
  const currentSubcategoryIndex = useSelector(state => state.questionnaire.currentSubcategoryIndex);
  const categories = useSelector(state => state.questionnaire.categories);

  const isLastStep = () => {
    if (!categories || categories.length === 0) return false;
    
    const lastCategoryIndex = categories.length - 1;
    const lastSubcategoryIndex = categories[lastCategoryIndex].subcategories.length - 1;

    return currentCategoryIndex === lastCategoryIndex && currentSubcategoryIndex === lastSubcategoryIndex;
  };

  return (
    <div className="py-2 px-8 sm:px-16 fixed left-0 right-0 bottom-0 h-16 z-10 flex justify-center items-center bg-[#F8F9FC] border-grayBlue-200 border border-1">
      <div className="max-w-lg flex justify-between w-full">
        <button
          onClick={currentCategoryIndex === 0 && currentSubcategoryIndex === 0 ? null : handleBack}
          className={`transition-all duration-300 px-4 py-2 text-sm border border-gray-300 bg-white font-semibold text-gray-700 hover:bg-gray-100 rounded-md ${currentCategoryIndex === 0 && currentSubcategoryIndex === 0 ? "opacity-0 cursor-default" : ""}`}
        >
          <div className="flex space-x-1 items-center justify-center">
            <ChevronLeftIcon className="h-4 w-4" /> <span>Back</span>
          </div>
        </button>
        <button
          onClick={isLastStep() ? handleSubmit : handleNext}
          className="px-4 py-2 text-sm border border-blue-600 bg-blue-600 font-semibold text-white hover:bg-blue-700 rounded-md"
        >
          {isLastStep() ? "Submit" : "Next"}
        </button>
      </div>
    </div>
  );
};

export default Footer;
