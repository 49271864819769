import React, { useState } from "react";
import { data as teamData1 } from './teamData1.js';
import { data as teamData2 } from './teamData2.js';
import { data as teamData3 } from './teamData3.js';

const tabs = ["Netsuite Scores", "Pigment Scores", "Jedox Scores"];

const getScoreColor = (score) => {
  if (score > 4) return 'bg-success-500 text-white';
  if (score >= 3 && score <= 4) return 'bg-warning-200 text-gray-800';
  return 'bg-error-300';
};

export default function TeamScorecard() {
  const [selectedTab, setSelectedTab] = useState(0);

  const teamDatas = [teamData1, teamData2, teamData3];
  const currentData = teamDatas[selectedTab];

  return (
    <div className='px-12'>
      <div className="max-w-full mx-auto text-lg">
        <div className="overflow-y-auto">
          <div className="py-6 text-2xl font-bold">Team Breakdown</div>
          <div className="pb-6 flex items-center justify-start space-x-2">
            {tabs.map((tab, idx) => {
              return (
                <div
                  key={idx}
                  onClick={() => setSelectedTab(idx)}
                  className={
                    idx === selectedTab
                      ? "p-1.5 px-3 rounded-md text-sm bg-blue-50 text-blue-700 font-semibold shadow-sm border border-blue-700 cursor-pointer"
                      : "p-1.5 px-3 rounded-md text-sm bg-white border border-gray-200 shadow-sm cursor-pointer"
                  }
                >
                  {tab}
                </div>
              );
            })}
          </div>
        </div>
        {currentData.map((category, idx) => (
          <div key={idx} className="mb-8">
            <h3 className={`font-bold mb-4 ${category.category === "Overall" ? 'font-bold text-2xl' : 'text-xl'}`}>{category.category}</h3>
            <div className="shadow-lg rounded-lg overflow-hidden">
              {category.items.map((item, index) => {
                const highestScore = Math.max(...Object.values(item).slice(1, -1)); // Exclude the first and last elements (requirement and average)
                const scoreColor = getScoreColor(highestScore);

                return (
                  <div key={index} className={`grid grid-cols-10 text-sm ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                    {Object.entries(item).map(([key, value], valueIndex) => {
                      return (
                        <div key={valueIndex} className={`p-3  flex justify-start border bl-gray-100 items-center ${key === "requirement" ? 'col-span-5' : 'col-span-1'}`}>
                          {value}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
