import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateScore } from '../../../redux/scoreVendors/scoreVendorsSlice';

const CommentPopup = ({ questionId, initialValue, onClose, questionCategory, questionText }) => {
  const [comment, setComment] = useState(initialValue);
  const dispatch = useDispatch();

  const handleSave = () => {
    dispatch(updateScore({ questionId, value: comment, questionCategory, questionText }));
    onClose(); // Close the popup after saving
  };

  return (
    <div className="fixed inset-0 bg-gray-600/50  overflow-y-auto h-full w-full flex justify-center items-center">
      <div className="bg-white rounded-lg p-4 max-w-lg w-full">
        <textarea
        placeholder="Add comments for the team..."
          className="border border-gray-300 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600 w-full"
          rows="4"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
       
        <div className="flex justify-end space-x-2 mt-2 text-sm">
          <button className="px-4 py-2 rounded bg-gray-200 hover:bg-gray-300" onClick={onClose}>Cancel</button>
          <button className="px-4 py-2 rounded bg-blue-600 text-white hover:bg-blue-700" onClick={handleSave}>Add Comment</button>
        </div>
      </div>
    </div>
  );
};

export default CommentPopup;
