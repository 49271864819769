import { createSlice } from "@reduxjs/toolkit";
import {
  saveTeam,
  getScorecardByQuestionnaireId,
  saveScorecardDataByQuestionnaireId,
  reSendTeamMemberInvite,
  removeMemberFromTeam,
} from "./scorecardsThunk";
import toast from "react-hot-toast";

const initialState = {
  scorecards: [],
  status: "init",
  selectedScorecard: null,
  matchDataByQuestionnaire: [],
  scorecardTableScaffold: [],
};

export const scorecardsSlice = createSlice({
  name: "scorecards",
  initialState,
  reducers: {
    changeVendor: (state, action) => {
      state.currentVendor = action.payload;
    },
    setMatchDataForQuestionnaire: (state, action) => {
      state.matchDataByQuestionnaire = [
        ...state.matchDataByQuestionnaire,
        action.payload,
      ];
    },
    setSelectedScorecard: (state, action) => {
      state.selectedScorecard = action.payload;
    },
    setScorecards: (state, action) => {
      state.scorecards = action.payload;
    },
    setScorecardTableScaffold: (state, action) => {
      const selectedScorecardCopy = JSON.parse(
        JSON.stringify(state.selectedScorecard)
      );
      const scoreCardData = JSON.parse(selectedScorecardCopy.scoreCardData);
      state.scorecardTableScaffold = scoreCardData;
    },
  },
  extraReducers: {
    // ----------
    // saveTeam
    // ----------
    [saveTeam.pending]: (state) => {
      state.status = "loading";
    },
    [saveTeam.fulfilled]: (state, action) => {
      state.status = "succeeded";
      // Replace old scorecard with new data
      state.scorecards = state.scorecards.map((scorecard) =>
        scorecard._id === action.payload._id ? action.payload : scorecard
      );
      state.selectedScorecard = null;
    },
    [saveTeam.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    },
    // ----------
    // getScorecardByQuestionnaireId
    // ----------
    [getScorecardByQuestionnaireId.pending]: (state) => {
      state.status = "loading";
    },
    [getScorecardByQuestionnaireId.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.selectedScorecard = action.payload;
    },
    [getScorecardByQuestionnaireId.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;

      if (action.payload.message == "Scorecard Not Found") {
        console.error("ERROR: Scorecard Not Found");
      } else {
        toast.error(action.payload.message);
      }
    },
    // ----------
    // saveScorecardDataByQuestionnaireId
    // ----------
    [saveScorecardDataByQuestionnaireId.pending]: (state) => {
      state.status = "loading";
    },
    [saveScorecardDataByQuestionnaireId.fulfilled]: (state, action) => {
      state.status = "succeeded";
      // Replace old scorecard with new data
      state.scorecards = state.scorecards.map((scorecard) =>
        scorecard._id === action.payload._id ? action.payload : scorecard
      );
      state.selectedScorecard = null;
      toast.success("Scorecard Saved!");
    },
    [saveScorecardDataByQuestionnaireId.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    },
    // ----------
    // reSendTeamMemberInvite
    // ----------
    [reSendTeamMemberInvite.pending]: (state) => {
      state.status = "loading";
    },
    [reSendTeamMemberInvite.fulfilled]: (state, action) => {
      state.status = "succeeded";
      toast.success("Invite Sent!");
    },
    [reSendTeamMemberInvite.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error("Failed to send email.");
    },
    // ----------
    // removeMemberFromTeam
    // ----------
    [removeMemberFromTeam.pending]: (state) => {
      state.status = "loading";
    },
    [removeMemberFromTeam.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.selectedScorecard = action.payload.scorecard
      toast.success("Member Removed");
    },
    [removeMemberFromTeam.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error("Failed to delete Member.");
    },
  
  },
});

// Action creators are generated for each case reducer function
export const {
  changeVendor,
  updateScore,
  addRequirementToScorecard,
  removeRequirementFromScorecard,
  setSelectedScorecard,
  setScorecards,
  setMatchDataForQuestionnaire,
  setScorecardTableScaffold,
} = scorecardsSlice.actions;

export default scorecardsSlice.reducer;
