import { createAsyncThunk } from '@reduxjs/toolkit'
import ShortListAdviceAPI from '../ShortListAdviceAPI.js'

export const queryVendorProperties = createAsyncThunk('vendors/queryVendorProperties', async (data, { rejectWithValue }) => {
  try {
    console.log(data)
    const body = {
      "Questionnaire": data
    }
    console.log('BODY')
    console.log(body)
    const response = await ShortListAdviceAPI.post('api/embeddings/query-embedding-properties', body)
    return response.data
  } catch (error) {
    throw rejectWithValue(error.response.data.message)
  }
})


export const fetchVendors = createAsyncThunk(
  'vendors/fetchVendors',
  async (_, { getState, rejectWithValue }) => {
    try {
      // Access the current state to get the page and limit values
      const { currentPage, itemsPerPage } = getState().vendors.pagination;

      console.log(currentPage)
      console.log(itemsPerPage)

      // Construct the query string with the retrieved page and limit values
      const queryString = `?currentPage=${currentPage}&itemsPerPage=${itemsPerPage}`;
      const response = await ShortListAdviceAPI.get('/api/vendors' + queryString);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);
