import React from 'react';
import Logo from "../../../assets/logo.png";
import Spinner from "../../../components/Spinner";
import {useDispatch} from "react-redux";
import {resetPassword} from "../../../redux/auth/authThunk";
import {useLocation, useNavigate} from "react-router-dom";

const ResetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const paramId = searchParams.get('id');

    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    function handleSubmit(e) {
      setLoading(true)
      e.preventDefault();
      console.log(password, confirmPassword)
      if (password !== confirmPassword) {
        alert('Passwords do not match');
        return;
      }
      dispatch(resetPassword({password, resetId: paramId})).unwrap()
        .then(r => {
          window.alert('Password reset successfully!');
          setLoading(false);
          navigate('/');
        })
        .catch(e => {
          window.alert('Password reset failed!');
          setLoading(false);
          navigate('/');
        })
    };

    return (
      <div className='flex h-screen flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gradient-to-r from-amber-400 to-slate-400'>
        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
            <div className='sm:mx-auto sm:w-full sm:max-w-md'>
              <img
                className='mx-auto h-12 w-auto'
                src={Logo}
                alt='Your Company'
              />
              <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>Reset your password</h2>
              <p className='my-3 mb-6 text-center text-sm text-gray-600'>
              </p>
            </div>
            <form className='space-y-3' onSubmit={(e) => handleSubmit(e)}>
              <div>
                <label htmlFor='password' className='block text-sm font-medium text-gray-700'>
                  Password
                </label>
                <div className='mt-1'>
                  <input
                    id='password'
                    name='password'
                    type='password'
                    autoComplete='current-password'
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    className='text-black block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-slate-500 focus:outline-none focus:ring-slate-500 sm:text-sm'
                  />
                </div>
              </div>
              <div>
                <label htmlFor='password' className='block text-sm font-medium text-gray-700'>
                  Confirm Password
                </label>
                <div className='mt-1'>
                  <input
                    id='password'
                    name='password'
                    type='password'
                    autoComplete='current-password'
                    required
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                    className='text-black block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-slate-500 focus:outline-none focus:ring-slate-500 sm:text-sm'
                  />
                </div>
              </div>
              <div className='pt-3'>
                <button
                  type='submit'
                  className='flex w-full justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2'
                >
                  {loading ? <Spinner/> : 'Reset Password'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
;

export default ResetPassword;
