export const targetPropertyOptions = [
  "None (No Score)",
  "VendorName",
  "URL",
  "Location",
  "CustomerEmployeeCount",
  "CustomerRevenue",
  "AverageAnnualLicense",
  "AverageSetupCost",
  "AverageSetupTime",
  "SalesTransparency",
  "InitialRelease",
  "Platform",
  "Interface",
  "Roadmap",
  "ActiveVersions",
  "AdvancedForecastingMethods",
  "DimensionSupport",
  "DriverBasedPlanningScale",
  "FinancialStatementsOutOfTheBox",
  "MaxPlanLength",
  "PreBuiltModels",
  "TopsideAdjustments",
  "AddCapitalAssetViaDriver",
  "CapExAllocations",
  "CapExApprovals",
  "CapExDepreciationAssignment",
  "CapExDepreciationSchedules",
  "CapExPlanningDetail",
  "TimeGranulaity",
  "DashboardAnalysis",
  "DashboardViews",
  "VisualizationTypes",
  "DemandForecastingMethods",
  "DemandPlanningLeadTime",
  "DemandPlanningProcessSupport",
  "HistoricalDataAmount",
  "InventoryPlanning",
  "LikeUnitPlanning",
  "MatrixItemSupport",
  "SeasonalityDetail",
  "PushToOperationalSystems",
  "UnitForecastDetail",
  "AccountReconciliationTransactions",
  "CloseManagement",
  "CloseManagementFeatures",
  "ConsolidationCompliance",
  "ConsolidationMethods",
  "ConsolidationMinority",
  "FinancialDataQualityManagementForConsolidation",
  "AnalysisCapabilities",
  "DataAggregation",
  "ReportFormattingTemplates",
  "ReportingComplexity",
  "ReportingInterfaces",
  "PipelineAnalysis",
  "QuotaPlanningCapabilities",
  "QuotaTrackingAndMonitoring",
  "SalesCompensationPlanning",
  "TerritoryPlanningCapabilities",
  "CalculateFullyBurdenedEmployeeCost",
  "CommissionPlanningCapabilities",
  "HCMIntegrationwithWorkforcePlanningModels",
  "PreBuiltPayrollDrivers",
  "SeasonalWorkforcePlanning",
  "UserScalability",
  "WorkforceDriverBasedHiringPlan",
  "WorkforceEmployeeCostAllocation",
  "WorkforcePlanningModelTypes",
  "MulticurrencySupport",
  "AnalysisChatbot",
];
