import React from 'react';

export default function CustomerStatusChip({ status }) {
  // Combined status configuration with labels and styling
  const statusConfig = {
    teamCreated: {
      label: "Team Created",
      style: 'bg-success-200 text-success-800'
    },
    scorecardCreated: {
      label: "Scorecard Created",
      style: 'bg-blue-200 text-blue-800'
    },
    questionnaireComplete: {
      label: "Questionnaire Complete",
      style: 'bg-warning-200 text-warning-800'
    },
    resultsSent: {
      label: "Match Report Sent",
      style: 'bg-success-200 text-success-800'
    },
    default: {
      label: "Unknown Status",
      style: 'bg-gray-200 text-gray-800'
    }
  };

  // Access the configuration based on the status, default if not found
  const { label, style } = statusConfig[status] || statusConfig.default;

  // Define the full set of Tailwind classes for the chip
  const chipClasses = `inline-block px-3 py-1 text-sm font-semibold rounded-full ${style}`;

  return (
    <div className={chipClasses}>
      {label}
    </div>
  );
}
