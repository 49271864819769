//Libraries
import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MailIcon } from "@heroicons/react/outline";
import { unwrapResult } from "@reduxjs/toolkit";
// Functions
import { setSelectedScorecard } from "../../redux/scorecards/scorecardsSlice";
import { updateCustomerStatus } from "../../redux/customerAuth/customerAuthSlice";
import {
  getScorecardByQuestionnaireId,
  saveTeam,
} from "../../redux/scorecards/scorecardsThunk";
// Components
import CustomerBackToShortlists from "../../components/CustomerBackToShortlists";
import MemberRow from "./components/MemberRow";
import InviteTeamMemberRow from "./components/InviteTeamMemberRow";
import Spinner from "../../components/Spinner";

const defaultTeamMember = {
  firstName: "",
  lastName: "",
  email: "",
};

export default function CustomerCreateTeam() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const questionnaireId = searchParams.get("qid");
  const currentUser = useSelector((state) => state.customerAuth.user);
  const currentCustomer = useSelector((state) => state.customerAuth.customer);

  const selectedScorecard = useSelector(
    (state) => state.scorecards.selectedScorecard
  );

  const [teamMembers, setTeamMembers] = useState([]);
  const [newInviteTeamMembers, setNewInviteTeamMembers] = useState([
    defaultTeamMember,
  ]);

  useEffect(() => {
    if (!selectedScorecard) {
      dispatch(getScorecardByQuestionnaireId({ id: questionnaireId }));
    } else {
      const currentUserEmail = currentUser.email;
      const filteredTeamMembers = selectedScorecard.teamMembers
        .filter(member => member.email !== currentUserEmail)
        .map(member => {
          const memberScore = selectedScorecard.memberScores.find(
            score => score.email === member.email
          );
          return {
            ...member,
            completedScorecard: memberScore ? true : false,
          };
        });
  
      setTeamMembers(filteredTeamMembers);
    }
  }, [selectedScorecard, currentUser, dispatch, questionnaireId]);

  const addNewMember = () => {
    const newMember = { ...defaultTeamMember };
    setNewInviteTeamMembers([...newInviteTeamMembers, newMember]);
  };

  const updateNewInviteMember = (index, newData) => {
    if (newData === null) {
      const updatedMembers = newInviteTeamMembers.filter(
        (_, memberIndex) => memberIndex !== index
      );
      setNewInviteTeamMembers(updatedMembers);
    } else {
      const updatedMembers = newInviteTeamMembers.map((member, memberIndex) =>
        memberIndex === index ? { ...member, ...newData } : member
      );
      setNewInviteTeamMembers(updatedMembers);
    }
  };

  const handleSaveTeam = async () => {
    setIsLoading(true)
    const saveTeamBody = {
      questionnaireId: questionnaireId,
      teamMembers: [...teamMembers, ...newInviteTeamMembers],
    };

    try {
      const resultAction = await dispatch(saveTeam({ body: saveTeamBody }));
      unwrapResult(resultAction);

      dispatch(setSelectedScorecard(null));
      dispatch(updateCustomerStatus("teamCreated"));
      navigate("/my-shortlists");
    } catch (error) {
      console.error("Failed to save team:", error);
      setIsLoading(false)
    }
  };

  

const [loading, setIsLoading] = useState(false);

  return (
    <div className="p-6 bg-white shadow-md rounded-lg pt-8">
      <div className="flex justify-between">
        <CustomerBackToShortlists />
      </div>
      <div className="font-semibold text-2xl pb-3">
        <div>Manage Access</div>
        <p className="text-sm text-gray-600 font-normal leading-normal">
          Here you can manage a team of people to evaluate the vendors with you.
          Team Members added here will receive a private link to join in scoring
          vendor demonstrations.
        </p>
      </div>
      <div className="flex p-3 flex-col sm:flex-row">
        <div className="w-full sm:w-1/4 pb-3 text-gray-700 pr-3">
          <div className="font-semibold">Invite Team Members</div>
          <div className="text-sm text-gray-500">
            Invite your colleagues to collaborate with you on your decision.
          </div>
        </div>
        <div className="flex flex-col flex-1 relative">
          <div className="space-y-3 pb-16">
            {newInviteTeamMembers.map((member, index) => (
              <InviteTeamMemberRow
                key={index}
                index={index}
                firstName={member.firstName}
                lastName={member.lastName}
                email={member.email}
                updateFunc={updateNewInviteMember}
              />
            ))}

            <button
              onClick={addNewMember}
              className="bg-blue-100 text-blue-600 text-sm rounded-md p-2 px-4 hover:bg-blue-700 hover:text-white transition-all duration-150"
            >
              + Add New Member
            </button>
          </div>
          <button
            onClick={handleSaveTeam}
            className="flex items-center justify-center space-x-2 mt-3 w-full sm:w-1/2 absolute right-0 bottom-0 bg-blue-700 text-white text-sm rounded-md p-2 px-4 hover:bg-blue-800 font-semibold hover:text-white transition-all duration-150"
          >
            {!loading && <MailIcon className="h-5 w-5" />}
            {loading ? <Spinner /> : <span>Send Invites</span>}
          </button>
        </div>
      </div>
      <div className="border-t border-gray-300 my-4"></div>

      <div className="flex p-3 flex-col sm:flex-row">
        <div className="w-full sm:w-1/4 pb-3 text-gray-700 pr-3">
          <div className="font-semibold">Team Members</div>
          <div className="text-sm text-gray-500">
            Manage your existing Team Members.
          </div>
        </div>
        <div className="flex flex-col flex-1 space-y-2">
          {teamMembers.map((member, index) => (
            <MemberRow
              key={index}
              index={index}
              firstName={member.firstName}
              lastName={member.lastName}
              status={member.status}
              completedScorecard={member.completedScorecard}
              email={member.email}
              currentUserName={currentCustomer.firstName}
              currentScorecardId={selectedScorecard?._id}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
