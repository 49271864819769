import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentPage } from '../../../../redux/requirements/requirementsSlice';
import { fetchRequirements } from '../../../../redux/requirements/requirementsThunk';

const Pagination = () => {
  const dispatch = useDispatch();
  const { pagination } = useSelector(state => state.requirements);
  const { currentPage, totalPages } = pagination;

  const goToPage = (page) => {
    dispatch(updateCurrentPage(page));
    dispatch(fetchRequirements());
  };

  const pageButtons = Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
    <button 
      key={page} 
      className={`px-3 py-1 mx-1 rounded-md ${
        currentPage === page ? 'bg-blue-500 text-white' : 'bg-white hover:bg-blue-100'
      }`}
      onClick={() => goToPage(page)}
    >
      {page}
    </button>
  ));

  return (
    <div className="flex items-center justify-center my-4 bg-gray-200 p-2 rounded-md text-sm" >
      <button 
        className="px-3 py-1 mx-1 rounded-md bg-white hover:bg-blue-100 cursor-pointer"
        onClick={() => goToPage(1)} 
        disabled={currentPage === 1}
      >
        First
      </button>
      <button 
        className="px-3 py-1 mx-1 rounded-md bg-white hover:bg-blue-100 cursor-pointer"
        onClick={() => goToPage(currentPage - 1)} 
        disabled={currentPage === 1}
      >
        Previous
      </button>
      
      {pageButtons}

      <button 
        className="px-3 py-1 mx-1 rounded-md bg-white hover:bg-blue-100 cursor-pointer"
        onClick={() => goToPage(currentPage + 1)} 
        disabled={currentPage === totalPages}
      >
        Next
      </button>
      <button 
        className="px-3 py-1 mx-1 rounded-md bg-white hover:bg-blue-100 cursor-pointer"
        onClick={() => goToPage(totalPages)} 
        disabled={currentPage === totalPages}
      >
        Last
      </button>
    </div>
  );
};

export default Pagination;
