import { ArrowLeftIcon } from "@heroicons/react/outline";
import React from "react";
import { useDispatch } from "react-redux";
import { saveRequirementToDb } from "../../../../redux/requirements/requirementsThunk";
import toast from "react-hot-toast";
import { toggleEditor } from "../../../../redux/requirements/requirementsSlice";

export default function Header() {
  const dispatch = useDispatch();

  const handlePublish = () => {};

  const handleSaveDraft = async () => {
    console.log('handle save')
    const result = await dispatch(saveRequirementToDb());
    console.log('got result')
    if (result.error) {
      console.log('had error')
      toast.error("Failed to Save, please update all fields.");
    } else {
      console.log('dispatching action')
      dispatch(toggleEditor()); // Navigate back on successful save
    }
  };

  return (
    <div className="flex justify-between items-center p-3">
      <div className="flex items-center justify-center">
        <div
          onClick={() => dispatch(toggleEditor())}
          className="cursor-pointer hover:bg-gray-100 border rounded-full bg-white border-gray-400 p-1 mr-2"
        >
          <ArrowLeftIcon className="h-4 w-4 text-gray-900" />
        </div>
        <div className="text-2xl font-semibold">Create/Edit Requirements</div>
      </div>
      <div className="flex justify-end space-x-2">
        <button
          type="button"
          onClick={() => handleSaveDraft()}
          className="px-4 py-2 text-sm border border-gray-300 bg-white font-semibold text-gray-700 hover:bg-gray-100 rounded-md"
        >
          Save As Draft
        </button>
        {/* <button
          onClick={() => handlePreview()}
          className="px-4 py-2 text-sm border border-blue-600 bg-blue-600 font-semibold text-white hover:bg-blue-700 rounded-md"
        >
          Preview Requirement
        </button> */}
        <button
          onClick={() => handlePublish()}
          className="px-4 py-2 text-sm border border-blue-600 bg-blue-600 font-semibold text-white hover:bg-blue-700 rounded-md"
        >
          Publish Requirement
        </button>
      </div>
    </div>
  );
}
