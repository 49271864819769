import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  toggleSlideout,
  setSelectedCategory,
  deleteCategory,
} from "../../../../redux/questions/questionsSlice"; // Import additional actions
import EmptyTable from "./EmptyTable";
import StatusBadge from "./StatusBadge";
import { TrashIcon, PencilIcon } from "@heroicons/react/outline";
import { saveQuestionsToDb } from "../../../../redux/questions/questionsThunk";

const CategoryTable = () => {
  const categories = useSelector((state) => state.questions.categories);
  const dispatch = useDispatch();

  // const hideInProduction = ['Contact', 'Source Systems', 'Select Categories']
  const hideInProduction = []

  const [visibleCategories, setVisibleCategories] = useState([])
  

  const handleAddCategoryClick = () => {
    dispatch(setSelectedCategory(null)); // Reset selected category when adding new
    dispatch(toggleSlideout());
  };

  const handleEditClick = (category) => {
    dispatch(setSelectedCategory(category));
    dispatch(toggleSlideout());
  };

  const handleDeleteClick = (categoryId, categoryTitle) => {
    const userInput = window.prompt(
      `Please type '${categoryTitle}' to confirm deletion:`
    );

    if (userInput === categoryTitle) {
      dispatch(deleteCategory(categoryId));

      // Wait for half a second before calling saveQuestionsToDb
      setTimeout(() => {
        dispatch(saveQuestionsToDb());
      }, 500);
    } else {
      // Do nothing
    }
  };

  useEffect(() => {
    // if (process.env.REACT_APP_ENV === 'development') {
    //   setVisibleCategories(categories);
    // } else {
    //   const filteredCategories = categories.filter(
    //     (category) => !hideInProduction.includes(category)
    //   );
    //   setVisibleCategories(filteredCategories);
    // }
  }, [categories]);

  return (
    <div className="p-4">
      <div className="flex w-full min-h-full items-center justify-start flex-col text-center font-sans text-sm shadow-sm text-left">
        <div className="overflow-x-auto min-h-full min-h-full min-w-full ">
          {categories?.length === 0 ? (
            <EmptyTable handleAddCategoryClick={handleAddCategoryClick} />
          ) : (
            <table className="min-w-full bg-white">
              <thead className="bg-gray-200 w-full border-separate">
                <tr>
                  <th className="text-left py-3 px-4 text-start rounded-tl-lg border-grayBlue-200">
                    Category Name
                  </th>
                  <th className="text-left py-3 px-4 text-start border-l border-grayBlue-200">
                    Subcategories
                  </th>
                  <th className="text-left py-3 px-4 text-start border-l border-grayBlue-200">
                    Status
                  </th>
                  <th className="text-left py-3 px-4 text-start rounded-tr-lg border-l border-grayBlue-200">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {categories?.map((category, index) => (
                  <tr key={index} className={(category.title !== "Company Details" && category.title !== "Contact") ? 'border-b' : 'border-b bg-gray-50 text-gray-400 italic text-sm'}>
                    <td className="py-3 px-4 text-left">{category.title}</td>
                    <td className="py-3 px-4 text-left">
                      {category.subcategories
                        .map((sub) => sub.title)
                        .join(", ")}
                    </td>
                    <td className="py-3 px-4 text-left">
                      <StatusBadge status={category.status} />
                    </td>
                     <td className="py-3 px-4 text-left flex gap-1">
                      <button
                        className=" bg-gray-200 hover:bg-gray-300 py-1 px-2 rounded-md"
                        onClick={() => handleEditClick(category)}
                      >
                      <PencilIcon className="h-5 w-5" />
                      </button>
                      {category.title !== "Company Details" && category.title !== "Contact" && <button
                        className="bg-gray-200 hover:bg-gray-300 py-1 px-2 rounded-md"
                        onClick={() => handleDeleteClick(category.id, category.title)}
                      >
                      <TrashIcon className="h-5 w-5" />
                      </button>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryTable;
