import { PlusCircleIcon, SpeakerphoneIcon } from "@heroicons/react/outline";
import React, { useState } from "react";

export default function AddCommentBtn({comment}) {
  const [showSendEmailTooltip, setShowSendEmailTooltip] = useState(false);
  const speakerPhoneStyle = comment?.length > 0 ? 'h-5 w-5 text-gray-500 hover:text-gray-700 cursor-pointer z-10' : 'h-5 w-5 opacity-0 z-10'
  return (
    <div className="flex items-center space-x-2 z-0">
      <div
        onMouseEnter={() => setShowSendEmailTooltip(true)}
        onMouseLeave={() => setShowSendEmailTooltip(false)}
        className="cursor-pointer bg-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-300 p-1 rounded-lg relative"
      >
        <PlusCircleIcon className="h-5 w-5" />
        {showSendEmailTooltip && (
            <div className="w-24 flex items-center justify-center text-center absolute -top-8 -left-8 z-10 p-1 text-xs text-white bg-black rounded-md">
              Add Comment
            </div>
          )}
      </div>
      <div className="group relative">
          <SpeakerphoneIcon className={speakerPhoneStyle} />
          {comment?.length > 0 && <div className="z-20 absolute hidden group-hover:block -left-36 w-36 text-center top-0 bg-gray-900 text-white text-xs rounded-lg px-2 py-1">
            {comment}
          </div>}
        </div>
    </div>
  );
}
