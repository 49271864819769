// CustomerDetails.js
import React from 'react';
import Header from './Header';
import { LinkIcon, NewspaperIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomerStatusChip from '../../../../components/CustomerStatusChip';
import { handleExportData, setLoadedMatchReport } from '../../../../redux/customers/customersSlice';

const CustomerDetails = ({ }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const customer = useSelector(state => state.customers.selectedCustomer)
  const loadedMatchReport = useSelector(state => state.customers.loadedMatchReport)

  const handleTeamMemberClick = (memberId) => {
    console.log(`Team member with id: ${memberId} was clicked`);
    // Implement the logic or navigation when a team member is clicked
  };

  const handleViewReportClick = (questionnaireId) => {
    console.log(`View report for questionnaire with id: ${questionnaireId}`);
    // Implement the logic or navigation when a view report button is clicked
    navigate(`/questionnare-results?qid=${questionnaireId}`)
  };

  const handleExportDataBtn = (questionnaireId) => {
    console.log(`Export Clicked for questionnaire with id: ${questionnaireId}`);
    dispatch(handleExportData(questionnaireId));
  };
  
  const handleViewMatchReportBtn = (questionnaireId) => {
    console.log(`handleViewMatchReportBtn for questionnaire with id: ${questionnaireId}`);
    // dispatch(handleExportData(questionnaireId));
    dispatch(setLoadedMatchReport(questionnaireId));
    navigate(`/view-match-report?qid=${questionnaireId}`)
  };

  const handleViewScorecardsBtn = (questionnaireId) => {
    console.log(`handleViewScorecardsBtn for questionnaire with id: ${questionnaireId}`);
    // dispatch(handleExportData(questionnaireId));
    navigate(`/view-team-and-scorecards?qid=${questionnaireId}`)
  };


  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <Header />
    
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-gray-50 px-3 py-3 sm:py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Full name
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
              {customer.firstName} {customer.lastName}
            </dd>
          </div>
          <div className="bg-white px-3 py-3 sm:py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Email address
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
              {customer.email}
            </dd>
          </div>
          <div className="bg-gray-50 px-3 py-3 sm:py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Company
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
              {customer.company}
            </dd>
          </div>
          <div className="bg-white px-3 py-3 sm:py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Status
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
            <CustomerStatusChip status={customer.status} />
            </dd>
          </div>
          <div className="bg-gray-50 px-3 py-3 sm:py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Last Modified
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
              {customer.updatedAt}
            </dd>
          </div>
          <div className="bg-white px-3 py-3 sm:py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Team Members
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
              <ul className="flex flex-wrap">
                {customer.teamMembers.map((member) => (
                  <li key={member._id} className="mr-2 mb-2">
                    <button
                      onClick={() => handleTeamMemberClick(member._id)}
                      className="flex items-center space-x-2 bg-blue-600 p-1.5 px-3 rounded-md text-white hover:bg-blue-700"
                    >
                      <span>{member.name} </span>
                      <LinkIcon  className='h-4 w-4'/>
                    </button>
                  </li>
                ))}
              </ul>
            </dd>
          </div>
          <div className="bg-gray-50 px-3 py-3 sm:py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Completed Questionnaires
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th className="px-6 py-3 bg-gray-50"></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 border ">
                  {customer.completedQuestionnaires.map((questionnaire) => (
                    <tr key={questionnaire._id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {new Date(questionnaire.createdAt).toLocaleString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium grid grid-cols-2 gap-2">
                      <button
                          onClick={() => handleViewReportClick(questionnaire._id)}
                          className="bg-blue-600 p-2 px-4 rounded-md text-white hover:bg-blue-700"
                        >
                          GPT-4 Results
                        </button>
                        <button
                          onClick={() => handleExportDataBtn(questionnaire._id)}
                          className="bg-gray-200 p-2 px-4 rounded-md text-gray-700 hover:text-gray-900 hover:bg-blue-200"
                        >
                          Export Data
                        </button>
                        <button
                          onClick={() => handleViewMatchReportBtn(questionnaire._id)}
                          className="bg-gray-200 p-2 px-4 rounded-md text-gray-700 hover:text-gray-900 hover:bg-blue-200"
                        >
                          View Match Report
                        </button>
                        <button
                          onClick={() => handleViewScorecardsBtn(questionnaire._id)}
                          className="bg-gray-200 p-2 px-4 rounded-md text-gray-700 hover:text-gray-900 hover:bg-blue-200"
                        >
                          Team + Scorecards
                        </button>
                      </td>
                      <td>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default CustomerDetails;
