import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  changeVendor,
  initForm,
  setCategories,
  setInitVendors,
  setScoreCardData,
  updateScore,
} from "../../../../redux/scoreVendors/scoreVendorsSlice";
import { getScorecardByQuestionnaireId } from "../../../../redux/scorecards/scorecardsThunk";
import TabController from './TabController.js'
import Rating from './Rating.js'
import { setScorecardTableScaffold } from "../../../../redux/scorecards/scorecardsSlice.js";

// Data overview for scorecards:
// First, a SCORECARD is what cusomers use, scoring vendors.
// A MATCH REPORT is the thing which has GptScores, etc.
// scoreCardData --> This is an array of ALL requirements in the scorecard. This is not sorted.
// questionnaireId --> ID reference for the questionnaire used in scorecard creation
// customerId --> Owner's CustomerId
// teamMembers: [{ type: teamMemberSchema }], --> List of firstName, lastName, email, status
// scoreCardData: --> This is an array of ALL requirements in the scorecard. This is not sorted. Stored in DB as string.
// memberScores: --> list of email + scores associated with that email. This might contain
// "bad" question data, such as if a user scored an answer, and then that question was removed from scorecardData.
// So, member scores always need to be filtered by whatever is present in the scoreCardData. Similarly,
// scoreCardData may have addtional fields which memberScores don't have.
// selectedWinner --> Object with licenseCost, reason, setupCost, and winner (their name).

// SO, the way this view is set up is that the initial scorecardData loads, which determines what the table looks like.
// This is done by converting scoreCardData --> "Categories" stored at scorecardCategories.
// Then, we can reference member scores by searching for their responses to each requirement,
// by functionalArea and name/title. If a user doesn't have data for a given entry, we score it as "blank."

// NOTE: Making this "scorecardTableScaffold" to mke this more clear.



const AdminTeamScores = () => {
  const dispatch = useDispatch();

  const currentVendor = useSelector(
    (state) => state.scoreVendors.currentVendor
  );

  const scoreCardData = useSelector(
    (state) => state.scoreVendors.scoreCardData
  );
  const scorecardCategories = useSelector(
    (state) => state.scoreVendors.categories
  );
  const selectedScorecard = useSelector(
    (state) => state.scorecards.selectedScorecard
  );
  const scorecardTableScaffold = useSelector(
    (state) => state.scorecards.scorecardTableScaffold
  );

  const [teamMembers, setTeamMembers] = useState([]);
  const [memberScores, setMemberScores] = useState([]);


  useEffect(() => {
    if (selectedScorecard?.teamMembers) {
      setTeamMembers(selectedScorecard.teamMembers);
    }
    if (selectedScorecard?.memberScores) {
      let tempMemberScores = [];
      for (let i = 0; i < selectedScorecard.memberScores.length; i++) {
        const parsedScores = JSON.parse(
          selectedScorecard.memberScores[i].scores
        );
        const email = selectedScorecard.memberScores[i].email;
        tempMemberScores.push({ email: email, scores: parsedScores });
      }

      setMemberScores(tempMemberScores);

      // Also, update vendor tabs:
      let vendorsList = [];
        selectedScorecard.memberScores.forEach(item => {
            const scores = JSON.parse(item.scores);
            // Add unique vendors to the list
            scores.forEach(score => {
                if (!vendorsList.includes(score.vendor)) {
                    vendorsList.push(score.vendor);
                }
            });
        });
        // Once all vendors have been captured
        console.log('Unique Vendors List:', vendorsList);
        dispatch(setInitVendors(vendorsList));
        if (vendorsList.length > 0) {
            dispatch(changeVendor(vendorsList[0]));
        }
    }
    if (selectedScorecard?.scoreCardData) {
      dispatch(setScorecardTableScaffold());
    }

    console.log('*****selectedScorecard')
    console.log(selectedScorecard)
    if (selectedScorecard?.scoreCardData) {
        const scoreCardData = JSON.parse(selectedScorecard.scoreCardData)
        console.log(scoreCardData)
        
    // This extracts the category + item data from the scorecardData.
    function transformData(data) {
        // Group data by 'functionalArea' into an object map
        const groupedByCategory = data.reduce((acc, item) => {
          const key = item.functionalArea;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(item.name);
          return acc;
        }, {});
  
        // Convert the grouped object map into the desired array format with 'items' key
        const transformedArray = [];
        Object.keys(groupedByCategory).forEach((category) => {
          const categoryObject = {
            category: category,
            items: groupedByCategory[category],
          };
          transformedArray.push(categoryObject);
        });
  
        return transformedArray;
      }
  
      const extractedCategoryData = transformData(scoreCardData);
  
      // Set these locally, and dispatch an update to redux
      dispatch(setCategories(extractedCategoryData));
      dispatch(setScoreCardData(scoreCardData))
    }
    
  }, [selectedScorecard, dispatch]);


  // This is a helper function to extract the requirement score data from the overall scorecard
  const filterScoresByRequirement = (scores, vendor, requirement) => {
    // console.log("FILTERING");
    // console.log(scores);
    // console.log(vendor);
    // console.log(requirement);
    if (!scores || requirement === "") return {};
    return scores
      .filter((score) => score.vendor === vendor) // First filter by vendor
      .flatMap((score) => score.items) // Flatten the items arrays from all score objects
      .filter((item) => item.requirement === requirement)[0]; // Then filter by requirement
  };

  useEffect(() => {
    console.log("memberScores");
    console.log(memberScores);
  }, [memberScores]);

  useEffect(() => {
    console.log("teamMembers");
    console.log(teamMembers);
  }, [teamMembers]);

  useEffect(() => {
    console.log("scorecardCategories");
    console.log(scorecardCategories);
  }, [scorecardCategories]);

  return (
    <div className="">
      <TabController />
      {scorecardCategories.map((category, categoryIndex) => {
        const currentCategoryData = scorecardCategories.find(
          (data) => data.category === category.category
        );
        if (currentCategoryData === undefined) return <></>;

        return (
          <div key={categoryIndex} className="px-6 overflow-auto">
            <div className="flex mb-4 mt-8 items-end">
              <div className="w-1/3 text-2xl font-bold pl-1 text-gray-800">
                {category.category}
              </div>
              <div className="flex w-2/3 jusify-between">
                <div className="px-2 flex flex-col text-xs justify-end text-end opacity-0">
                  <div>Score:</div>
                  <div>Importance:</div>
                </div>
                {teamMembers.map((teamMember) => {
                  return (
                    <div className="flex flex-col items-center justify-center text-start text-xs font-bold w-24 pl-6 text-gray-800">
                      <div className="w-24">{teamMember.firstName}</div>
                      <div className="w-24">{teamMember.lastName}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="border-gray-200 border-l border-r border-t">
              {category.items.map((requirement, index) => {
                const currentItem = currentCategoryData?.items[index];
                let relevantScores = [];

                // Here, we use a helper function to find the specific scores we need to render in the teamMember view.
                for (let i = 0; i < memberScores.length; i++) {
                  const requirementScore = filterScoresByRequirement(
                    memberScores[i]?.scores,
                    currentVendor,
                    currentItem || ""
                  );

                  relevantScores.push({
                    email: memberScores[i].email,
                    importance: requirementScore?.importance || -1,
                    score: requirementScore?.score || -1,
                    requirement: requirementScore?.requirement || "",
                    comment: requirementScore?.comment || "",
                  });
                }

                return (
                  <div
                    key={index}
                    className="flex border-gray-200 border-b p-2 bg-white"
                  >
                    <div className="w-1/3 text-sm">
                      <div>{requirement}</div>
                    </div>
                    <div className="flex w-2/3">
                      <div className="px-2 flex flex-col text-xs justify-center text-end h-full space-y-2 text-gray-500">
                        <div>Score:</div>
                        <div>Importance:</div>
                      </div>
                      {teamMembers.map((teamMember, teamMemberIndex) => {
                        // Find the score object that matches the team member's email

                        const matchedScore = relevantScores.find(
                          (score) => score.email === teamMember.email
                        );

                        // console.log('0-00')
                        // console.log(teamMember.email)
                        // console.log(matchedScore)

                        // Extract score and importance if matchedScore is found; otherwise, set default values
                        const scoreValue = matchedScore?.score || -1;
                        const importanceValue = matchedScore?.importance || -1;
                        const comment = matchedScore?.comment || "";

                        return (
                          <div className="">
                            <div className="flex flex-col items-center justify-center text-center text-sm w-24 h-full space-y-2">
                              <Rating score={scoreValue} comment={comment} />
                              <Rating score={importanceValue} comment="" />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default AdminTeamScores;
