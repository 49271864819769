import React from "react";

export default function EmptyTable() {
  return (
    <div className="flex w-full min-h-full items-center justify-start flex-col text-center font-sans text-sm">
      <table className="w-full">
        <thead className="bg-gray-200 w-full border-separate">
          <tr>
            <th className="text-left py-3 px-4 text-start rounded-tl-lg border-grayBlue-200 font-semibold">
              Name
            </th>
            <th className="text-left py-3 px-4 text-start border-l border-grayBlue-200 font-semibold">
              Email
            </th>
            <th className="text-left py-3 px-4 text-start border-l border-grayBlue-200 font-semibold">
              Company
            </th>
            <th className="text-left py-3 px-4 text-start border-l border-grayBlue-200 font-semibold">
              Status
            </th>
            <th className="text-left py-3 px-4 text-start border-l border-grayBlue-200 font-semibold">
              Last Modified
            </th>
            <th className="text-left py-3 px-4 text-start rounded-tr-lg border-l border-grayBlue-200 font-semibold">
              Actions
            </th>
          </tr>
        </thead>
      </table>
    </div>
  );
}
