import React from "react";

export default function SubcategoryQuestionCount({ number }) {
  return (
    number == 0 ? <></> :
    <div class="flex justify-center items-center">
      <div class="w-5 h-5 bg-[#9F1AB1] rounded-full flex items-center justify-center text-white text-xs font-normal">
        <span>{number}</span>
      </div>
    </div>
  );
}
