import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SubcategoryQuestionAnswerItem from "./SubcategoryQuestionAnswerItem";
import {
  addAnswerToQuestion,
  deleteAnswerFromQuestion,
  updateAnswersOrder,
} from "../../../../redux/questions/questionsSlice"; // Adjust import path
import { v4 as uuidv4 } from "uuid";

const QuestionAnswersForm = ({ question }) => {
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.questions.currentQuestions);
  const currentQuestion = questions.find((q) => q.id === question.id) || {};
  const answers = currentQuestion.answers || [];

  
  useEffect(() => {
    // If questions are empty, load 3 questions.
    if (answers.length == 0) {
      const newAnswer1 = { id: uuidv4(), text: "" };
      dispatch(
        addAnswerToQuestion({ questionId: question.id, answer: newAnswer1 })
      );
      const newAnswer2 = { id: uuidv4(), text: "" };
      dispatch(
        addAnswerToQuestion({ questionId: question.id, answer: newAnswer2 })
      );
      const newAnswer3 = { id: uuidv4(), text: "" };
      dispatch(
        addAnswerToQuestion({ questionId: question.id, answer: newAnswer3 })
      );

    }
  }, [])
  

  const handleAddAnswer = () => {
    const newAnswer = { id: uuidv4(), text: "" };
    dispatch(
      addAnswerToQuestion({ questionId: question.id, answer: newAnswer })
    );
  };

  const handleMoveAnswer = (fromIndex, toIndex) => {
    const newAnswers = [...answers];
    const [movedAnswer] = newAnswers.splice(fromIndex, 1);
    newAnswers.splice(toIndex, 0, movedAnswer);
    dispatch(
      updateAnswersOrder({ questionId: question.id, answers: newAnswers })
    );
  };

  const handleDelete = (index) => {
    dispatch(
      deleteAnswerFromQuestion({ questionId: question.id, answerIndex: index })
    );
  };

  return (
    <div className=''>
      <div className='pb-2 flex items-end justify-between'>
      <div className='font-semibold text-gray-900 text-md'>Answers</div>
      <button
        onClick={handleAddAnswer}
        className="mt-1 px-4 py-1 text-sm hover:bg-blue-100 text-blue font-bold rounded"
      >
        Add Answer
      </button>
      </div>
      <div className="w-full py-2">
        {answers.map((answer, index) => (
          <div key={answer.id} className="flex items-center mb-2 w-full">
            <SubcategoryQuestionAnswerItem
              answer={answer}
              question={question}
              index={index}
              answers={answers}
              handleMoveAnswer={handleMoveAnswer}
              onDelete={() => handleDelete(index)}
            />
          </div>
        ))}
      </div>
      
    </div>
  );
};

export default QuestionAnswersForm;
