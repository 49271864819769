import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../components/PageHeader/PageHeader';
// import SubcategoryQuestionList from './components/SubcategoryQuestionList';
import CategoryTable from './components/CategoryTable/CategoryTable';
import { setSelectedCategory, toggleModal, toggleSlideout } from '../../redux/questions/questionsSlice'; // Import your Redux actions
import CategoryModal from './components/CategoryModal';
import CategorySlideout from './components/CategorySlideout';
import { saveQuestionsToDb } from '../../redux/questions/questionsThunk';
import { initQuestionnaire } from '../../redux/questionnaire/questionnaireSlice';
import { useNavigate } from 'react-router-dom';
import { getAdminQuestionnaire } from '../../redux/questions/questionsThunk';
import Spinner from '../../components/Spinner.js'

export default function QuestionnaireEditor() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const questionsStatus = useSelector(state => state.questions.status)

  const handleAddCategoryClick = () => {
    dispatch(setSelectedCategory(null)); // Reset selected category when adding new
    dispatch(toggleSlideout());
  };
  
  const handlePreviewQuestionnaire = () => {
    dispatch(saveQuestionsToDb())
    dispatch(initQuestionnaire())
    navigate("/questionnaire")
  }
  
  
  useEffect(() => {
    if (questionsStatus === "init") {
      dispatch(getAdminQuestionnaire());
    }
  }, []);
  

  const actions = [{
    text: "Add Category",
    func: handleAddCategoryClick
  },
  {
    text: "Preview Questionnaire",
    func: handlePreviewQuestionnaire
  },
]

if (questionsStatus == "loading") {
  return (
    <div className='min-h-full flex flex-col items-center justify-center'>
      <Spinner />
    </div>
  );
}

  return (
    <div className='min-h-full flex flex-col'>
      <PageHeader pageName="Questionnaire Editor" actions={actions}/>
      <CategoryTable />
      <CategoryModal />
      <CategorySlideout />
    </div>
  );
}
