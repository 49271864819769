export const data = [

    {
      category: "Planning",
      items: [
        {
          requirement: "",
          member1: "TJ",
          member2: "Jeff",
          member3: "Andrea",
          member4: "Wendy",
          average: "Average",
        },
        {
          requirement: "Master currency conversion table with starting and ending rates",
          member1: 4.2,
          member2: 4.6,
          member3: 3.9,
          member4: 4.1,
          average: 4.2,
        },
        {
          requirement: "Capital Asset Planning includes pre-built and configurable depreciation schedules",
          member1: 4.0,
          member2: 4.0,
          member3: 4.3,
          member4: 3.9,
          average: 4.05,
        },
        {
          requirement: "Integrated budgeting and forecasting",
          member1: 3.5,
          member2: 4.2,
          member3: 5,
          member4: 3.7,
          average: 4.1,
        },
        {
          requirement: "Real-time budget vs actuals reporting",
          member1: 1.1,
          member2: 2.0,
          member3: 1.5,
          member4: 2.2,
          average: 1.7,
        },
        {
          requirement: "Scenario planning",
          member1: 3.8,
          member2: 4.3,
          member3: 4.1,
          member4: 4.0,
          average: 4.05,
        },
        {
          requirement: "Revenue forecasting",
          member1: 3.9,
          member2: 4.2,
          member3: 4.6,
          member4: 4.3,
          average: 4.25,
        },
        {
          requirement: "Personnel planning",
          member1: 4.2,
          member2: 4.6,
          member3: 3.9,
          member4: 4.1,
          average: 4.2,
        },
        {
          requirement: "Cash flow forecasting",
          member1: 4.1,
          member2: 4.4,
          member3: 4.0,
          member4: 3.6,
          average: 4.025,
        },
      ],
    },
    {
      category: "Forecasting",
      items: [
        {
          requirement: "",
          member1: "TJ",
          member2: "Jeff",
          member3: "Andrea",
          member4: "Wendy",
          average: "Average",
        },
        {
          requirement: "What-if analysis capabilities",
          member1: 3.5,
          member2: 4.2,
          member3: 4.5,
          member4: 3.8,
          average: 4.0,
        },
        {
          requirement: "Multi-dimensional forecasting models",
          member1: 4.3,
          member2: 3.9,
          member3: 4.2,
          member4: 4.1,
          average: 4.125,
        },
        {
          requirement: "Rolling forecasts",
          member1: 4.1,
          member2: 4.4,
          member3: 4.0,
          member4: 3.6,
          average: 4.025,
        },
        {
          requirement: "Conditional forecasting logic",
          member1: 4.0,
          member2: 4.3,
          member3: 3.8,
          member4: 4.2,
          average: 4.075,
        },
        {
          requirement: "Forecasting based on statistical methods",
          member1: 3.6,
          member2: 4.5,
          member3: 4.1,
          member4: 3.7,
          average: 3.975,
        },
        {
          requirement: "Collaborative forecasting process",
          member1: 3.9,
          member2: 4.2,
          member3: 4.6,
          member4: 4.3,
          average: 4.25,
        },
      ],
    },
    {
      category: "Reporting",
      items: [
        {
          requirement: "",
          member1: "TJ",
          member2: "Jeff",
          member3: "Andrea",
          member4: "Wendy",
          average: "Average",
        },
        {
          requirement: "Dimensions can be aliased for the sake of more understandable reporting",
          member1: 4,
          member2: 4.5,
          member3: 3,
          member4: 3.2,
          average: 3.675,
        },
        {
          requirement: "Flexible reporting tool allowing custom column and row creation",
          member1: 3.3,
          member2: 4.5,
          member3: 3.5,
          member4: 3.0,
          average: 3.575,
        },
        {
            requirement: "Supports both financial and operational reporting",
            member1: 4.2,
            member2: 4.0,
            member3: 4.4,
            member4: 3.9,
            average: 4.125,
          },
          {
            requirement: "Automated report distribution capabilities",
            member1: 4.1,
            member2: 3.8,
            member3: 4.2,
            member4: 3.9,
            average: 4.0,
          },
          {
            requirement: "Ad-hoc reporting and analysis",
            member1: 3.7,
            member2: 4.3,
            member3: 4.1,
            member4: 4.2,
            average: 4.075,
          },
          {
            requirement: "Mobile reporting access",
            member1: 3.8,
            member2: 4.2,
            member3: 4.5,
            member4: 4.0,
            average: 4.125,
          },
          {
            requirement: "Compliance with global reporting standards",
            member1: 4.0,
            member2: 4.1,
            member3: 3.9,
            member4: 3.8,
            average: 3.95,
          },
          {
            requirement: "Drill-down capabilities to transaction level details",
            member1: 4.4,
            member2: 4.6,
            member3: 3.8,
            member4: 4.1,
            average: 4.225,
          },
          {
            requirement: "Customizable dashboards",
            member1: 4.3,
            member2: 4.4,
            member3: 4.0,
            member4: 3.7,
            average: 4.1,
          },
          {
            requirement: "Real-time data visualization",
            member1: 4.5,
            member2: 4.2,
            member3: 3.7,
            member4: 4.0,
            average: 4.1,
          },
          {
            requirement: "Integration with external data sources for reporting",
            member1: 3.6,
            member2: 4.5,
            member3: 4.3,
            member4: 3.9,
            average: 4.075,
          },
          {
            requirement: "Pre-built industry-specific report templates",
            member1: 3.9,
            member2: 4.0,
            member3: 4.2,
            member4: 4.1,
            average: 4.05,
          },
        ],
      },
      {
        category: "Compliance",
        items: [
          {
            requirement: "",
            member1: "TJ",
            member2: "Jeff",
            member3: "Andrea",
            member4: "Wendy",
            average: "Average",
          },
          {
            requirement: "Audit trails for all data entries and changes",
            member1: 4.2,
            member2: 4.1,
            member3: 3.7,
            member4: 4.0,
            average: 4.0,
          },
          {
            requirement: "Support for multiple regulatory compliance standards",
            member1: 4.3,
            member2: 3.9,
            member3: 4.5,
            member4: 4.2,
            average: 4.225,
          },
          {
            requirement: "Automated controls to prevent unauthorized data access",
            member1: 4.1,
            member2: 4.3,
            member3: 3.9,
            member4: 4.2,
            average: 4.125,
          },
          {
            requirement: "Data retention policies to meet compliance requirements",
            member1: 4.0,
            member2: 4.5,
            member3: 4.2,
            member4: 3.8,
            average: 4.125,
          },
          {
            requirement: "Regular updates to comply with new regulations",
            member1: 0.8,
            member2: 1.2,
            member3: 2.0,
            member4: 1.5,
            average: 1.375,
          },
        ],
      },
    ];