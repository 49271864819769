import React, { useState, useEffect } from "react";
import Dropdown from "../../../../components/Dropdown";
import VendorSelection from "./VendorSelection";
import { useDispatch, useSelector } from "react-redux";
import ScorecardUpdates from "../LiveDataScorecard/MatchReport";
import { adminSendResultsReady } from "../../../../redux/questionnaire/questionnaireThunk";
import {useNavigate} from 'react-router-dom'


export default function SendToTeam() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [customMessage, setCustomMessage] = useState(
    "Thank you for taking the time to take our questionnaire."
  );

  const [vendorOptions, setVendorOptions] = useState([]);
  const customerFirstName = useSelector(
    (state) => state.customers.selectedCustomer.firstName
  );
  const selectedVendors = useSelector(
    (state) => state.questionnaire?.gptScores?.selectedVendors || []
  );
  const matchReportSelectedVendors = useSelector(
    (state) => state.questionnaire?.gptScores?.matchReportSelectedVendors || []
  );
  const matchReportContactInfo = useSelector(
    (state) => state.questionnaire?.gptScores?.matchReportContactInfo || []
  );
  const customerId = useSelector(
    (state) => state.customers.selectedCustomer._id
  );
  const customerEmail = useSelector(
    (state) => state.customers.selectedCustomer.email
  );

  useEffect(() => {
    // Extract keys where the value is true
    const activeVendors = Object.keys(selectedVendors).filter(
      (key) => selectedVendors[key]
    );

    setVendorOptions(activeVendors);
  }, [selectedVendors]);

  const handleSendEmail = () => {
    // Use window.confirm to ask for user confirmation
    const confirmed = window.confirm(
      "Are you sure you would like to send this email? If so, click OK."
    );

    if (confirmed) {
      console.log("SENDING EMAIL");

      // Dispatch the thunk and handle the promise
      dispatch(adminSendResultsReady({ customerEmail: customerEmail, cid: customerId }))
        .unwrap()
        .then((result) => {
          // Navigation to '/customers' upon successful resolution of the promise
          navigate('/customers');
        })
        .catch((error) => {
          // Error handling if the thunk is rejected
          console.error('Failed to send email:', error);
        });
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-3">Pick Shortlist</h1>
      <div className="flex space-x-6">
        <VendorSelection vendors={vendorOptions} title={"Shortlist Top Pick"} />
        <VendorSelection
          vendors={vendorOptions}
          title={"Shortlist Second Pick"}
        />
        <VendorSelection
          vendors={vendorOptions}
          title={"Shortlist Third Pick"}
        />
      </div>
      <div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-grayBlue-700 mb-2">
            Custom Email Contents
          </label>
          <textarea
            value={customMessage}
            onChange={(e) => setCustomMessage(e.target.value)}
            className="border border-gray-300 rounded-md shadow-sm text-sm p-2 focus:outline-blue-600 w-full"
            rows="4"
          ></textarea>
        </div>
      </div>
      <h1 className="text-2xl font-bold mb-3">Email Preview</h1>
      <div className="bg-white border border-gray-200 rounded-lg p-2 mb-6 text-sm max-w-[600px] p-6">
        <h1 className="text-2xl font-bold py-4">Your report is here!</h1>
        <p className="pb-4">
          Our advisors have completed the human review of your AI-powered match
          report. We have ensured you've got the absolute best vendors
          applicable to your use-case. Click the link below to look at your
          detailed match report.
        </p>
        <div className="rounded-md border flex items-center justify-center w-48 py-2 border-gray-300 hover:bg-blue-600 cursor-pointer hover:text-white font-semibold hover:border-blue-600">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${window.location.origin}/customer-scorecard?cid=${customerId}`}
          >
            View your Match Report
          </a>
        </div>
        <p className={customMessage.length > 0 ? "pt-4" : ""}>
          {customMessage}
        </p>
        <div className="py-4">
          The three vendors above have been notified of your interest. Their
          contact information is listed below:
        </div>
        <div className="pb-2">
          <div className="font-semibold text-gray-900">
            {matchReportSelectedVendors[0]}
          </div>
          <div>{matchReportContactInfo[0].vendorName}</div>
          <div>{matchReportContactInfo[0].vendorEmail}</div>
          <div>{matchReportContactInfo[0].vendorPhone}</div>
        </div>
        <div className="pb-2">
          <div className="font-semibold text-gray-900">
            {matchReportSelectedVendors[1]}
          </div>
          <div>{matchReportContactInfo[1].vendorName}</div>
          <div>{matchReportContactInfo[1].vendorEmail}</div>
          <div>{matchReportContactInfo[1].vendorPhone}</div>
        </div>
        <div className="pb-2">
          <div className="font-semibold text-gray-900">
            {matchReportSelectedVendors[2]}
          </div>
          <div>{matchReportContactInfo[2].vendorName}</div>
          <div>{matchReportContactInfo[2].vendorEmail}</div>
          <div>{matchReportContactInfo[2].vendorPhone}</div>
        </div>
        <div className="py-6">
          Please click{" "}
          <a
            className="font-bold cursor-pointer hover:underline text-blue-700"
            target="_blank"
            rel="noopener noreferrer"
            href={`${window.location.origin}/customer-scorecard?cid=${customerId}`}
          >
            here
          </a>{" "}
          to access your Shortlist Match Results.
        </div>
        <div className="pb-1.5">Thank you,</div>
        <div className="pb-1.5">Jeff</div>
      </div>
      <button
        onClick={handleSendEmail}
        className="bg-blue-600 font-semibold text-white w-full p-4 my-6 mb-12 rounded-md hover:bg-blue-800"
      >
        Send Match Report to {customerEmail}
      </button>
      <h1 className="text-2xl font-bold mb-6">Match Report Preview</h1>
      <div>
        <ScorecardUpdates />
      </div>
    </div>
  );
}
