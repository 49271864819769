import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeVendor } from "../../../redux/scoreVendors/scoreVendorsSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { submitScorecardScores } from "../../../redux/scoreVendors/scoreVendorsThunk";
import { unwrapResult } from "@reduxjs/toolkit";
import CustomerBackToShortlists from "../../../components/CustomerBackToShortlists";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import toast from "react-hot-toast";

const TabController = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const vendors = useSelector((state) => state.scoreVendors.vendors);
  const currentVendor = useSelector(
    (state) => state.scoreVendors.currentVendor
  );
  const scoreCardData = useSelector(
    (state) => state.scoreVendors.scoreCardData
  );

  
  const matchDataByQuestionnaire = useSelector(
    (state) => state.scorecards.matchDataByQuestionnaire
  );


  const navigate = useNavigate();

  const handleTabClick = (vendor) => {
    dispatch(changeVendor(vendor));
  };

  const handleSaveScorecard = () => {
    console.log("handle save");
    console.log(scoreCardData);
  
    const body = { scores: JSON.stringify(scoreCardData) };
    const urlParams = new URLSearchParams(window.location.search);
    const qid = urlParams.get("qid");
  
    dispatch(submitScorecardScores({ qid, body }))
      .then(unwrapResult) // Assuming you're using something like Redux Toolkit's unwrapResult
      .then(result => {
        // Navigate on success
        navigate("/my-shortlists");
        toast.success('Vendor Scores Saved')
      })
      .catch(err => {
        // Handle any errors or unsuccessful submission cases here
        console.error("Failed to submit scores: ", err);
        toast.success('Error Saving Vendor Scores')
      });
  };

  
  const currentVendors = useSelector(state => state.questionnaire.matchReportScores.selectedVendors)

  const handleGoBack = () => {
    // Display confirmation dialog
    const confirm = window.confirm("Are you sure you'd like to go back? Scores will not be saved.");
    
    // Check if user confirmed
    if (confirm) {
      navigate("/my-shortlists");  // Navigate to '/my-shortlists' if user confirms
    }
  };


  return (
    <div  className="z-20 flex sticky top-[20px] flex flex-col left-0 right-0 bg-white border-t border-gray-200">
      <div className="pl-6 pt-6">
      <div
      onClick={() => handleGoBack()}
      className="pb-6 flex space-x-2 items-center hover:underline cursor-pointer text-sm w-48"
    >
      <ArrowLeftIcon className="h-3 w-3" />
      <span>Back to Shortlists</span>
    </div>
      </div>
    <div className="z-20 flex items-end justify-between p-3 sticky top-16 left-0 right-0 bg-white border border-gray-200">
      
      <div>
        <div className="text-xs font-semibold pb-1">Select Vendor:</div>
        <div className="flex space-x-2">
          {currentVendors.map((vendor) => (
            <button
              key={vendor}
              className={`px-4 py-2 rounded ${
                vendor === currentVendor
                  ? "p-1.5 px-3 rounded-md text-sm bg-blue-50 text-blue-700 font-semibold shadow-sm border border-blue-700 cursor-pointer"
                  : "p-1.5 px-3 rounded-md text-sm bg-white border border-gray-200 shadow-sm cursor-pointer"
              }`}
              onClick={() => handleTabClick(vendor)}
            >
              {vendor}
            </button>
          ))}
        </div>
      </div>
      <button
        className="bg-blue-700 hover:bg-blue-800 text-white text-md font-semibold p-2 rounded-md px-8"
        onClick={handleSaveScorecard}
      >
        Save Scores
      </button>
    </div>
    </div>
  );
};

export default TabController;
