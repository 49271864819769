import React from 'react'
import Header from './Header'
import Form from './Form'

export default function VendorCreator() {
  return (
    <div className='w-full flex flex-col '>
        <Header />
        <Form />
    </div>
  )
}
