import { createSlice } from "@reduxjs/toolkit";
import { fetchCustomers, getCustomerDetailsById } from "./customersThunk";

const initialState = {
  currentPage: 1,
  totalItemsInDb: -1,
  allCustomers: [],
  selectedCustomer: null,
  showEditor: false,
  loadedMatchReport: null,
  status: "init",
  error: null,
  pagination: {
    currentPage: 1,
    itemsPerPage: 20,
    totalItems: -1,
    totalPages: -1,
  },
};

const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    initNewCustomer: (state, action) => {
      state.selectedCustomer = {
        category: null,
        functionalArea: null,
        industry: null,
        companySize: null,
        name: "",
        tags: [], // List of strings for tags
        description: null,
        isDraft: true,
      };
    },
    updateCurrentPage: (state, action) => {
      state.pagination.currentPage = action.payload;
    },
    clearSelectedCustomer: (state, action) => {
      state.selectedCustomer = null;
    },
    setSelectedCustomer: (state, action) => {
      state.selectedCustomer = action.payload;
    },
    addCustomer: (state, action) => {
      state.allCustomers.push(action.payload);
    },
    updateCustomer: (state, action) => {
      const index = state.allCustomers.findIndex(
        (req) => req._id === action.payload._id
      );
      if (index !== -1) {
        state.allCustomers[index] = action.payload;
      }
    },
    updateSelectedCustomerStatus: (state, action) => {
      state.selectedCustomer.status = action.payload;
    },
    updateSelectedCustomer: (state, action) => {
      if (
        state.selectedCustomer &&
        (state.selectedCustomer._id === action.payload.id ||
          state.selectedCustomer._id === action.payload._id)
      ) {
        state.selectedCustomer[action.payload.property] = action.payload.value;
      }
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    handleExportData: (state, action) => {
      // Deep copy of state.selectedCustomer using JSON methods
      const selectedCustomer = JSON.parse(
        JSON.stringify(state.selectedCustomer)
      );
      const { completedQuestionnaires, selectedCustomerScorecards } = selectedCustomer;
      const questionnaireId = action.payload;
    
      // Placeholder for future data processing
      console.log("Selected Customer:", selectedCustomer);
      console.log("Completed Questionnaires:", completedQuestionnaires);
      console.log("Selected Customer Scorecards:", selectedCustomerScorecards);
      console.log("Questionnaire ID:", questionnaireId);
    
      // Find the completedQuestionnaire with the matching ID
      const matchingQuestionnaire = completedQuestionnaires.find(
        (q) => q._id === questionnaireId
      );
    
      if (matchingQuestionnaire) {
        let parsedCategories = [];
        let parsedScores = [];
        let matchReportItems = [];
        // Parse the completedQuestionnaire.categories
        try {
          parsedCategories = JSON.parse(matchingQuestionnaire.categories);
          console.log("Parsed Categories:", parsedCategories);
        } catch {
          // do nothing
        }
    
        // Parse the completedQuestionnaire.scores
        try {
          parsedScores = JSON.parse(matchingQuestionnaire.scores);
          console.log("Parsed Scores:", parsedScores);
        } catch {
          // do nothing
        }
    
        // Parse the completedQuestionnaire.matchReportItems
        try {
          matchReportItems = JSON.parse(matchingQuestionnaire.matchReportItems);
          console.log("Parsed matchReportItems:", matchReportItems);
        } catch {
          // do nothing
        }
    
        console.log("Parsed Scores:", parsedScores);
        state.loadedMatchReport = parsedScores;
    
        // CSV conversion logic
        let csvResult = "";
    
        // Section 1: Customer Info
        const customerInfo = {
          "Email Address": selectedCustomer.email,
          "First Name": selectedCustomer.firstName,
          "Last Name": selectedCustomer.lastName,
          Company: selectedCustomer.company,
          "Phone Number": selectedCustomer.phoneNumber,
          Status: selectedCustomer.status,
          "Created At": selectedCustomer.createdAt,
          "Updated At": selectedCustomer.updatedAt,
        };
    
        const customerInfoRows = Object.entries(customerInfo)
          .map(([key, value]) => `"${key}","${value}"`)
          .join("\n");
    
        csvResult += "Customer Info\n" + customerInfoRows + "\n\n";
    
        // Section 2: Questionnaire Responses
        const questionnaireResponses = [];
        if (parsedScores?.categories?.length > 0) {
          parsedScores.categories.forEach((category) => {
            const categoryTitle = category.title;
            category.questionScores.forEach((questionScore) => {
              const { userResponse, question, userImportanceRating } = questionScore;
              questionnaireResponses.push({
                Category: categoryTitle,
                Question: question,
                "User Response": userResponse ? userResponse.toString() : "-",
                "User Importance Rating": userImportanceRating ? userImportanceRating.toString() : "-",
              });
            });
          });
        }
    
        const questionnaireHeaders = "Category,Question,User Response,User Importance Rating";
        const questionnaireRows = questionnaireResponses
          .map(
            (response) =>
              `"${response.Category}","${response.Question}","'${response["User Response"]}","'${response["User Importance Rating"]}"`
          )
          .join("\n");
    
        csvResult += "Questionnaire Responses\n" + questionnaireHeaders + "\n" + questionnaireRows + "\n";
    
        // Print CSV result for debugging
        console.log("CSV Result:\n", csvResult);
    
        // Initiate download of CSV file
        const blob = new Blob([csvResult], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${customerInfo["First Name"]}_${customerInfo["Last Name"]}_Export.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        console.log("No matching completed questionnaire found.");
      }
    },
    
    setLoadedMatchReport: (state, action) => {
      // Deep copy of state.selectedCustomer using JSON methods
      const selectedCustomer = JSON.parse(
        JSON.stringify(state.selectedCustomer)
      );
      const { completedQuestionnaires, selectedCustomerScorecards } =
        selectedCustomer;
      const questionnaireId = action.payload;

      // Placeholder for future data processing
      console.log("Selected Customer:", selectedCustomer);
      console.log("Completed Questionnaires:", completedQuestionnaires);
      console.log("Selected Customer Scorecards:", selectedCustomerScorecards);
      console.log("Questionnaire ID:", questionnaireId);

      // Find the completedQuestionnaire with the matching ID
      const matchingQuestionnaire = completedQuestionnaires.find(
        (q) => q._id === questionnaireId
      );

      if (matchingQuestionnaire) {
        let parsedScores = [];
        // Parse the completedQuestionnaire.categories

        // Parse the completedQuestionnaire.matchReportItems
        try {
          parsedScores = JSON.parse(matchingQuestionnaire.matchReportItems);
          console.log("Parsed matchReportItems:", parsedScores);
        } catch {
          // do nothing
        }

        state.loadedMatchReport = parsedScores;
      } else {
        console.log("No matching completed questionnaire found.");
      }
    },
  },
  extraReducers: {
    // ----------
    // fetchCustomers
    // ----------
    [fetchCustomers.pending]: (state) => {
      state.status = "loading";
    },
    [fetchCustomers.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.allCustomers = action.payload.customers;
      state.pagination = action.payload.pagination;
      state.totalItemsInDb = action.payload.length;
    },
    [fetchCustomers.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    // ----------
    // getCustomerDetailsById
    // ----------
    [getCustomerDetailsById.pending]: (state) => {
      state.status = "loading";
    },
    [getCustomerDetailsById.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.selectedCustomer = action.payload.customer;
      state.selectedCustomerScorecards = action.payload.scorecards;
    },
    [getCustomerDetailsById.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export const {
  setLoadedMatchReport,
  clearSelectedCustomer,
  handleExportData,
  updateCurrentPage,
  initNewCustomer,
  updateCustomer,
  selectCustomer,
  updateSelectedCustomer,
  setSelectedCustomer,
  setPage,
  updateSelectedCustomerStatus,
} = customersSlice.actions;

export default customersSlice.reducer;
