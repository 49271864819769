export const dummyResults = [
    {
        "question": "What is your company's annual revenue?",
        "answer": "$950m",
        "importance": "5",
        "targetField": "RevenueRanges",
        "similarDocuments": [
            {
                "_id": "6571341e645782812897a4f8",
                "VendorName": "Strategic Analytics",
                "RevenueRanges": "$75m - $4b",
                "MinimumAnnualCost": "$40,000",
                "FrontEndPlatform": "Web, Custom Integration",
                "score": 0.9327026605606079
            },
            {
                "_id": "6571341e645782812897a4f6",
                "VendorName": "Fiscal Dynamics",
                "RevenueRanges": "$50m - $2b",
                "MinimumAnnualCost": "$30,000",
                "FrontEndPlatform": "Web, Excel, Custom Applications",
                "score": 0.9317507743835449
            },
            {
                "_id": "6571341d645782812897a4f3",
                "VendorName": "Optimum Insights",
                "RevenueRanges": "$100m - $3b",
                "MinimumAnnualCost": "$50,000",
                "FrontEndPlatform": "Web, Google Sheets, Excel",
                "score": 0.9314577579498291
            },
            {
                "_id": "6571341e645782812897a4fa",
                "VendorName": "Efficient Solutions",
                "RevenueRanges": "$80m - $3.5b",
                "MinimumAnnualCost": "$60,000",
                "FrontEndPlatform": "Mobile App, Web, Excel Plugin",
                "score": 0.9272122383117676
            }
        ]
    },
    {
        "question": "What is your annual budget for this solution?",
        "answer": "$40,000",
        "importance": "2",
        "targetField": "MinimumAnnualCost",
        "similarDocuments": [
            {
                "_id": "6571341e645782812897a4f8",
                "VendorName": "Strategic Analytics",
                "RevenueRanges": "$75m - $4b",
                "MinimumAnnualCost": "$40,000",
                "FrontEndPlatform": "Web, Custom Integration",
                "score": 1
            },
            {
                "_id": "6571341e645782812897a4fa",
                "VendorName": "Efficient Solutions",
                "RevenueRanges": "$80m - $3.5b",
                "MinimumAnnualCost": "$60,000",
                "FrontEndPlatform": "Mobile App, Web, Excel Plugin",
                "score": 0.9528151154518127
            },
            {
                "_id": "6571341e645782812897a4f6",
                "VendorName": "Fiscal Dynamics",
                "RevenueRanges": "$50m - $2b",
                "MinimumAnnualCost": "$30,000",
                "FrontEndPlatform": "Web, Excel, Custom Applications",
                "score": 0.9512397646903992
            },
            {
                "_id": "6571341d645782812897a4f3",
                "VendorName": "Optimum Insights",
                "RevenueRanges": "$100m - $3b",
                "MinimumAnnualCost": "$50,000",
                "FrontEndPlatform": "Web, Google Sheets, Excel",
                "score": 0.9450713396072388
            }
        ]
    },
    {
        "question": "Which front-end platform will you need (e.g., Web, Excel)?",
        "answer": "Google Sheets",
        "importance": "4",
        "targetField": "FrontEndPlatform",
        "similarDocuments": [
            {
                "_id": "6571341d645782812897a4f3",
                "VendorName": "Optimum Insights",
                "RevenueRanges": "$100m - $3b",
                "MinimumAnnualCost": "$50,000",
                "FrontEndPlatform": "Web, Google Sheets, Excel",
                "score": 0.9647425413131714
            },
            {
                "_id": "6571341e645782812897a4fa",
                "VendorName": "Efficient Solutions",
                "RevenueRanges": "$80m - $3.5b",
                "MinimumAnnualCost": "$60,000",
                "FrontEndPlatform": "Mobile App, Web, Excel Plugin",
                "score": 0.9208933711051941
            },
            {
                "_id": "6571341e645782812897a4f6",
                "VendorName": "Fiscal Dynamics",
                "RevenueRanges": "$50m - $2b",
                "MinimumAnnualCost": "$30,000",
                "FrontEndPlatform": "Web, Excel, Custom Applications",
                "score": 0.9207384586334229
            },
            {
                "_id": "6571341e645782812897a4f8",
                "VendorName": "Strategic Analytics",
                "RevenueRanges": "$75m - $4b",
                "MinimumAnnualCost": "$40,000",
                "FrontEndPlatform": "Web, Custom Integration",
                "score": 0.8934420347213745
            }
        ]
    }
]

export const dummyQuestions = {
    "Questionnaire": [
      {
        "Question": "What is your company's annual revenue?",
        "Target Field": "RevenueRanges",
        "Answer": "$950m"
      },
      {
        "Question": "What is your annual budget for this solution?",
        "Target Field": "MinimumAnnualCost",
        "Answer": "$40,000"
      },
      {
        "Question": "Which front-end platform will you need (e.g., Web, Excel)?",
        "Target Field": "FrontEndPlatform",
        "Answer": "Google Sheets"
      }
    ]
  }
  