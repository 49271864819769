import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App'
import './index.css'
import {Toaster} from "react-hot-toast";

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  // <React.StrictMode>
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_ID}>
    <Provider store={store}>
        <App />
      <Toaster position="bottom-center"/>
    </Provider>
  </GoogleOAuthProvider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
