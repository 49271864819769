import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import TeamView from "./components/TeamView";
import ScorecardView from "./components/ScorecardView";
import { getScorecardByQuestionnaireIdAdmin } from "../../redux/scorecards/scorecardsThunk";

export default function ViewTeamAndScorecards() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const selectedCustomerScorecards = useSelector(
    (state) => state.customers.selectedCustomerScorecards
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const qid = searchParams.get("qid");
    dispatch(getScorecardByQuestionnaireIdAdmin({id: qid}))
  }, []);

  return (
    <div className="overflow-y-auto bg-white pb-12">
      <div className="p-6 text-2xl font-bold flex items-center space-x-3">
        <div
          onClick={() => navigate("/customers")}
          className="cursor-pointer hover:bg-gray-100 border rounded-full bg-white border-gray-400 p-1"
        >
          <ArrowLeftIcon className="h-4 w-4 text-gray-900" />
        </div>
        <div className="w-full">Team and Scorecards:</div>
      </div>
      {selectedCustomerScorecards[0] ? (
        <div className="px-6 xl:px-12 flex w-full">
          <TeamView teamMembers={selectedCustomerScorecards[0].teamMembers} />
          <ScorecardView />
        </div>
      ) : (
        <div className="p-6">Customer has not yet created a scorecard.</div>
      )}
    </div>
  );
}
