import React from 'react'
import Header from './Header'
import Form from './Form'
import MDEditContainer from './MDEditContainer'

export default function RequirementsCreator() {
  return (
    <div className='w-full flex flex-col '>
        <Header />
        <Form />
        <MDEditContainer />
    </div>
  )
}
