import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {queryVendorProperties, fetchVendors} from './vendorsThunk'

const vendorsSlice = createSlice({
  name: "vendors",
  initialState: {
    status: "init",
    vendorResults: [],
    vendors: [],
    selectedVendor: null,
    error: null,
    pagination: {
      currentPage: 1,
      itemsPerPage: 20,
      totalItems: -1,
      totalPages: -1,
    },
  },
  reducers: {
    initNewVendor: (state, action) => {
      state.selectedVendor = {
        category: null,
        functionalArea: null,
        industry: null,
        companySize: null,
        name: "",
        tags: [], // List of strings for tags
        description: null,
        isDraft: true,
      };
    },
    updateCurrentPage: (state, action) => {
      state.pagination.currentPage = action.payload;
    },
    toggleEditor: (state, action) => {
      state.showEditor = !state.showEditor;
    },
    setSelectedVendor: (state, action) => {
      state.selectedVendor = action.payload;
    },
    addVendor: (state, action) => {
      state.allVendors.push(action.payload);
    },
    updateVendor: (state, action) => {
      const index = state.allVendors.findIndex(
        (req) => req._id === action.payload._id
      );
      if (index !== -1) {
        state.allVendors[index] = action.payload;
      }
    },
    updateSelectedVendor: (state, action) => {
      if (
        state.selectedVendor &&
        state.selectedVendor._id === action.payload.id
      ) {
        state.selectedVendor[action.payload.property] =
          action.payload.value;
      }
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: {
    // --------
    // queryVendorProperties
    // --------
    [queryVendorProperties.pending]: (state, action) => {
      state.status = "loading";
    },
    [queryVendorProperties.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.vendorResults = payload;
    },
    [queryVendorProperties.rejected]: (state, { payload }) => {
      state.status = "failed";
      toast.error(payload);
    },
    // --------
    // fetchVendors
    // --------
    [fetchVendors.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchVendors.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.vendors = payload.vendors;
      state.pagination = payload.pagination;
    },
    [fetchVendors.rejected]: (state, { payload }) => {
      state.status = "failed";
      toast.error(payload);
    },
  },
});

export const {
  initNewVendor,
  updateCurrentPage,
  toggleEditor,
  setSelectedVendor,
  addVendor,
  updateVendor,
  updateSelectedVendor,
  setPage,
} = vendorsSlice.actions;
export const selectQuestions = (state) => state.vendors;
export default vendorsSlice.reducer;
