import { createSlice } from "@reduxjs/toolkit";
import { fetchRequirements, saveRequirementToDb, deleteRequirement } from "./requirementsThunk";

const initialState = {
  currentPage: 1,
  totalItemsInDb: -1,
  allRequirements: [],
  selectedRequirement: null,
  showEditor: false,
  status: "init", // 'init', 'loading', 'succeeded', 'failed'
  error: null,
  pagination: {
    currentPage:1,
    itemsPerPage:20,
    totalItems:-1,
    totalPages:-1,
  },
};

const requirementsSlice = createSlice({
  name: "requirements",
  initialState,
  reducers: {
    initNewRequirement: (state, action) => {
      state.selectedRequirement = {
        category: null,
        functionalArea: null,
        industry: null,
        companySize: null,
        name: "",
        tags: [], // List of strings for tags
        description: null,
        isDraft: true,
      };
    },
    updateCurrentPage: (state, action) => {
      state.pagination.currentPage = action.payload;
    },
    toggleEditor: (state, action) => {
      state.showEditor = !state.showEditor;
    },
    setSelectedRequirement: (state, action) => {
      state.selectedRequirement = action.payload;
    },
    addRequirement: (state, action) => {
      state.allRequirements.push(action.payload);
    },
    updateRequirement: (state, action) => {
      const index = state.allRequirements.findIndex(
        (req) => req._id === action.payload._id
      );
      if (index !== -1) {
        state.allRequirements[index] = action.payload;
      }
    },
    updateSelectedRequirement: (state, action) => {
      if (
        state.selectedRequirement &&
        state.selectedRequirement._id === action.payload.id
      ) {
        state.selectedRequirement[action.payload.property] =
          action.payload.value;
      }
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: {
    // ----------
    // fetchRequirements
    // ----------
    [fetchRequirements.pending]: (state) => {
      state.status = "loading";
    },
    [fetchRequirements.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.allRequirements = action.payload.requirements;
      state.pagination = action.payload.pagination
      state.totalItemsInDb = action.payload.length;
    },
    [fetchRequirements.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    // ----------
    // saveRequirementToDb
    // ----------
    [saveRequirementToDb.pending]: (state) => {
      state.status = "loading";
    },
    [saveRequirementToDb.fulfilled]: (state, action) => {
      state.status = "succeeded";

      // Check if the requirement already exists in the list
      const existingIndex = state.allRequirements.findIndex(
        (req) => req._id === action.payload._id
      );

      if (existingIndex >= 0) {
        // If it exists, replace the existing entry
        state.allRequirements[existingIndex] = action.payload;
      } else {
        // If it doesn't exist, add the new requirement to the beginning of the list
        state.allRequirements = [action.payload, ...state.allRequirements];
        state.totalItemsInDb += 1;
      }

      // Reset the selectedRequirement
      state.selectedRequirement = {
        category: null,
        functionalArea: null,
        industry: null,
        companySize: null,
        name: "",
        tags: [],
        description: null,
        isDraft: true,
      };
    },
    [saveRequirementToDb.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    // ----------
    // deleteRequirement
    // ----------
    [deleteRequirement.pending]: (state) => {
      state.status = "loading";
    },
    [deleteRequirement.fulfilled]: (state, action) => {
      state.status = "succeeded";
      // Remove the deleted requirement from the list
      state.allRequirements = state.allRequirements.filter(
        (req) => req._id !== action.payload._id
      );
      state.totalItemsInDb -= 1;
    },
    [deleteRequirement.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export const {
  toggleEditor,
  updateCurrentPage,
  initNewRequirement,
  updateRequirement,
  selectRequirement,
  updateSelectedRequirement,
  setSelectedRequirement,
  setPage,
} = requirementsSlice.actions;

export default requirementsSlice.reducer;
