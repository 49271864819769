import { createBrowserRouter, RouterProvider } from "react-router-dom";
import SignIn from "./pages/SignIn/SignIn";
import Page404 from "./pages/Page404/Page404";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import ResetPassword from "./pages/SignIn/components/ResetPassword";
import Home from "./pages/Home/Home";
import RequirementsDatabase from "./pages/RequirementsDatabase/RequirementsDatabase";
import QuestionnaireEditor from "./pages/QuestionnaireEditor/QuestionnaireEditor";
import Customers from "./pages/Customers/Customers";
import CustomerLogin from "./pages/CustomerLogin/CustomerLogin";
import Questionnaire from "./pages/Questionnaire/Questionnaire";
import VendorDatabase from "./pages/VendorDatabase/VendorDatabase";
import QuestionnaireResults from "./pages/QuestionnaireResults/QuestionnaireResults";
import Scorecards from "./pages/Scorecards/Scorecards";
import ScoreVendors from "./pages/ScoreVendors/ScoreVendors";
import CustomerScorecard from "./pages/CustomerScorecardLogin/CustomerScorecardLogin";
import CustomerScorecardView from "./pages/CustomerScorecardView/CustomerScorecardView";

import { ProtectedRoute } from "./components/ProtectedRoute";
import { CustomerPortalWrapper } from "./components/CustomerPortalWrapper/CustomerPortalWrapper";
import CustomerCreateDemoScorecard from "./pages/CustomerCreateDemoScorecard/CustomerCreateDemoScorecard";
import CustomerRequirementsDB from "./pages/CustomerRequirementsDB/CustomerRequirementsDB";
import CustomerMyScorecards from "./pages/CustomerMyScorecards/CustomerMyScorecards";
import CustomerTeamResults from "./pages/CustomerTeamResults/CustomerTeamResults";
import CustomerCreateTeam from "./pages/CustomerCreateTeam/CustomerCreateTeam";
import JoinTeam from "./pages/JoinTeam/JoinTeam";
import ViewMatchReport from "./pages/ViewMatchReport/ViewMatchReport";
import ViewTeamAndScorecards from "./pages/ViewTeamAndScorecards/ViewTeamAndScorecards";

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <Page404 />,
    element: <CustomerLogin />,
  },
  {
    path: "/admin-login",
    errorElement: <Page404 />,
    element: <SignIn />,
  },
  {
    path: "/join-team",
    errorElement: <Page404 />,
    element: <JoinTeam />,
  },
  {
    path: "/customer-login",
    errorElement: <Page404 />,
    element: <CustomerLogin />,
  },
  {
    path: "/customer-scorecard",
    errorElement: <Page404 />,
    element: <CustomerScorecard />,
  },
  {
    path: "/view-scorecard",
    errorElement: <Page404 />,
    element: (
      <CustomerPortalWrapper>
        <CustomerScorecardView />
      </CustomerPortalWrapper>
    ),
  },
  {
    path: "/create-team",
    errorElement: <Page404 />,
    element: (
      <CustomerPortalWrapper>
        <CustomerCreateTeam />
      </CustomerPortalWrapper>
    ),
  },
  {
    path: "/view-team-results",
    errorElement: <Page404 />,
    element: (
      <CustomerPortalWrapper>
        <CustomerTeamResults />
      </CustomerPortalWrapper>
    ),
  },
  {
    path: "/my-shortlists",
    errorElement: <Page404 />,
    element: (
      <CustomerPortalWrapper>
        <CustomerMyScorecards />
      </CustomerPortalWrapper>
    ),
  },
  {
    path: "/create-demo-scorecard",
    errorElement: <Page404 />,
    element: (
      <CustomerPortalWrapper>
        <CustomerCreateDemoScorecard />
      </CustomerPortalWrapper>
    ),
  },
  {
    path: "/customer-requirements-db",
    errorElement: <Page404 />,
    element: (
      <CustomerPortalWrapper>
        <CustomerRequirementsDB />
      </CustomerPortalWrapper>
    ),
  },
  {
    path: "/questionnaire",
    errorElement: <Page404 />,
    element: <Questionnaire />,
  },
  {
    path: "/reset-password",
    errorElement: <Page404 />,
    element: <ResetPassword />,
  },
  {
    path: "/home",
    errorElement: <Page404 />,
    element: (
      <ProtectedRoute>
        <Home />
      </ProtectedRoute>
    ),
  },
  {
    path: "/view-match-report",
    errorElement: <Page404 />,
    element: (
      <ProtectedRoute>
        <ViewMatchReport />
      </ProtectedRoute>
    ),
  },
  {
    path: "/requirements-database",
    element: (
      <ProtectedRoute>
        <RequirementsDatabase />
      </ProtectedRoute>
    ),
  },
  {
    path: "/vendor-database",
    element: (
      <ProtectedRoute>
        <VendorDatabase />
      </ProtectedRoute>
    ),
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions />,
  },
  {
    path: "/questionnaire-editor",
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <QuestionnaireEditor />
          </ProtectedRoute>
        ),
      },
    ],
    errorElement: <Page404 />,
  },
  {
    path: "/scorecards",
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <Scorecards />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/customers",
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <Customers />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/questionnare-results",
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <QuestionnaireResults />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/view-team-and-scorecards",
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <ViewTeamAndScorecards />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/score-vendors",
    children: [
      {
        index: true,
        element: (
          <CustomerPortalWrapper>
            <ScoreVendors />
          </CustomerPortalWrapper>
        ),
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
