import { createAsyncThunk } from '@reduxjs/toolkit';
import ShortListAdviceAPI from '../ShortListAdviceAPI'; // Replace with your actual API utility

export const fetchRequirements = createAsyncThunk(
  'requirements/fetchRequirements',
  async (_, { getState, rejectWithValue }) => {
    try {
      // Access the current state to get the page and limit values
      const { currentPage, itemsPerPage } = getState().requirements.pagination;

      console.log(currentPage)
      console.log(itemsPerPage)

      // Construct the query string with the retrieved page and limit values
      const queryString = `?currentPage=${currentPage}&itemsPerPage=${itemsPerPage}`;
      const response = await ShortListAdviceAPI.get('/api/requirements' + queryString);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);


export const saveRequirementToDb = createAsyncThunk(
  'requirements/saveRequirement',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { requirements } = getState();
      const selectedRequirement = requirements.selectedRequirement;

      // Ensure there is a selected requirement to save
      if (!selectedRequirement) {
        throw new Error('No selected requirement to save');
      }

      const response = await ShortListAdviceAPI.post('/api/requirements', selectedRequirement);
      return response.data;
    } catch (error) {
      // Adjust based on how your API utility and backend are configured
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);


export const deleteRequirement = createAsyncThunk(
  'requirements/deleteRequirement',
  async (requirementId, { rejectWithValue }) => {
    try {
      const response = await ShortListAdviceAPI.delete(`/api/requirements/${requirementId}`);
      return response.data; // Assuming the response data contains the ID of the deleted requirement
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);