import React from "react";

export default function TeamView({ teamMembers }) {
  console.log("TeamView");
  console.log(teamMembers);
  return (
    <div >
      {teamMembers && (
        <div className="p-3 pr-6 flex flex-col space-y-2">
          <h4 className="mb-2 font-semibold">Team Members:</h4>
          {teamMembers.map((item) => (
            <div className="text-sm p-2 border rounded-md shadow-sm bg-gray-50">
              <div className="font-semibold">
                {item.firstName.toString() + " "}
                {item.lastName.toString()}
              </div>
              <div>{item.email.toString()}</div>
              <div className="mt-4 text-xs">Status: {item.status.toString()}</div>
            </div>
          ))}
        </div>
      )}

      {(!teamMembers || teamMembers?.length == 0) && <div>No Team Members</div>}
    </div>
  );
}
