import { createSlice, current } from "@reduxjs/toolkit";
import { saveQuestionsToDb, getAdminQuestionnaire, getQuestionnaire } from "./questionsThunk";
import toast from "react-hot-toast";

// This slice manages CRUD of the questions / categories /subcategories themselves.
// Questionnaire is what manages the actual taking of the questionnaire for clients.

const questionsSlice = createSlice({
  name: "questions",
  initialState: {
    user: null,
    passwordProtect: true,
    status: "init",
    error: "",
    slideoutOpen: false,
    modalOpen: false,
    categories: [],
    selectedCategory: null,
    subcategoryModalOpen: false,
    selectedSubcategory: null,
    currentSubcategories: [],
    currentQuestions: [],
  },
  reducers: {
    addCategory: (state, { payload }) => {
      const newCategory = {
        title: payload.title,
        id: payload.id,
        status: "Active",
        subcategories: payload.subcategories.map((sub) => ({
          id: sub.id,
          title: sub.title,
          headline: sub.headline,
          questions: sub.questions,
        })),
      };
      // Insert newCategory before the last category
      state.categories.splice(state.categories.length - 1, 0, newCategory);
    },
    updateCategory: (state, { payload }) => {
      const categoryIndex = state.categories.findIndex(
        (cat) => cat.id === payload.id
      );
      if (categoryIndex !== -1) {
        console.log("Found category and updating");
        state.categories[categoryIndex] = {
          ...state.categories[categoryIndex],
          ...payload,
        };
      }
    },
    updateSubcategory: (state, { payload }) => {
      const { categoryId, subcategory } = payload;
      const category = state.categories.find((cat) => cat.id === categoryId);
      if (category) {
        const subcategoryIndex = category.subcategories.findIndex(
          (sub) => sub.id === subcategory.id
        );
        if (subcategoryIndex !== -1) {
          console.log("Found subcategory and updating");
          category.subcategories[subcategoryIndex] = {
            ...category.subcategories[subcategoryIndex],
            ...subcategory,
          };
        }
      }
    },
    deleteSubcategory: (state, { payload }) => {
      const subcategoryId = payload;
      console.log('DELETE SUBCA')
      console.log(subcategoryId)
    
      // Take a snapshot of the current subcategories
      const currentSubcategories = current(state.currentSubcategories);
    
      // Find the index of the subcategory with the matching subcategoryId
      const subcategoryIndex = currentSubcategories.findIndex(sub => sub.id === subcategoryId);

      // If the subcategory is found, remove it from the state's currentSubcategories
      if (subcategoryIndex !== -1) {
        state.currentSubcategories.splice(subcategoryIndex, 1);
      } else {
        // Do nothing.
      }
    },

    
    deleteCategory: (state, { payload }) => {
      state.categories = state.categories.filter(
        (category) => category.id !== payload
      );
    },
    setSelectedCategory: (state, { payload }) => {
      state.selectedCategory = payload;
    },
    toggleSlideout: (state, { payload }) => {
      state.slideoutOpen = !state.slideoutOpen;
    },
    toggleModal: (state, { payload }) => {
      state.modalOpen = !state.modalOpen;
    },
    toggleSubcategoryModal: (state, { payload }) => {
      state.subcategoryModalOpen = !state.subcategoryModalOpen;
    },
    setSelectedSubcategory: (state, { payload }) => {
      state.selectedSubcategory = payload;
    },
    setCurrentSubcategories: (state, { payload }) => {
      state.currentSubcategories = payload;
    },
    addSubcategory: (state, { payload }) => {
      state.currentSubcategories.push(payload);
    },
    updateCurrentSubcategory: (state, { payload }) => {
      const subcategoryIndex = state.currentSubcategories.findIndex(
        (sub) => sub.id === payload.id
      );
      if (subcategoryIndex !== -1) {
        // Assign currentQuestions to the subcategory's questions property
        state.currentSubcategories[subcategoryIndex] = {
          ...state.currentSubcategories[subcategoryIndex],
          ...payload,
          questions: [...state.currentQuestions],
        };
        // Reset currentQuestions to an empty array
        state.currentQuestions = [];
      }
    },
    saveSubcategoriesToCategory: (state) => {
      if (state.selectedCategory) {
        const categoryIndex = state.categories.findIndex(
          (cat) => cat.id === state.selectedCategory.id
        );
        if (categoryIndex !== -1) {
          state.categories[categoryIndex].subcategories = [
            ...state.currentSubcategories,
          ];
        }
      }
    },
    setCurrentQuestions: (state, { payload }) => {
      state.currentQuestions = payload;
    },
    addQuestion: (state, { payload }) => {
      state.currentQuestions.push(payload);
    },
    updateCurrentQuestion: (state, { payload }) => {
      const questionIndex = state.currentQuestions.findIndex(
        (q) => q.id === payload.id
      );
      if (questionIndex !== -1) {
        state.currentQuestions[questionIndex] = {
          ...state.currentQuestions[questionIndex],
          ...payload,
        };
      }
    },
    saveQuestionsToSubcategory: (state) => {
      if (state.selectedSubcategory) {
        const subcategoryIndex = state.currentSubcategories.findIndex(
          (sub) => sub.id === state.selectedSubcategory.id
        );
        if (subcategoryIndex !== -1) {
          state.currentSubcategories[subcategoryIndex].questions = [
            ...state.currentQuestions,
          ];
        }
      }
    },
    updateCurrentQuestionsOrder: (state, { payload }) => {
      state.currentQuestions = payload;
    },
    deleteQuestion: (state, { payload }) => {
      state.currentQuestions = state.currentQuestions.filter(
        (question) => question.id !== payload
      );
    },
    updateQuestionType: (state, { payload }) => {
      const { id, type } = payload;
      const questionIndex = state.currentQuestions.findIndex(
        (q) => q.id === id
      );
      if (questionIndex !== -1) {
        state.currentQuestions[questionIndex].type = type;
      }
    },
    updateQuestionTargetProperty: (state, { payload }) => {
      const { id, targetProperty } = payload;
      const questionIndex = state.currentQuestions.findIndex(
        (q) => q.id === id
      );
      if (questionIndex !== -1) {
        state.currentQuestions[questionIndex].targetProperty = targetProperty;
      }
    },
    updateQuestionScoreMethod: (state, { payload }) => {
      const { id, scoreMethod } = payload;
      console.log('REDUX updateQuestionScoreMethod')
      console.log(id)
      console.log(scoreMethod)
      const questionIndex = state.currentQuestions.findIndex(
        (q) => q.id === id
      );
      if (questionIndex !== -1) {
        state.currentQuestions[questionIndex].scoreMethod = scoreMethod;
      }
    },

    updateQuestionShowImportance: (state, { payload }) => {
      const { id, showImportance } = payload;
      const questionIndex = state.currentQuestions.findIndex(
        (q) => q.id === id
      );
      if (questionIndex !== -1) {
        state.currentQuestions[questionIndex].showImportance = showImportance;
      }
    },


    addAnswerToQuestion: (state, { payload }) => {
      const { questionId, answer } = payload;
      const questionIndex = state.currentQuestions.findIndex(
        (q) => q.id === questionId
      );
      if (questionIndex !== -1) {
        state.currentQuestions[questionIndex].answers.push(answer);
      }
    },

    deleteAnswerFromQuestion: (state, { payload }) => {
      const { questionId, answerIndex } = payload;
      const questionIndex = state.currentQuestions.findIndex(
        (q) => q.id === questionId
      );
      if (questionIndex !== -1) {
        state.currentQuestions[questionIndex].answers.splice(answerIndex, 1);
      }
    },
    updateAnswersOrder: (state, { payload }) => {
      const { questionId, answers } = payload;
      const questionIndex = state.currentQuestions.findIndex(
        (q) => q.id === questionId
      );
      if (questionIndex !== -1) {
        state.currentQuestions[questionIndex].answers = answers;
      }
    },
    updateAnswerText: (state, { payload }) => {
      const { questionId, answerId, text } = payload;
      const questionIndex = state.currentQuestions.findIndex(
        (q) => q.id === questionId
      );
      if (questionIndex !== -1) {
        const answerIndex = state.currentQuestions[
          questionIndex
        ].answers.findIndex((a) => a.id === answerId);
        if (answerIndex !== -1) {
          state.currentQuestions[questionIndex].answers[answerIndex].text =
            text;
        }
      }
    },
    updateQuestionText: (state, { payload }) => {
      const { questionId, text } = payload;
      // Find the index of the question to be updated
      const questionIndex = state.currentQuestions.findIndex(
        (q) => q.id === questionId
      );
      if (questionIndex !== -1) {
        // Update the text of the question
        state.currentQuestions[questionIndex].text = text;
      }
    },
    updateTooltipText: (state, { payload }) => {
      const { questionId, tooltip } = payload;
      // Find the index of the question to be updated
      const questionIndex = state.currentQuestions.findIndex(
        (q) => q.id === questionId
      );
      if (questionIndex !== -1) {
        // Update the tooltip of the question
        state.currentQuestions[questionIndex].tooltip = tooltip;
      }
    },
  },
  extraReducers: {
    // --------
    // saveQuestionsToDb
    // --------
    [saveQuestionsToDb.pending]: (state, action) => {
      state.status = "loading";
      state.user = null;
      state.error = "";
    },
    [saveQuestionsToDb.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.user = payload;
    },
    [saveQuestionsToDb.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.user = null;
      state.error = payload;
      toast.error(payload);
    },
    // --------
    // getAdminQuestionnaire
    // --------
    [getAdminQuestionnaire.pending]: (state) => {
      state.status = "loading";
      state.categories = null;
      state.error = "";
    },
    [getAdminQuestionnaire.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.categories = payload;
    },
    [getAdminQuestionnaire.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.categories = null;
      state.error = payload;
      toast.error(payload);
    },
  },
});

export const {
  updateQuestionText,
  updateTooltipText,
  updateAnswerText,
  updateAnswersOrder,
  addAnswerToQuestion,
  deleteAnswerFromQuestion,
  updateQuestionType,
  deleteQuestion,
  updateCurrentQuestionsOrder,
  setCurrentQuestions,
  addQuestion,
  updateCurrentQuestion,
  saveQuestionsToSubcategory,
  setCurrentSubcategories,
  addSubcategory,
  updateCurrentSubcategory,
  saveSubcategoriesToCategory,
  setSelectedCategory,
  deleteCategory,
  updateCategory,
  addCategory,
  toggleSlideout,
  toggleModal,
  toggleSubcategoryModal,
  setSelectedSubcategory,
  updateSubcategory,
  updateQuestionTargetProperty,
  updateQuestionScoreMethod,
  updateQuestionShowImportance,
  deleteSubcategory
} = questionsSlice.actions;
export const selectQuestions = (state) => state.questions;
export default questionsSlice.reducer;
